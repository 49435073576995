import React from 'react'
import PropTypes from 'prop-types'
import {
    List,
    TextareaItem,
    Button,
    InputItem,
    NavBar,
    Checkbox,
    Icon,
    WhiteSpace,
    Picker,
    Toast,
    DatePicker,
    WingBlank
} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import {createForm} from 'rc-form'
import {postRequest} from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import CustomerSearch from '../../components/customer_search'
import AmapSearch from '../../components/amapSearch'

const Item = List.Item;


class XiaoJiaEditor extends React.Component {
    static propTypes = {
        form: PropTypes.object.isRequired,
        saveXiaoJia: PropTypes.func.isRequired,
    }

    state = {
        content: '',
        end_time:'',
        qingjia_id: '',
        xiaojia_id: '',
    };

    // componentWillMount() {
    //     const waichuback_id = localStorage.getItem('waichuback_id');
    //     this.setState({waichuback_id: waichuback_id});
    //     if (waichuback_id !== '')//判断是编辑还是新增
    //     {
    //         this.getDetail(waichu_id);
    //     }
    // }

    componentDidMount() {
    }

    onSubmit = () => {
        const {validateFields} = this.props.form
        const {content,end_time} = this.state
        const {files, history, saveXiaoJia} = this.props
        const qingjia_id = localStorage.getItem('qingjia_id');
        validateFields((err, values) => {
            let body = '';
            if(end_time) {
                body += `endtime=${moment(end_time).format('YYYY-MM-DD HH:mm')}`
            } else {
                Toast.info('提前销假时间为必填项', 1);
                return
            }
            if (content) {
                body += `&content=${content}`
            } else {
                Toast.info('提前销假原由必填！', 1);
                return
            }

            if (!err) {
                body += `&qj_id=${qingjia_id}`
                console.log(body);
                saveXiaoJia(body, history)
            }
        });
    }

    render() {
        const {getFieldProps} = this.props.form;
        const {ctype, detail} = this.props;

        return (
            <div className='index-content' id='wipeout_editor'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left"/>}
                    onLeftClick={() => this.props.history.goBack()}
                >提前销假申请</NavBar>
                <List renderHeader='' className="qj-list my_list">
                    <DatePicker
                        mode="datetime"
                        title="选择销假时间"
                        extra="选择销假时间"
                        value={this.state.end_time}
                        onChange={date => this.setState({ end_time:date })}
                    >
                        <List.Item arrow="horizontal">销假时间<span className="xing">*</span></List.Item>
                    </DatePicker>
                    <TextareaItem
                        {...getFieldProps('content', {
                            rules: [{require: true}],
                        })}
                        value={this.state.content}
                        onChange={value => this.setState({content: value})}
                        placeholder="请填写提前销假原由"
                        data-seed="logId"
                        rows={4}
                        ref={el => this.customFocusInst = el}
                    />
                </List>
                <WhiteSpace/>
                <WhiteSpace/>
                <WhiteSpace/>
                <WingBlank size="md">
                    <Button type='primary' onClick={() => this.onSubmit()}>提交</Button>
                </WingBlank>

            </div>
        )
    }
}

export default createForm()(XiaoJiaEditor)