import React from 'react'
import PropTypes from 'prop-types'
import { List, TextareaItem, Button, InputItem, NavBar, Checkbox, Icon, WhiteSpace, Picker, Toast, DatePicker,WingBlank, ImagePicker } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import { Link } from 'react-router-dom'
import { createForm } from 'rc-form'
import { postRequest } from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import Customer from '../../components/customer'
import CustomerSearch from '../../components/customer_search'
import AmapSearch from '../../components/amapSearch'
const Item = List.Item;
const data = [{
    url: 'http://192.168.80.22/uploadfile/jiucan/20191226135801199.png',
    id: '1',
}];
class JiaBanMealsEditor extends React.Component {
  static propTypes = {
    form:PropTypes.object.isRequired,
    getApper:PropTypes.func.isRequired,
      getuser:PropTypes.func.isRequired,
    apper:PropTypes.array.isRequired,
      users:PropTypes.array.isRequired,
      saveJiaBanMeals:PropTypes.func.isRequired,
  }

  state = {
    start_time:'',
    apper:[],
      users:[],
      userName:[],
      userid:[],
      content:'',
      waichu_id:'',
      check_username:'',
      files: [],
  };
    componentWillMount(){
    const waichu_id = localStorage.getItem('waichu_id');
    this.setState({waichu_id:waichu_id});
    if(waichu_id!=='')//判断是编辑还是新增
    {
        this.getDetail(waichu_id);
    }
}
  componentDidMount() {
    this.props.getApper();
    this.props.getuser();
  }
   //获取修改数据
    getDetail = async(waichu_id) => {
        postRequest(`/jiucan/edit`, `id=${waichu_id}`).then((result) => {
            //console.log(result);
            if(result && result.status === 1) {
                let imgurl=result.data.imgurl;
                const list=[];
                list.push({"id":result.data.id,"url": imgurl});
                const userid=[];
                const userlist=result.data.car_id.split(',');
                for(var i=0;i<userlist.length;i++)
                {
                    userid.push(parseInt(userlist[i]));
                }
                this.setState({
                    start_time:new Date(result.data.infotime),
                    apper:[result.data.check_id],
                    check_username:result.data.u_fullname,
                    userid:userid,
                    userName:result.data.yg.split(','),
                    files:list,
                    content:result.data.content,
                });
            } else {
                Toast.info(result.msg || '获取失败', 1);
                if(result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }
//上传图片
    onChange = (files, type, index) => {
        console.log(files, type, index);
        this.setState({
            files,
        });
    }
  onSubmit = () => {
    const { validateFields } = this.props.form
    const { start_time,apper,content,userid } = this.state
    const { files, history, saveJiaBanMeals } = this.props

    validateFields((err, values) => {
      let body =''
      if(start_time) {
        body += `infotime=${moment(start_time).format('YYYY-MM-DD HH:mm')}`
      } else {
          Toast.info('就餐时间为必填项', 1);
        return
      }
        if (this.state.files[0] != 'undefined' && this.state.files[0] != null) {
            body += `&imgurl=${this.state.files[0].url}`;
        }
        if(userid&&userid[0]) {
            const len=userid.join(',');
            body += `&car_id=${len}`
        } else {
            Toast.info('就餐人员为必填项', 1);
            return
        }
      if(apper && apper[0]) {
        body += `&check_id=${apper[0]}`
      } else {
          Toast.info('审批人为必填项', 1);
        return
      }
        if(content) {
            body += `&content=${content}`
        } else {
            Toast.info('外出事由为必填项', 1);
            return
        }
      if (!err) {
        //Toast.loading('新建中, 请稍等....')
          if(this.state.waichu_id!='')
          {
              body += `&id=${this.state.waichu_id}`
          }
          //console.log(body);
          saveJiaBanMeals(body, history)
       }
    });
  }

  render() {
    const { getFieldProps } = this.props.form;
    const {  ctype, detail,users} = this.props;
    const{files}=this.state;
    let apper = [];
      apper = this.props.apper.map((value, index) => {
      return {
        value:value.u_id,
        label:value.u_fullname
      }
    })
    return (
      <div className='index-content' id='wipeout_editor'>
      <NavBar
        mode="dark"
        icon={<Icon type="left" />}
        onLeftClick={() => this.props.history.goBack() }
        >加班就餐申请</NavBar>
          <List  className="picker-list title_width my_list" renderHeader=''>

            <DatePicker
              mode="datetime"
              title="就餐时间"
              extra="选择就餐时间"
              value={this.state.start_time}
              onChange={date => {
                  this.setState({ start_time:date });}}
            >
              <List.Item arrow="horizontal">就餐时间<span className="xing">*</span></List.Item>
            </DatePicker>
              <Item multipleLine wrap extra={this.state.userName.join(',')} onClick={(e) => {
                  e.preventDefault()
                  this.setState({visible:true})
              }}>就餐人员</Item>
              <div style={{position:'fixed', top:0, bottom:0, width:'100%', display:this.state.visible ? 'block' : 'none', minHeight:document.documentElement.clientHeight, zIndex:99999, overflow:'scroll', backgroundColor:'#fff' }}>
                  <Customer selectvalue={this.state.userid} customers={users} getCustomers={(value) => {
                      let apperName = [];
                      let user_id='';
                      value.forEach((v,i) => {
                          user_id+=v+',';
                          users.forEach((val, index) => {
                              if(v === val.u_id) {
                                  apperName.push(val.u_fullname)
                              }
                          })
                      })
                      this.setState({userName:apperName, userid:value, visible:false})
                  }} />
              </div>

            <CustomerSearch selectvalue={this.state.apper} initialValue={this.state.check_username} customers={apper} onOk={(v) => {
                {this.setState({ apper: v })}
            }}  name='审批人'/>
              <div className='upload_title'>就餐签字单凭证<span className="xing">*</span></div>
              <ImagePicker className='upload_img'
                  files={files}
                  multiple={false}
                  length={1}
                  onChange={this.onChange}
                  onImageClick={(index, fs) => console.log(index, fs)}
                  selectable={files.length < 1}
              />
          </List>
        <List renderHeader='' className="qj-list">
          <TextareaItem
              {...getFieldProps('content', {
                  rules:[{require: true}],
              })}
              value={this.state.content}
              onChange={value=>this.setState({content:value})}
              placeholder="请填写事由"
              data-seed="logId"
              rows={4}
              ref={el => this.customFocusInst = el}
          />
        </List>
          <WhiteSpace />
          <WhiteSpace />
          <WhiteSpace />
        <WingBlank size="md">
          <Button type='primary' onClick={() => this.onSubmit()}>提交</Button>
        </WingBlank>
          <div style={{height:'10px'}}></div>
          <div className='daka_rule'>
              <span>注意事项</span>
              <p>
                  1）加班餐费为15元/人，超出部分自费<br/>
              </p>
          </div>
      </div>
    )
  }
}

export default createForm()(JiaBanMealsEditor)