import React from 'react'
import PropTypes from 'prop-types'
import { List, TextareaItem, Button, InputItem, NavBar, Checkbox, Icon, WhiteSpace, Picker, Toast, DatePicker,WingBlank } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import { Link } from 'react-router-dom'
import { createForm } from 'rc-form'
import { postRequest } from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import CustomerSearch from '../../components/customer_search'
import AmapSearch from '../../components/amapSearch'
const Item = List.Item;
const types = [
        {
            label: '是',
            value: 0,
        },
        {
            label: '否',
            value: 1,
        },
    ]

class WaiChuEditor extends React.Component {
  static propTypes = {
    form:PropTypes.object.isRequired,
    getApper:PropTypes.func.isRequired,
    apper:PropTypes.array.isRequired,
      saveWaiChu:PropTypes.func.isRequired,
  }

  state = {
    files:null,
    start_time:'',
    apper:[],
      content:'',
      waichu_id:'',
      check_username:'',
      showMap:false,
      isShow:false,
      work:[0],
  };
    componentWillMount(){
    const waichu_id = localStorage.getItem('waichu_id');
    this.setState({waichu_id:waichu_id});
    if(waichu_id!=='')//判断是编辑还是新增
    {
        this.getDetail(waichu_id);
    }
}
  componentDidMount() {
    this.props.getApper();
  }
   //获取修改数据
    getDetail = async(waichu_id) => {
        postRequest(`/out/edit`, `id=${waichu_id}`).then((result) => {
            //console.log(result)
            if(result && result.status === 1) {
               // console.log(result.data.starttime);
                this.setState({
                    start_time:new Date(result.data.starttime),
                    apper:[result.data.check_id],
                    check_username:result.data.u_fullname,
                    content:result.data.content,
                    work:[result.data.work],
                    // isShow:true
                });

            } else {
                Toast.info(result.msg || '获取失败', 1);
                if(result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }


  onSubmit = () => {
    const { validateFields } = this.props.form
    const { start_time,apper,content,work } = this.state
    const { files, history, saveWaiChu } = this.props

    validateFields((err, values) => {
      let body =''
      if(start_time) {
        body += `starttime=${moment(start_time).format('YYYY-MM-DD')}`
      } else {
          Toast.info('外出时间为必填项', 1);
        return
      }
        if(work && work[0]!=null) {
            body += `&work=${work[0]}`
        }
        else {
            Toast.info('需要填写是否从公司出发', 1);
            return
        }
      if(apper && apper[0]) {
        body += `&check_id=${apper[0]}`
      } else {
          Toast.info('审批人为必填项', 1);
        return
      }
        if(content) {
            body += `&content=${content}`
        } else {
            Toast.info('外出事由为必填项', 1);
            return
        }
      if (!err) {
        //Toast.loading('新建中, 请稍等....')
          if(this.state.waichu_id!='')
          {
              body += `&id=${this.state.waichu_id}`
          }
          //console.log(body);
          saveWaiChu(body, history)
       }
    });
  }

  render() {
    const { getFieldProps } = this.props.form;
    const {  ctype, detail } = this.props;
    let apper = [];
      apper = this.props.apper.map((value, index) => {
      return {
        value:value.u_id,
        label:value.u_fullname
      }
    })
    return (
      <div className='index-content' id='wipeout_editor'>
      <NavBar
        mode="dark"
        icon={<Icon type="left" />}
        onLeftClick={() => this.props.history.goBack() }
        >外出申请</NavBar>
          <List  className="picker-list title_width my_list" renderHeader=''>

            <DatePicker
              mode="date"
              title="选择开始时间"
              extra="选择开始时间"
              value={this.state.start_time}
              onChange={date => {
                  // if(date>new Date())this.setState({ isShow:true }); //申请非当天的外出需填写外出当天是否未到公司直接外出
                  this.setState({ start_time:date });}}
            >
              <List.Item arrow="horizontal">外出时间<span className="xing">*</span></List.Item>
            </DatePicker>

                  <Picker
                      data={types}
                      extra="是否从公司出发"
                      value={this.state.work}
                      cols={1}
                      onChange={v => {this.setState({ work: v})}}
                  >
                      <List.Item arrow="horizontal">从公司出发<span className="xing">*</span></List.Item>
                  </Picker>

            <CustomerSearch selectvalue={this.state.apper} initialValue={this.state.check_username} customers={apper} onOk={(v) => {
                {this.setState({ apper: v })}
            }}  name='审批人'/>
          </List>
        <List renderHeader='' className="qj-list">

          <TextareaItem
              {...getFieldProps('content', {
                  rules:[{require: true}],
              })}
              value={this.state.content}
              onChange={value=>this.setState({content:value})}
              placeholder="请填写事由，需注明外出和返回时间"
              data-seed="logId"
              rows={4}
              ref={el => this.customFocusInst = el}
          />
        </List>
          <WhiteSpace />
          <WhiteSpace />
          <WhiteSpace />
        <WingBlank size="md">
          <Button type='primary' onClick={() => this.onSubmit()}>提交</Button>
        </WingBlank>
          <div className='daka_rule'>
              <span>注意事项</span>
              <p>
                  1）外出和返回公司时，需本人到门卫室协助查监控<br/>
                  2）公事耗时过长，16不能返回公司的，需提交申请，通过后在外出打卡界面打卡<br/>
                  3）晚于9点后的外出打卡算迟到， 早于16:00前的外出打卡算早退<br/>
              </p>
          </div>
      </div>
    )
  }
}

export default createForm()(WaiChuEditor)