import { connect } from 'react-redux'
import { getWaiChuBack,getXuJialist,deleteWaiChuBack} from './actions'
import Setting from './main'


const mapDispatchToProps = {
    getXuJialist,
}
const mapStateToProps = (state) => {
  return {
    ...state.XuJiaList
  }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
