import React from 'react'
import PropTypes from 'prop-types'
import {
    List,
    TextareaItem,
    Button,
    InputItem,
    NavBar,
    Checkbox,
    Icon,
    WhiteSpace,
    Picker,
    Toast,
    DatePicker,
    WingBlank
} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import {createForm} from 'rc-form'
import {postRequest} from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import CustomerSearch from '../../components/customer_search'
import AmapSearch from '../../components/amapSearch'

const Item = List.Item;
class XuJiaEditor extends React.Component {
    static propTypes = {
        form: PropTypes.object.isRequired,
        saveXuJia: PropTypes.func.isRequired,
    }

    state = {
        content: '',
        qingjia_id: '',
        starttime:'',
        endtime:'',
        xujiatime:'',
        xujia_id: '',
    };

    componentWillMount() {
        // const xujia_id = localStorage.getItem('xujia_id');
        // this.setState({xujia_id: xujia_id});
        // if (xujia_id !== '')//判断是编辑还是新增
        // {
        //     this.getDetail(xujia_id);
        // }
        const qingjia_id = localStorage.getItem('qingjia_id');
        if(qingjia_id!='')
        {
            this.getQingJiaData(qingjia_id);
        }
    }

    componentDidMount() {
    }

    //获取修改数据
    // getDetail = async (waichu_id) => {
    //     postRequest(`/outapply/edit`, `id=${waichu_id}`).then((result) => {
    //         //console.log(result)
    //         if (result && result.status === 1) {
    //             // console.log(result.data.starttime);
    //             this.setState({
    //                 content: result.data.content,
    //             });
    //
    //         } else {
    //             Toast.info(result.msg || '获取失败', 1);
    //             if (result.msg === '请登陆！') {
    //                 localStorage.removeItem('daka_token')
    //                 alert('账号已在别处登录,请重新登录')
    //                 this.props.history.push('/')
    //             }
    //         }
    //     })
    // }
    //获取请假日期
    getQingJiaData = async (qingjia_id) => {
        postRequest(`/xujia/look`, `id=${qingjia_id}`).then((result) => {
            //console.log(result)
            if (result && result.status === 1) {
                this.setState({
                    starttime: result.data.starttime,
                    endtime: result.data.endtime,
                });

            } else {
                Toast.info(result.msg || '获取失败', 1);
                if (result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }

    onSubmit = () => {
        const {validateFields} = this.props.form
        const {xujiatime,content} = this.state
        const {files, history, saveXuJia} = this.props
        const qingjia_id = localStorage.getItem('qingjia_id');
        validateFields((err, values) => {
            let body = '';
            if(xujiatime) {
                body += `endtime=${moment(xujiatime).format('YYYY-MM-DD HH:mm')}`
            } else {
                Toast.info('续假时间为必填项', 1);
                return
            }
            if (content) {
                body += `&content=${content}`
            } else {
                Toast.info('续假事由必填', 1);
                return
            }
            if (!err) {
                // if (this.state.waichuback_id != '') {//修改
                //     body += `&id=${this.state.waichuback_id}`
                // }
                //else {  //新增(获取请假申请的开始时间和结束时间)
                    body += `&qj_id=${qingjia_id}`
                //}
                //console.log(body);
                saveXuJia(body, history)
            }
        });
    }

    render() {
        const {getFieldProps} = this.props.form;
        const {ctype, detail} = this.props;

        return (
            <div className='index-content' id='wipeout_editor'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left"/>}
                    onLeftClick={() => this.props.history.goBack()}
                >续假申请</NavBar>
                <List renderHeader='' className="qj-list my_list">
                    <Item multipleLine wrap extra={this.state.starttime}>开始时间</Item>
                    <Item multipleLine wrap extra={this.state.endtime}>原结束时间</Item>
                    <DatePicker
                        mode="datetime"
                        minDate={new Date(this.state.endtime)}
                        title="续假时间"
                        extra="请填写续假时间"
                        value={this.state.xujiatime}
                        onChange={date => {
                            this.setState({ xujiatime:date });}}
                    >
                        <List.Item arrow="horizontal">续假时间<span className="xing">*</span></List.Item>
                    </DatePicker>
                    <TextareaItem
                        {...getFieldProps('content', {
                            rules: [{require: true}],
                        })}
                        value={this.state.content}
                        onChange={value => this.setState({content: value})}
                        placeholder="请填写续假事由"
                        data-seed="logId"
                        rows={4}
                        ref={el => this.customFocusInst = el}
                    />
                </List>
                <WhiteSpace/>
                <WhiteSpace/>
                <WhiteSpace/>
                <WingBlank size="md">
                    <Button type='primary' onClick={() => this.onSubmit()}>提交</Button>
                </WingBlank>

            </div>
        )
    }
}

export default createForm()(XuJiaEditor)