import React from 'react'
import PropTypes from 'prop-types'
import {
    List,
    TextareaItem,
    Button,
    InputItem,
    NavBar,
    Checkbox,
    Icon,
    WhiteSpace,
    Picker,
    Toast,
    DatePicker,
    WingBlank,
    ImagePicker
} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import {createForm} from 'rc-form'
import {postRequest} from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import CustomerSearch from '../../components/customer_search'

const Item = List.Item;

class QingjiaFileEditor extends React.Component {
    static propTypes = {
        form: PropTypes.object.isRequired,
        getApper: PropTypes.func.isRequired,
        apper: PropTypes.array.isRequired,
        postQingjiafileEditor: PropTypes.func.isRequired,
    }

    state = {
        data: [],
        qingjiaid: '',
        qingjiafile_id: '',
        shiyou: '',
        files: [],
        files2: [],
        showimg: false,
        qingjia_type: '', //请假类型
        btndisplay: true,
    };

    componentWillMount() {
        const qingjia_id = localStorage.getItem('qingjia_id');
        const qingjia_type = localStorage.getItem('qingjia_type');
        this.setState({qingjiaid: qingjia_id, qingjia_type: qingjia_type});
        // if(qingjiafile_id!=='')//判断是编辑还是新增
        // {
        //     this.getDetail(qingjiafile_id);
        // }
    }

    componentDidMount() {

    }

    getDetail = async (qingjia_id) => {
        postRequest(`/qingjiabucailiao/edit`, `id=${qingjia_id}`).then((result) => {
            //console.log(result)
            if (result && result.suc === 1) {
                let imgurl = result.data.imgurl;
                const list = [];
                list.push({"id": result.data.id, "url": imgurl});
                this.setState({
                    files: list,
                    content: result.data.content
                });
            } else {
                Toast.fail(result.msg || '获取失败', 1);
                if (result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }
//上传材料 type=1普通请假，2：特批请假
    onChange = (files, type, index) => {
        //console.log(files, type, index);
        if (type == 1) this.setState({files});
        if (type == 2) this.setState({files2: files});
    }
    onSegChange = (e) => {
        const index = e.nativeEvent.selectedSegmentIndex;
        this.setState({
            multiple: index === 1,
        });
    }

    onSubmit = () => {
        this.setState({btndisplay: false})
        const {validateFields} = this.props.form
        const {qingjiaid, shiyou, qingjia_type, files, files2} = this.state
        const {history, postQingjiafileEditor} = this.props
        let fileNum = 0
        validateFields((err, values) => {
            let body = ''
            if (shiyou) {
                body += `content=${shiyou}`
            }
            else {
                this.setState({btndisplay: true})
                Toast.info('请填写文字描述', 2);
                return
            }
            if (qingjiaid != '') {
                body += `&qj_id=${qingjiaid}`
            }
            if (files[0] != 'undefined' && files[0] != null) {
                body += `&imgurl=${files[0].url}`;
                fileNum = fileNum + 1
            }
            if (qingjia_type == '特批出勤') {//核酸报告
                if (files2[0] != 'undefined' && files2[0] != null) {
                    body += `&tpurl=${files2[0].url}`;
                    fileNum = fileNum + 1
                }
            }
            if (fileNum < 1) {
                this.setState({btndisplay: true})
                Toast.info('至少上传一个材料', 2);
                return
            }
            if (!err) {
                // if(this.state.qingjiaid!='')
                // {
                //     body += `&id=${this.state.qingjiaid}`
                // }
                //console.log(body);
                Toast.loading('操作中, 请稍等....')
                postQingjiafileEditor(body, history).then(result => {
                    if (result) {
                        if (result.suc === 0) {
                            this.setState({btndisplay: true})
                            Toast.hide()
                            Toast.info(result.msg, 3);
                            if (result.msg === '请登陆！') {
                                localStorage.removeItem('daka_token')
                                alert('账号已在别处登录,请重新登录')
                                this.props.history.push('/')
                            }
                        } else {
                            Toast.hide()
                            Toast.success(result.msg, 2,()=>{history.push('/qingjia/list');});
                        }
                    }
                    else {
                        this.setState({btndisplay: true})
                        Toast.hide()
                        Toast.fail('上传材料接口错误，请联系信息开发部', 4);
                    }
                })
            }
        });
    }


    render() {
        const {getFieldProps} = this.props.form
        const {ctype, detail} = this.props;
        const {files, qingjia_type, files2} = this.state;
        return (
            <div className='index-content' id='wipeout_editor'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left"/>}
                    onLeftClick={() => this.props.history.goBack()}
                >请假证明材料</NavBar>
                <List className="picker-list my_list" renderHeader=''>
                    <div className='upload_title'>证明材料<span className="xing">*</span></div>
                    <ImagePicker className='upload_img'
                                 files={files}
                                 multiple={false}
                                 length={1}
                                 onChange={(files) => this.onChange(files, 1)}
                                 onImageClick={(index, fs) => console.log(index, fs)}
                                 selectable={files.length < 1}
                    />
                </List>
                {
                    qingjia_type == '特批出勤' &&
                    <List className="picker-list my_list" renderHeader=''>
                        <div className='upload_title'>核酸检测结果<span className="xing">*</span></div>
                        <ImagePicker className='upload_img'
                                     files={files2}
                                     multiple={false}
                                     length={1}
                                     onChange={(files) => this.onChange(files, 2)}
                                     onImageClick={(index, fs) => console.log(index, fs)}
                                     selectable={files2.length < 1}
                        />
                    </List>
                }
                <List renderHeader='' className="qj-list">
                    <TextareaItem
                        {...getFieldProps('shiyou', {
                            rules: [{require: true}],
                        })}
                        value={this.state.shiyou}
                        onChange={value => {
                            this.setState({shiyou: value})
                        }}
                        title={<div><span>描述</span><span className="xing">*</span></div>}
                        placeholder="请文字描述材料"
                        data-seed="logId"
                        rows={4}
                        ref={el => this.customFocusInst = el}
                    />
                </List>
                <WhiteSpace/>
                <WhiteSpace/>
                <WhiteSpace/>
                <WingBlank size="md">
                    {this.state.btndisplay &&
                    <Button type='primary' onClick={() => this.onSubmit()}>提交</Button>
                    }
                </WingBlank>

            </div>
        )
    }
}

export default createForm()(QingjiaFileEditor)