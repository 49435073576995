import fetch from 'cross-fetch'

export const postRequest = async (url, data, formdata) => {
    const token = localStorage.getItem('daka_token') || ''
    let initHeaders = {
        'Accept': 'application/json, text/plain, */*',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8; multipart/form-data',
        'maxPostSize': '50242880',
        'Authorization': token,
        'token': token
    }
    let body = ''
    body = `${data || ''}`
    if (formdata) {
        body = formdata
    }
    const init = {
        method: 'POST',
        credentials: 'include', // cookies
        cache: 'no-cache ', // cookies
        headers: initHeaders,
        maxPostSize: "50242880",
        body
    }
    try {
        const res = await fetch(
            `/index${url}`,
            init
        )
        const users = await res.json()
        return users
    } catch (error) {
        console.log('错误', error)
    }
}
//参数为键值对的请求
export const postRequest2 = async (url, data, formdata) => {
    const token = localStorage.getItem('daka_token') || ''
    let initHeaders = {
        'Accept': 'application/json, text/plain, */*',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8; multipart/form-data',
        'maxPostSize': '50242880',
        'Authorization': token,
        'token': token
    }
    let body = ''
    if (data) {
        Object.keys(data).forEach((key, index) => {
            if (index === 0) {
                body = `${key}=${data[key]}`
            } else {
                body += `&${key}=${data[key]}`
            }
        })
    }
    const init = {
        method: 'POST',
        credentials: 'include', // cookies
        cache: 'no-cache ', // cookies
        headers: initHeaders,
        maxPostSize: "50242880",
        body
    }
    try {
        const res = await fetch(
            `/index${url}`,
            init
        )
        const users = await res.json()
        return users
    } catch (error) {
        console.log('错误', error)
    }
}

export const backpromise = async (url, data, formdata) => {
    const token = localStorage.getItem('daka_token') || ''
    let initHeaders = {
        'Accept': 'application/json, text/plain, */*',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8; multipart/form-data',
        'maxPostSize': '50242880',
        'Authorization': token,
        'token': token
    }
    let body = ''
    body = `${data || ''}`
    if (formdata) {
        body = formdata
    }
    const init = {
        method: 'POST',
        credentials: 'include', // cookies
        cache: 'no-cache ', // cookies
        headers: initHeaders,
        maxPostSize: "50242880",
        body
    }
    try {
        const res = await new Promise(function (resolve, reject) {
            fetch(
                `/index${url}`,
                init
            )
        })

        //     .then(response => {
        //     console.log(response.json())
        //     return response.json();
        // }).then(data=>{
        //     console.log(data)
        // }).catch(err => {
        //     return {error: {message: 'Request failed.'}};
        // })
        console.log('aaa')
        return res
    } catch (error) {
        console.log('错误', error)
    }
}
//记录用户操作日志
export const saveLog = () => {
    postRequest(`/login/queka_tx`, '').then((result) => {
        console.log(result)
        if (result.status > 0) {
        }
    })
}
//判断是否可以打卡
export const checkDaka = (status, jingdu, weidu,address) => {
        //const jingdu='122.08624';const weidu='39.10037';
        return postRequest(`/login/check`, `status=${status}&jingdu=${jingdu}&weidu=${weidu}&address=${address || ''}`).then((result) => {
            return result
        })

}

export const getUserInfo = () => {
    let user = {}
    if (typeof(localStorage.getItem('userInfo')) == 'string') {
        user = JSON.parse(localStorage.getItem('userInfo'))
    }
    return user
}

