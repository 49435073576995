import React from 'react'
import PropTypes from 'prop-types'
import { List, TextareaItem, Button, InputItem, NavBar, Checkbox, Icon, ImagePicker, Picker, Toast, DatePicker,WingBlank,Steps,Modal } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import { Link } from 'react-router-dom'
import { createForm } from 'rc-form'
import { postRequest } from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import CustomerSearch from '../../components/customer_search'
const Item = List.Item;
const types = [
        {
            label: '内部考勤',
            value: 2,
        },
        {
            label: '外出考勤',
            value: 1,
        },
    ]
const Step = Steps.Step;
const alert = Modal.alert;
class BukaCheck extends React.Component {
  static propTypes = {
    form:PropTypes.object.isRequired,
      checkBuka:PropTypes.func.isRequired
  }

  state = {
      buka_id:'',
      data:{},
      option:'',
      checkers:[],
      bk_time:'',
      status:'',
      showbtn:true,
      files: [],
  };

  componentDidMount() {
    this.getDetail();
    this.getChecks();
  }
    getDetail = async() => {
        const buka_id = localStorage.getItem('buka_id');
        this.setState({buka_id:buka_id});
        postRequest(`/bk/check_view`, `id=${buka_id}`).then((result) => {
            console.log(result)
            if(result && result.status === 1) {
                this.setState({data:result.data.list,status:result.data.list.status})
            } else {
                Toast.fail(result.msg || '获取失败', 1);
                if(result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }
    //获取主管
    getChecks = async() => {
        postRequest(`/yijiao/zhuguan`, ``).then((result) => {
            //console.log(result)
            if(result && result.status === 1) {
                this.setState({checkers:result.data})
            }
        })
    }
    //移交保存
    YiJiao = async(checkerid) => {
        const buka_id = localStorage.getItem('buka_id');
        let body2=`id=${buka_id}&mokuai=bk`;
        postRequest(`/yijiao/yj`, body2).then((result) => {
            if(result && result.status === 1) {
                const shijian = result.data.shijian
                let body = `check_id=${checkerid}&id=${buka_id}&mokuai=bk&shijian=${shijian}`;
                postRequest(`/yijiao/save`, body).then((result) => {
                    //console.log(result)
                    if (result && result.status === 1) {
                        Toast.success('移交成功', 1);
                    }
                })
            } })
    }

    agree = (status) => {
        const {history, checkBuka } = this.props
        let body =`id=${this.state.buka_id}&status=${status}&content=${this.state.option}`
        if(this.state.status==4)
        {
            if(this.state.bk_time) {
                body += `&shijian=${moment(this.state.bk_time).format('YYYY-MM-DD HH:mm')}`
            } else {
                Toast.info('门卫审核，补卡时间必填', 1);
                this.setState({showbtn:true});
                return
            }
            if (this.state.files[0] != 'undefined' && this.state.files[0] != null) {
                body += `&imgurl=${this.state.files[0].url}`;
            }
            else{
                Toast.info('门卫审核，监控照片必须上传', 1);
                this.setState({showbtn:true});
                return
            }
        }
        if(status==2){
            if(this.state.option) {
            } else {
                Toast.info('审核不通过时，必须填写理由', 1);
                this.setState({showbtn:true})
                return
            }
        }
        //console.log(body);
        checkBuka(body, history)
  }
//上传材料
    onChange = (files, type, index) => {
        //console.log(files, type, index);
        this.setState({
            files,
        });
    }
    render() {
        const { data,checkers,showbtn,files } = this.state;
        const user = JSON.parse(localStorage.getItem('daka_user'))
        const u_id = user.u_id;
        let checkername = [];
        checkername = checkers.map((value, index) => {
            return {
                value: value.u_id,
                label: value.u_fullname
            }
        });
        return (
            <div style={{backgroundColor:'#f5f5f7', minHeight:document.documentElement.clientHeight }}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack() }
                >补卡审核</NavBar>
                <List className="my-list detail_list" renderHeader=''>
                    <Item multipleLine wrap extra={data.u_fullname}>申请人</Item>
                    <Item multipleLine wrap extra={data.shijian}>补卡时间</Item>
                    <Item multipleLine wrap extra={data.type==3?'外出考勤':'内部考勤'}>补卡类型</Item>
                    {data.type==3?
                        <Item multipleLine wrap extra={data.address}>打卡地址</Item>:''
                    }
                    <Item multipleLine wrap extra={data.content}>补卡事由</Item>
                </List>
                <div className='img_box'>
                    <p>监控照片</p>
                    {
                        data.imgurl ?
                        <img onClick={() => {
                            const large = this.state.isLarge;
                            this.setState({isLarge: !large});
                            this.setState({imgurl: data.imgurl})
                        }} src={data.imgurl} alt=""/>:
                            <span>暂无图片</span>
                    }
                </div>
                {(data.status==0&&data.check_id==u_id)||(data.status==4&&data.menwei_id==u_id)||(data.status==3&&data.jingli_id==u_id)?
                <List renderHeader='审批意见'>
                    {data.status==4?
                        <DatePicker
                            mode="datetime"
                            title="选择补卡时间"
                            extra="选择补卡时间"
                            value={this.state.bk_time}
                            onChange={date => this.setState({ bk_time:date })}
                        >
                            <List.Item arrow="horizontal">补卡时间<span className="xing">*</span></List.Item>
                        </DatePicker>:''}
                    <div className='upload_title'>监控照片</div>
                    <ImagePicker className='upload_img'
                                 files={files}
                                 multiple={false}
                                 length={1}
                                 onChange={this.onChange}
                                 onImageClick={(index, fs) => console.log(index, fs)}
                                 selectable={files.length < 1}
                    />
                    <TextareaItem
                        value={this.state.option}
                        onChange={value=>this.setState({option:value})}
                        placeholder="请填写审批意见"
                        data-seed="logId"
                        rows={4}
                        ref={el => this.customFocusInst = el}
                    />
                </List>:''}
                {data.check==''?'':
                <List renderHeader={() => '审批流程'} className="my-list">
                    <Steps className='check_step' size="small" current={7}>
                        {data.check==null?'':
                            data.check.map(i=>(i.check_id==null?'':
                                    <Step key={i.id}  status={i.status==2?'error':'process'}
                                          title=
                                              {<div className='check_user'>
                                                  <span>{i.check_id}</span>
                                                  <span className='check_time'>{i.reply_time}</span>
                                              </div>}
                                          description={<div><span className='check_status'>
                                        {i.status==3||i.status==4||i.status==5?'审核通过':(i.status==1?'已登记':'审核未通过')}</span>
                                              {i.content==''?'':<span>({i.content})</span>}</div>}
                                    />
                            ))
                        }
                    </Steps>
                </List>
                }
                <div style={{height:'40px'}}></div>
                {
                    data.status==1||data.status==2?'':
                        !showbtn?'':
                    data.status == 5 ?
                    <div className='bottom_btn'>
                        <div className='bottom_left' onClick={() =>{this.setState({showbtn:false}); this.agree(1)}}>登记</div>
                        <Picker
                            data={checkername}
                            extra="移交主管"
                            value={this.state.checkerID}
                            cols={1}
                            onOk={(v)=>{this.YiJiao(v)}}
                        >
                            <div className='bottom_right'>移交</div>
                        </Picker>

                    </div>
                    :
                        ((data.status==0&&data.check_id==u_id)||(data.status==4&&data.menwei_id==u_id)||(data.status==3&&data.jingli_id==u_id))&&data.status!=6?
                    <div className='bottom_btn'>
                        <div className='bottom_left' onClick={() => {this.setState({showbtn:false});this.agree(2)}}>不同意</div>
                        <div className='bottom_right' onClick={() =>
                        {
                            alert('', '确定提交吗？', [
                                { text: '取消', onPress: () => console.log('cancel') },
                                { text: '确定', onPress: () => {this.setState({showbtn:false});this.agree(1)} },
                            ])
                        }
                            }>同意</div>
                        <Picker
                            data={checkername}
                            extra="移交主管"
                            value={this.state.checkerID}
                            cols={1}
                            onOk={(v)=>{this.YiJiao(v)}}
                        >
                            <div className='bottom_right'>移交</div>
                        </Picker>
                    </div>:''

                }
                {this.state.isLarge ?
                    <div onClick={() => {
                        const large = this.state.isLarge;
                        this.setState({isLarge: !large})
                    }} className='img_view'>
                        <img src={this.state.imgurl}/>
                    </div> : ''
                }
            </div>
        )
    }
}

export default createForm()(BukaCheck)