import React from 'react'
import PropTypes from 'prop-types'
import { List, TextareaItem, Button, InputItem, NavBar, Checkbox, Icon, WhiteSpace, Picker, Toast, DatePicker,WingBlank,Steps } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import { Link } from 'react-router-dom'
import { createForm } from 'rc-form'
import { postRequest } from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import CustomerSearch from '../../components/customer_search'
const Item = List.Item;
const types = [
        {
            label: '内部考勤',
            value: 2,
        },
        {
            label: '外出考勤',
            value: 1,
        },
    ]
const Step = Steps.Step;
class JiaBanYCCheck extends React.Component {
  static propTypes = {
    form:PropTypes.object.isRequired,
      checkJiaBanYC:PropTypes.func.isRequired
  }

  state = {
      buka_id:'',
      data:{},
      option:'',
      showbtn:true,
  };

  componentDidMount() {
    this.getDetail();
  }
    getDetail = async() => {
        const buka_id = localStorage.getItem('jiabanyc_id');
        this.setState({buka_id:buka_id});
        postRequest(`/yanchang/checkview`, `id=${buka_id}`).then((result) => {
            //console.log(result)
            if(result && result.status === 1) {
                this.setState({data:result.data})
            } else {
                Toast.fail(result.msg || '获取失败', 1);
                if(result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }


    agree = (status) => {

        const {history, checkJiaBanYC } = this.props
        let body =`id=${this.state.buka_id}&status=${status}&content=${this.state.option}`
        if(status==2){
            if(this.state.option) {

            } else {
                Toast.info('审核不通过时，必须填写理由', 1);
                this.setState({showbtn:true})
                return
            }
        }
        //console.log(body);
        checkJiaBanYC(body, history)
  }

    render() {
        const { data,showbtn } = this.state;
        const user = JSON.parse(localStorage.getItem('daka_user'))
        const u_id = user.u_id;
        //console.log(data);
        return (
            <div style={{backgroundColor:'#f5f5f7', minHeight:document.documentElement.clientHeight }}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack() }
                >加班延长审核</NavBar>
                <List className="my-list jb_list detail_list" renderHeader=''>
                    <Item multipleLine wrap extra={data.user_id}>申请人</Item>
                    <Item multipleLine wrap extra={<span
                        dangerouslySetInnerHTML={{__html: data.shijian}}></span>}>延长时间</Item>
                    <Item multipleLine wrap extra={data.content}>延长事由</Item>

                </List>
                {u_id==data.check_name_id?
                <List renderHeader='' className="qj-list">
                    <TextareaItem
                        value={this.state.option}
                        onChange={value=>this.setState({option:value})}
                        placeholder="请填写审批意见"
                        data-seed="logId"
                        rows={4}
                        ref={el => this.customFocusInst = el}
                    />
                </List>:''}
                {data.record==''?'':
                <List renderHeader={() => '审核意见'} className="my-list">
                    <Steps className='check_step' size="small" current={5}>
                        {data.record==null?'':
                            data.record.map(i=>(i.check_id==null?'':
                                    <Step key={i.id}  status={i.status==2?'error':'process'}
                                          title=
                                              {<div className='check_user'>
                                                  <span>{i.check_id}</span>
                                                  <span className='check_time'>{i.reply_time}</span>
                                              </div>}
                                          description={<div><span className='check_status'>
                                        {i.status==3||i.status==4?'审核通过':(i.status==1?'已登记':'审核未通过')}</span>
                                              {i.reply==''?'':<span>({i.reply})</span>}</div>}
                                    />
                            ))
                        }
                    </Steps>
                </List>
                }
                <div style={{height:'40px'}}></div>
                {

                        !showbtn?'':
                            u_id==data.check_name_id?
                            <div>
                                {data.status == 0 ?
                            <div className='bottom_btn'>
                            <div className='bottom_left' onClick={() => {this.setState({showbtn:false});this.agree(2)}}>不同意</div>
                            <div className='bottom_right' onClick={() => {this.setState({showbtn:false});this.agree(3)}}>同意</div>
                            </div>:
                            <div className='bottom_btn'>
                            <div className='bottom_right' onClick={() => {this.setState({showbtn:false});this.agree(1)}}>登记</div>
                            </div>}
                            </div>:''
                }
            </div>
        )
    }
}

export default createForm()(JiaBanYCCheck)