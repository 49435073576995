import React from 'react'
import { Link, Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import { InputItem, Modal, List, SearchBar, PickerView, Button} from 'antd-mobile'
import { createForm } from 'rc-form'


class CustomerSearch extends React.Component {
  static propTypes = {
    selectedTab:PropTypes.string,
    form:PropTypes.object.isRequired,
    customers:PropTypes.array.isRequired,
    initialValue:PropTypes.string,
    onOk:PropTypes.func.isRequired,
    name:PropTypes.string,
  }

  state = {
    selectedTab: 'index',
    hidden: false,
    fullScreen: false,
    modal2:false,
    customers:[],
    value:'',
    name:''
  };
  componentWillReceiveProps(nextProps) {
    
   if(this.props.name!=nextProps.name)
    this.setState({name: nextProps.name});
  }

  render() {
    const { initialValue } = this.props
    const { customers } = this.state
    const { getFieldProps } = this.props.form
    
    return (
        <div>
          <InputItem
              {...getFieldProps('customer', {
                rules:[{require: true}],
                initialValue:initialValue
              })}
              clear
              value={this.state.name || this.props.initialValue}
              placeholder='请选择审批人'
              onFocus={() => {
                this.setState({customers:this.props.customers})
                this.setState({modal2:true})
              }}
            >审批人<span className="xing">*</span></InputItem>
            <Modal
              popup
              visible={this.state.modal2}
              animationType="slide-up"
            >
              <div style={{display:'flex'}}>
                <span style={{ width:60, lineHeight:'36px', fontSize:18, color:'red'}} onClick={() => {
                  this.setState({ modal2: false })
                }}>取消</span>
                <div style={{flex:1, textAlign:'left'}}>
                  
                </div>
                <span style={{width:60, lineHeight:'32px', fontSize:18, color:'#007aff'}} onClick={() => {
                  let name = ''
                  if(this.state.value && this.state.value.length > 0) {
                    const c = customers.find((v) => {
                      return v.value === this.state.value[0]
                    })
                    name = c.label
                    this.props.onOk(this.state.value,name)
                  } else {
                    name = customers[0].label
                    this.props.onOk([customers[0].value],name)
                  }
                  
                  this.setState({ modal2: false, name })
                }}>确定</span>
              </div>
              <SearchBar onChange={(value) => {
                let arr = []
                this.props.customers.forEach((v, index) => {
                  if( v.label.indexOf(value) >= 0) {
                    arr.push(v)
                  }
                })
                this.setState({customers:arr})
              }} />
              <PickerView
                onChange={(value) => {
                  this.setState({value})
                }}
                value={this.state.value||this.props.selectvalue}
                data={customers}
                cascade={false}
              />
            </Modal>
        </div>
      )
  }
}

export default createForm()(CustomerSearch)
