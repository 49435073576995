import { connect } from 'react-redux'
import { getApper, postJiabanEditor,getuser } from './actions'
import Setting from './main'


const mapDispatchToProps = {
  getApper,
  postJiabanEditor,
    getuser
}
const mapStateToProps = (state) => {
  return {
    ...state.JiabanEditor
  }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
