import { postRequest, getUserInfo } from '../../../utils/utils';
import {Toast} from 'antd-mobile';
// ------------------------------------
// Constants
// ------------------------------------
export const QINGJIA_LIST = 'QINGJIA_LIST'

// ------------------------------------
// Actions
// ------------------------------------


export const getQingjia = (pagesize,oldData,type, keyword) => {
  return async (dispatch, getState) => {
    postRequest(`/qingjia/applylist`, `keyword=${keyword || ''}&&page_size=${pagesize}`).then((value) => {
        console.log(value);
      if(value.suc === 0) {
          dispatch({ type: QINGJIA_LIST, payload:{qjlistdata:[],noqj: false ,count:0} })
          //Toast.info(value.msg, 1);
        if(value.msg === '请登陆！') {
          localStorage.removeItem('daka_token')
          alert('账号已在别处登录,请重新登录')
          this.props.history.push('/')
        }
      } else {
        //console.log(value)
          if(value.data!='') {
              let newlist=[];
              newlist = value.data.shuju
              if(oldData && oldData.length > 0) {
                  newlist = oldData.concat(value.data.shuju)
              }
              dispatch({type: QINGJIA_LIST, payload: {qjlistdata: value.data.shuju, noqj: true,count:value.data.count}})
          }
          else {
              dispatch({ type: QINGJIA_LIST, payload:{qjlistdata:[],noqj: false,count:0 } })
          }
      }
    })
  }
}

export const getOverTime = (pagesize,oldData,type, keyword) => {
  return async (dispatch, getState) => {
    postRequest(`/qingjia/checklist`, `keyword=${keyword || ''}&&page_size=${pagesize}`).then((value) => {
        console.log(value)
      if(value.suc === 0) {
          dispatch({ type: QINGJIA_LIST, payload:{qjcheckData:[],noqjcheck: false ,count2:0 } })
          //Toast.info(value.msg, 1);
        if(value.msg === '请登陆！') {
          localStorage.removeItem('daka_token')
          alert('账号已在别处登录,请重新登录')
          this.props.history.push('/')
        }
      } else {
        //console.log(value)
          if(value.data!='') {
              let newlist=[];
              newlist = value.data.shuju
              if(oldData && oldData.length > 0) {
                  newlist = oldData.concat(value.data.shuju)
              }
              dispatch({type: QINGJIA_LIST, payload: {qjcheckData: newlist,noqjcheck:true,count2:value.data.count}})
          }
          else {
              dispatch({ type: QINGJIA_LIST, payload:{qjcheckData:[],noqjcheck: false,count2:0 } })
          }
      }
    })
  }
}

export const deleteQingjia = (body,history) => {
    return async (dispatch, getState) => {
        //console.log(body);
        postRequest(`/qingjia/del`, body).then((value) => {
            //console.log(value);
            if(value.suc === 0) {
                Toast.info(value.msg, 1);
                if(value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            } else {
                Toast.success('删除成功！', 1);
                dispatch(getQingjia());
            }
        })
    }
}
