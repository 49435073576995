import React from 'react'
import PropTypes from 'prop-types'
import {Flex, WingBlank, WhiteSpace, NavBar, List, Toast, Button, Icon, Tabs, ImagePicker} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import BottomTab from '../components/bottomTab'

import Work from '../../images/work.png'
import offwork from '../../images/offwork.png'
import Person from '../../images/person.png'
import address from '../../images/address.png'
import {postRequest} from '../../utils/utils.js'

import './index.css'

const Item = List.Item;
const Brief = Item.Brief;
var map, geolocation


class zufangDaka extends React.Component {
    static propTypes = {}

    state = {
        selectedTab: 'index',
        clheight: '',
        data: [],
        files: [],
        address: '',
        jingdu: '',
        weidu: '',
        btnstate1: true,
        btnstate2: true,
        amtime: '',
        pmtime: '',
        offworkTime: '17:00',
        juli: 0, //家到公司的距离
        isdaka: false
    };

    componentDidMount() {
        this.CheckDaka()
       this.getGPS()
    }
    //地图定位
    getGPS = () => {
        const {AMap,} = window
        map = new AMap.Map('mapContainer', {
            resizeEnable: true,
            zoom: 12
        });
        map.plugin('AMap.Geolocation', () => {
            geolocation = new AMap.Geolocation({
                enableHighAccuracy: true,//是否使用高精度定位，默认:true
                //timeout: 10000,          //超过10秒后停止定位，默认：无穷大
                buttonOffset: new AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
                zoomToAccuracy: true,      //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                buttonPosition: 'RT'
            });
            map.addControl(geolocation);
            geolocation.getCurrentPosition();
            Toast.loading('定位中..., 请稍后', 30);
            AMap.event.addListener(geolocation, 'complete', (data) => {
                console.log(data)
                const jingdu = data.position.lng;
                const weidu = data.position.lat;
                this.setState(
                    {
                        jingdu: data.position.lng,
                        weidu: data.position.lat,
                        address: data.formattedAddress||'未获取到地址,请重新地位',
                        province: data.addressComponent.province,
                        city: data.addressComponent.city
                    })//获取经度
                if (data.formattedAddress) {
                    Toast.hide()
                }
                else {
                    Toast.hide()
                    Toast.fail('未获取到定位信息，请重新定位', 6);
                }
            });//返回定位信息
            AMap.event.addListener(geolocation, 'error', (data) => {
                console.log(data);
            });      //返回定位出错信息
        });
    }

    onChange = (files, type, index) => {
        this.setState({
            files,
        });
        this.handleSendDaka();
    }
//打卡
    handleSendDaka = async () => {
        const {jingdu,weidu,address,province,city}=this.state
        Toast.loading('打卡中..., 请稍后', 60);
        let body = `jingdu=${jingdu}&weidu=${weidu}&address=${address}&province=${province}&city=${city}`
        let imgurl;
        if (this.state.files[0] != 'undefined' && this.state.files[0] != null) {
            imgurl = this.state.files[0].url;
        }
        if (imgurl != '' && imgurl != 'undefined' && imgurl != null) {
            body += `&imgurl=${imgurl}`;
        }
        //console.log(body);
        postRequest('/zufang/daka', body).then((result) => {
            console.log(result);
            if (result) {
                if (result.status === 1) {
                    Toast.hide()
                    Toast.success('打卡成功', 2);
                }
                else {
                    if (result.msg === '请登陆') {
                        localStorage.removeItem('daka_token')
                        Toast.info('账号已在别处登录,请重新登录', 4);
                        this.props.history.push({pathname: '/login'})
                    }
                    else Toast.fail(result.msg || '租房打卡失败，请稍后再试', 4);
                }
            } else {
                Toast.info('/zufang/daka接口错误，请联系信息开发部', 5);
            }
        })
    }
    //检查是否打卡
    CheckDaka = () => {
        postRequest(`/zufang/check`, '').then((result) => {
            if(result){
                if (result.status == 0) {
                    this.setState({isdaka: true})
                }
                else this.setState({isdaka: false})
            }
           else this.setState({isdaka: false})
        })
    }
    //重新定位
    getLocation = async () => {
        geolocation.getCurrentPosition();
    }

    render() {
        const {data, files} = this.state
        const daka_user = JSON.parse(localStorage.getItem('daka_user'))

        return (
            <div className='index-content'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left"/>}
                    onLeftClick={() => this.props.history.goBack()}
                >租房打卡</NavBar>
                <div className="contentBox">
                    {/*头部*/}
                    <List className="my-list">
                        <Item className="top_list" align="top" thumb={
                            daka_user.u_avatar == null ? Person : `http://jd.stycie.com/Upload/${daka_user.u_avatar}`}
                              multipleLine>
                            <div>
                                {daka_user.u_fullname}
                                <Brief>部门：{daka_user.depart}</Brief>
                            </div>

                        </Item>
                    </List>
                    {/*头部*/}
                    <div className='address_content'>
                        <div id="mapContainer"></div>
                    </div>
                    <List className="my-list address">
                        <Item>
                            <div className='site_title'>
                                <img src={address}/>
                                <span>我的位置</span>
                                <span onClick={this.getLocation} style={{color: '#3182D2'}}>（重新定位）</span>
                            </div>
                            <Brief>{this.state.address}</Brief>
                        </Item>
                    </List>
                    <WhiteSpace/>
                    <div>
                        <div className="daka_bottom">
                            {
                                files && files.length > 0?
                                    <ImagePicker className='homeimg'
                                                 files={files}
                                                 length="1"
                                                 capture='camera'
                                                 selectable={files.length < 1}
                                                 multiple={false}
                                                 disableDelete
                                    />:
                                    <span className='daka-btn' onClick={() => {
                                        var myDate = new Date();
                                        var hours = myDate.getHours();
                                        var min = myDate.getMinutes();
                                        console.log(hours)
                                    if(this.state.isdaka){
                                        if((hours>21||(hours==21&&min>=0))||(hours<7||(hours==7&&min==0)))
                                            document.getElementById('tempdk').getElementsByTagName('input')[0].click()
                                        else  {console.log('bbb'); Toast.info('打卡时间为晚上21:00-早上7:00！', 5);}
                                    }
                                    else Toast.info('今日已经打过卡了！', 3);
                                }}>租房打卡</span>

                            }
                            <WhiteSpace/>
                        </div>
                        <div style={{display: 'none'}} id='tempdk'>
                            <ImagePicker className='homeimg'
                                         files={files}
                                         length="1"
                                         onChange={
                                             (files, type, index) => {
                                                 this.onChange(files, type, index)
                                             }
                                         }
                                         capture='camera'
                                         onImageClick={(index, fs) => console.log(index, fs)}
                                         selectable={files.length < 1}
                                         multiple={false}
                                         style={{display: 'none'}}
                                         disableDelete
                            />
                        </div>
                        <div style={{height: '53px'}}></div>
                    </div>


                </div>
            </div>
        )
    }
}

export default zufangDaka
