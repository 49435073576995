import { reducer as HomeReducer } from './home';
import { reducer as SettingListReducer } from './setting/list';
import { reducer as RecordReducer } from './record';

import { reducer as QingjiaListReducer } from './qingjia/list';
import { reducer as QingjiaEditorReducer } from './qingjia/editor';
import { reducer as QingjiaCheckReducer } from './qingjia/check';

import { reducer as JiabanListReducer } from './jiaban/list';
import { reducer as JiabanEditorReducer } from './jiaban/editor';

import { reducer as BukaListReducer } from './buka/list';
import { reducer as BukaEditorReducer } from './buka/editor';
import { reducer as BukaCheckReducer } from './buka/check';
import { reducer as WaiChuListReducer } from './waichu/list';
import { reducer as WaiChuEditorReducer } from './waichu/editor';
import { reducer as WaiChuCheckReducer } from './waichu/check';
import { reducer as JiaBanCheckReducer } from './jiaban/check';
import { reducer as JBBukaListReducer } from './JiabBanbuka/list';
import { reducer as JBBukaEditorReducer } from './JiabBanbuka/editor';
import { reducer as JBBukaCheckReducer } from './JiabBanbuka/check';
import { reducer as JiaBanYCListReducer } from './JiabBanYanChang/list';
import { reducer as JiaBanYCEditorReducer } from './JiabBanYanChang/editor';
import { reducer as JiaBanYCCheckReducer } from './JiabBanYanChang/check';
import { reducer as WaiChuBackListReducer } from './waichuback/list';
import { reducer as WaiChuBackEditorReducer } from './waichuback/editor';
import { reducer as WaiChuBackCheckReducer } from './waichuback/check';
import { reducer as XuJiaListReducer } from './xujia/list';
import { reducer as XuJiaEditorReducer } from './xujia/editor';
import { reducer as XuJiaCheckReducer } from './xujia/check';

import { reducer as QingJiaDelListReducer } from './qingjiadel/list';
import { reducer as QingJiaDelEditorReducer } from './qingjiadel/editor';
import { reducer as QingJiaDelCheckReducer } from './qingjiadel/check';

import { reducer as XiaoJiaListReducer } from './xiaojia/list';
import { reducer as XiaoJiaEditorReducer } from './xiaojia/editor';
import { reducer as XiaoJiaCheckReducer } from './xiaojia/check';

import { reducer as JiaBanMealsListReducer } from './jiabanMeals/list';
import { reducer as JiaBanMealsEditorReducer } from './jiabanMeals/editor';
import { reducer as JiaBanMealsCheckReducer } from './jiabanMeals/check';

import { reducer as QingJiaFileListReducer } from './qingjiafile/list';
import { reducer as QingjiaFileEditorReducer } from './qingjiafile/editor';
import { reducer as QingJiaFileCheckReducer } from './qingjiafile/check';
import { reducer as ZufangDakaReducer } from './zufangDaka';

const reducer = {
  home: HomeReducer,
  SettingList:SettingListReducer,
  record:RecordReducer,
  QingjiaList:QingjiaListReducer,
  QingjiaEditor:QingjiaEditorReducer,
  JiabanList:JiabanListReducer,
  JiabanEditor:JiabanEditorReducer,
    BukaList:BukaListReducer,
      BukaEditor:BukaEditorReducer,
    BukaCheck:BukaCheckReducer,
    WaiChuList:WaiChuListReducer,
    WaiChuEditor:WaiChuEditorReducer,
    WaiChuCheck:WaiChuCheckReducer,
    QingjiaCheck:QingjiaCheckReducer,
    JiabanCheck:JiaBanCheckReducer,
    JiaBanBKList:JBBukaListReducer,
    JiaBanBukaEditor:JBBukaEditorReducer,
    JBBukaCheck:JBBukaCheckReducer,
    JiaBanYCList:JiaBanYCListReducer,
    JiaBanYCEditor:JiaBanYCEditorReducer,
    JiaBanYCCheck:JiaBanYCCheckReducer,
    WaiChuBackList:WaiChuBackListReducer,
    WaiChuBackEditor:WaiChuBackEditorReducer,
    WaiChuBackCheck:WaiChuBackCheckReducer,
    XuJiaList:XuJiaListReducer,
    XuJiaEditor:XuJiaEditorReducer,
    XuJiaCheck:XuJiaCheckReducer,
    QingJiaDelList:QingJiaDelListReducer,
    QingJiaDelEditor:QingJiaDelEditorReducer,
    QingJiaDelCheck:QingJiaDelCheckReducer,
    XiaoJiaList:XiaoJiaListReducer,
    XiaoJiaEditor:XiaoJiaEditorReducer,
    XiaoJiaCheck:XiaoJiaCheckReducer,
    JiaBanMealsList:JiaBanMealsListReducer,
    JiaBanMealsEditor:JiaBanMealsEditorReducer,
    JiaBanMealsCheck:JiaBanMealsCheckReducer,
    QingJiaFileList:QingJiaFileListReducer,
    QingjiaFileEditor:QingjiaFileEditorReducer,
    QingJiaFileCheck:QingJiaFileCheckReducer,
    ZufangDaka:ZufangDakaReducer,
};

export default reducer;
