import { postRequest } from '../../../utils/utils';
import {Toast} from 'antd-mobile';
// ------------------------------------
// Constants
// ------------------------------------
export const QINGJIAFILE_EDITOR = 'QINGJIAFILE_EDITOR'

// ------------------------------------
// Actions
// ------------------------------------

export const getApper = () => {
  return async (dispatch, getState) => {
    postRequest('/qingjia/agree').then((value) => {
      //console.log(value)
      if(value.suc === 0) {
          Toast.info(value.msg, 1);
        if(value.msg === '请登陆！') {
          localStorage.removeItem('daka_token')
          alert('账号已在别处登录,请重新登录')
          this.props.history.push('/')
        }
      } else {
        dispatch({ type:'QINGJIAFILE_EDITOR', payload:{ apper: value.data } })
      }
      
    })
  }
}


export const postQingjiafileEditor = (body, history) => {
  return async (dispatch, getState) => {
   return postRequest(`/qingjiabucailiao/apply`, body).then((value) => {
      //console.log(value);
        if(value){
            if(value.suc === 0) {
                Toast.info(value.msg, 1);
                if(value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            } else {
                Toast.success(value.msg, 1,()=>{history.push('/qingjia/list');});
            }
            return value
        }
     else {
            return null
            Toast.fail('上传材料接口错误，请联系信息开发部', 3);
        }
    })
  }
}
