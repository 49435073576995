import { QINGJIADEL_LIST } from './actions'

// -----------------------------------------
// Reducer 处理方法，更新 state 数据，重新渲染 UI
// -----------------------------------------

// ------------------------------------
// 定义Reducer
// ------------------------------------
const initialState = {
    listdata:[],//申请列表
    hasdata:false,//申请列表是否有数据
    checklistdata:[],
    hascheckdata:true,
    count:0,
    count2:0
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
      case QINGJIADEL_LIST:
        return {
          ...state,
          ...action.payload
        };
      default:
        return state;
    }
  };

export default reducer;
