import React from 'react'
import PropTypes from 'prop-types'
import {Flex, WingBlank, WhiteSpace, NavBar, List, Toast, Button, Modal} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import BottomTab from '../components/bottomTab'
import {postRequest, getUserInfo, CheckLogin} from '../../utils/utils'

import daka from '../../images/index/daka.png'
import buka from '../../images/index/buka.png'
import qingjia from '../../images/index/qingjia.png'
import jiaban from '../../images/index/jiaban.png'
import waichu from '../../images/index/waichu.png'
import chucai from '../../images/index/chucai.png'
import tongji from '../../images/index/jiabanyc.png'
import chuqing from '../../images/index/chuqing.png'
import ico from '../../images/index/ico.png'

import jiabanCheck from '../../images/index/jiabanCheck2.png'
import qingjiaCheck from '../../images/index/qingjiaCheck2.png'
import waichuCheck from '../../images/index/waichuCheck2.png'
import bukaCheck from '../../images/index/bukaCheck2.png'
import chucaiCheck from '../../images/index/jiabanBKCheck2.png'
import check from '../../images/index/jiabanYCCheck2.png'
import waichuBackCheck from '../../images/index/waichuBackCheck.png'
import xujiaCheck from '../../images/index/xujiaoCheck.png'
import chexiaoCheck from '../../images/index/chexiaocheck.png'
import xiaojiaCheck from '../../images/index/xiaojiacheck.png'
import diancanCheck from '../../images/index/diancanCheck.png'
import qingjiafileCheck from '../../images/index/qingjiafileCheck.png'
import bgimg from '../../images/gonggao.png';

import './index.css'


class HomeIndex extends React.Component {
    static propTypes = {}

    state = {
        selectedTab: 'homeindex',
        height: document.documentElement.clientHeight-300,
        bukaCount: 0,
        waichuCount: 0,
        jiabanCount: 0,
        qingjiaCount: 0,
        jiabanBKCount: 0,
        jiabanYCCount: 0,
        waichuback: 0,
        xujia: 0,
        qingjiadel: 0,
        xiaojia: 0,
        jiabanmeals: 0,
        qingjiafile: 0,
        show:false
    };

    componentDidMount() {
        this.forceUpdate();
        this.getXiaoJiaNum();
        this.getBukaCount();
        this.getWaiChuCount();
        this.getJiaBanCount();
        this.getQingJiaCount();
        this.getJiaBanBKCount();
        this.getJiaBanYCCount();
        this.getWaiChuBackCount();
        this.getXuJiaCount();
        this.getQingJiaDelCount();
        this.getXiaoJiaCount();
        this.getJiaBanMealsCount();
        this.getQingJiaFileCount();
    }

    getXiaoJiaNum = async () => {
        postRequest(`/Login/xiaojia`).then((value) => {
            console.log(value);
            if (value.status == 1)
                Toast.info('你有销假未处理，请到人力部销假，不办理销假人员将按双倍事假处理', 4)
        })
    }
//获取补卡待审核数量
    getBukaCount = async () => {
        postRequest(`/bk/shenhe`).then((value) => {
            //console.log(value);
            if (value.data != '')
                this.setState({bukaCount: value.data.num})
        })
    }
//获取外出待审核数量
    getWaiChuCount = async () => {
        postRequest(`/out/checklist`).then((value) => {
            if (value.data != '')
                this.setState({waichuCount: value.data.num})
        })
    }
    //获取加班待审核数量
    getJiaBanCount = async () => {
        postRequest(`/jiaban/check_list`).then((value) => {
            if (value.data != '')
                this.setState({jiabanCount: value.data.num})
        })
    }
    //获取请假待审核数量
    getQingJiaCount = async () => {
        postRequest(`/qingjia/checklist`).then((value) => {
            if (value.data != '')
                this.setState({qingjiaCount: value.data.num})
        })
    }
    //获取加班补卡待审核数量
    getJiaBanBKCount = async () => {
        postRequest(`/jiabanbk/checklist`).then((value) => {
            if (value.status != 0)
                if (value.data != '')
                    this.setState({jiabanBKCount: value.data.num})
        })
    }
    //获取加班延长待审核数量
    getJiaBanYCCount = async () => {
        postRequest(`/yanchang/checklist`).then((value) => {
            //console.log(value);
            if (value.suc === 0) {
                localStorage.removeItem('daka_token');
                Toast.info('账号已在别处登录,请重新登录', 2)
                this.props.history.push('/')
            }
            else {
                if (value.status != 0 && value.data != '')
                    this.setState({jiabanYCCount: value.data.num})
            }
        })
    }
    //获取外出不返回公司审核数量
    getWaiChuBackCount = async () => {
        postRequest(`/outapply/checklist`).then((value) => {
            //console.log(value);
            if (value.suc === 0) {
                localStorage.removeItem('daka_token');
                Toast.info('账号已在别处登录,请重新登录', 2)
                this.props.history.push('/')
            }
            else {
                if (value.status != 0 && value.data != '')
                    this.setState({waichuback: value.data.num})
            }
        })
    }

    //获取续假审核数量
    getXuJiaCount = async () => {
        postRequest(`/xujia/checklist`).then((value) => {
            //console.log(value);
            if (value.suc === 0) {
                localStorage.removeItem('daka_token');
                Toast.info('账号已在别处登录,请重新登录', 2)
                this.props.history.push('/')
            }
            else {
                if (value.status != 0 && value.data != '')
                    this.setState({xujia: value.data.num})
            }
        })
    }

    //获取撤销请假待审核数量
    getQingJiaDelCount = async () => {
        postRequest(`/qingjiachexiao/checklist`).then((value) => {
            if (value.data != '')
                this.setState({qingjiadel: value.data.num})
        })
    }

    //获取提前销假待审核数量
    getXiaoJiaCount = async () => {
        postRequest(`/qingjiatiqian/checklist`).then((value) => {
            if (value.data != '')
                this.setState({xiaojia: value.data.num})
        })
    }
    //获取加班就餐待审核数量
    getJiaBanMealsCount = async () => {
        postRequest(`/jiucan/checklist`).then((value) => {
            if (value.data != '')
                this.setState({jiabanmeals: value.data.num})
        })
    }
    //获取加班就餐待审核数量
    getQingJiaFileCount = async () => {
        postRequest(`/qingjiabucailiao/checklist`).then((value) => {
            //console.log(value);
            if (value.data != '')
                this.setState({qingjiafile: value.data.num})
        })
    }

    render() {
        return (
            <div className='index-content'>
                {/*<NavBar*/}
                {/*mode="dark"*/}
                {/*>九鼎昊天环保</NavBar>*/}
                <div style={{margin: 0}} className='ico_content2'>
                    <div className='ico_title'>应用中心</div>
                    <div className='flex_content'>
                        <Link to='/home'>
                            <div className="icolist">
                                <div>
                                    <img src={daka}/>
                                </div>
                                <p>打卡</p>
                            </div>
                        </Link>
                        <Link to='/buka/list'>
                            <div className="icolist">
                                <div>
                                    <img src={buka}/>
                                </div>
                                <p>补卡</p>
                            </div>
                        </Link>
                        <Link to='/waichu/list'>
                            <div className="icolist">
                                <div>
                                    <img src={waichu}/>
                                </div>
                                <p>外出</p>
                            </div>
                        </Link>
                        <Link to='/qingjia/list'>
                            <div className="icolist">
                                <div>
                                    <img src={qingjia}/>
                                </div>
                                <p>请假</p>
                            </div>
                        </Link>
                    </div>
                    <div className='flex_content'>
                        <Link to='/jiaban/list'>
                            <div className="icolist">
                                <div>
                                    <img src={jiaban}/>
                                </div>
                                <p>加班</p>
                            </div>
                        </Link>
                        <Link to='/jiabanMeals/list'>
                            <div className="icolist">
                                <div>
                                    <img src={chuqing}/>
                                </div>
                                <p>加班就餐</p>
                            </div>
                        </Link>
                        <Link to='/JiabBanbuka/list'>
                            <div onClick={() => {
                                // Toast.info('该功能还在开发中，敬请期待！', 1);
                            }} className="icolist">
                                <div>
                                    <img src={ico}/>
                                </div>
                                <p>加班补卡</p>
                            </div>
                        </Link>
                        <Link to='/JiabBanYanChang/list'>
                            <div className="icolist">
                                <div>
                                    <img src={tongji}/>
                                </div>
                                <p>加班延长</p>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className='ico_content2 new_content'>
                    <div className='ico_title'>待办事项</div>
                    <div className='flex_content content2'>
                        <div onClick={() => {
                            this.props.history.push({pathname: '/qingjia/list', state: {selectindex: 1}})
                        }} className="icolist2">
                            <img src={qingjiaCheck}/>
                            <p>请假审批</p>
                            {this.state.qingjiaCount == 0 ? '' :
                                <span>{this.state.qingjiaCount}</span>}
                        </div>
                        <div onClick={() => {
                            this.props.history.push({pathname: '/jiaban/list', state: {selectindex: 1}})
                        }} className="icolist2">
                            <img src={jiabanCheck}/>
                            <p>加班审批</p>
                            {this.state.jiabanCount == 0 ? '' :
                                <span>{this.state.jiabanCount}</span>}
                        </div>
                        <div onClick={() => {
                            this.props.history.push({pathname: '/waichu/list', state: {selectindex: 1}})
                        }} className="icolist2">
                            <img src={waichuCheck}/>
                            <p>外出审批</p>
                            {this.state.waichuCount == 0 ? '' :
                                <span>{this.state.waichuCount}</span>}
                        </div>
                    </div>
                    <div className='flex_content content2'>
                        <div onClick={() => {
                            this.props.history.push({pathname: '/buka/list', state: {selectindex: 1}})
                        }} className="icolist2">
                            <img src={bukaCheck}/>
                            <p>补卡审批</p>
                            {this.state.bukaCount == 0 ? '' :
                                <span>{this.state.bukaCount}</span>}

                        </div>
                        <div onClick={() => {
                            this.props.history.push({pathname: '/JiabBanbuka/list', state: {selectindex: 1}})
                        }} className="icolist2">
                            <img src={chucaiCheck}/>
                            <p>加班补卡</p>
                            {this.state.jiabanBKCount == 0 ? '' :
                                <span>{this.state.jiabanBKCount}</span>}
                        </div>
                        <div onClick={() => {
                            this.props.history.push({pathname: '/JiabBanYanChang/list', state: {selectindex: 1}})
                        }} className="icolist2">
                            <img src={check}/>
                            <p>加班延长</p>
                            {this.state.jiabanYCCount == 0 ? '' :
                                <span>{this.state.jiabanYCCount}</span>}
                        </div>
                    </div>
                    <div className='flex_content content2'>
                        <div onClick={() => {
                            this.props.history.push({pathname: '/waichuback/list', state: {selectindex: 1}})
                        }} className="icolist2">
                            <img src={waichuBackCheck}/>
                            <p>外出不归</p>
                            {this.state.waichuback == 0 ? '' :
                                <span>{this.state.waichuback}</span>}

                        </div>
                        <div onClick={() => {
                            this.props.history.push({pathname: '/xujia/list', state: {selectindex: 1}})
                        }} className="icolist2">
                            <img src={xujiaCheck}/>
                            <p>续假审批</p>
                            {this.state.xujia == 0 ? '' :
                                <span>{this.state.xujia}</span>}

                        </div>
                        <div onClick={() => {
                            this.props.history.push({pathname: '/qingjiadel/list', state: {selectindex: 1}})
                        }} className="icolist2">
                            <img src={chexiaoCheck}/>
                            <p>撤销请假</p>
                            {this.state.qingjiadel == 0 ? '' :
                                <span>{this.state.qingjiadel}</span>}

                        </div>

                    </div>
                    <div className='flex_content content2'>
                        <div onClick={() => {
                            this.props.history.push({pathname: '/xiaojia/list', state: {selectindex: 1}})
                        }} className="icolist2">
                            <img src={xiaojiaCheck}/>
                            <p>提前销假</p>
                            {this.state.xiaojia == 0 ? '' :
                                <span>{this.state.xiaojia}</span>}

                        </div>
                        <div onClick={() => {
                            this.props.history.push({pathname: '/qingjiafile/list', state: {selectindex: 1}})
                        }} className="icolist2">
                            <img src={qingjiafileCheck}/>
                            <p>请假材料</p>
                            {this.state.qingjiafile == 0 ? '' :
                                <span>{this.state.qingjiafile}</span>}

                        </div>
                        <div onClick={() => {
                            this.props.history.push({pathname: '/jiabanMeals/list', state: {selectindex: 1}})
                        }} className="icolist2">
                            <img src={diancanCheck}/>
                            <p>就餐审批</p>
                            {this.state.jiabanmeals == 0 ? '' :
                                <span>{this.state.jiabanmeals}</span>}

                        </div>
                        {/*<div className="icolist2 nocontent"></div>*/}
                    </div>
                </div>

                {/*<Modal className='gonggao_modal'*/}
                    {/*visible={this.state.show}*/}
                    {/*transparent*/}
                    {/*maskClosable={true}*/}
                    {/*title="升级公告"*/}
                    {/*footer={[{ text: '关闭', onPress: () => {*/}
                        {/*this.setState({show:false})*/}
                    {/*} }]}*/}
                    {/*afterClose={() => {this.setState({show:false}) }}*/}
                {/*>*/}
                    {/*<div className='gb_box' style={{*/}
                        {/*height: this.state.height,*/}
                        {/*overflow: 'scroll'}}>*/}
                      {/*<div>*/}
                          {/*<strong>市内外出</strong>*/}
                          {/*<p>1. 新增：  外出下午16:00以后不能返回公司的，需要在外出列表中进行“申请不回公司”操作，审批通过后，方可打卡，否则16:00以后不可打卡。</p>*/}
                      {/*</div>*/}
                        {/*<div>*/}
                            {/*<strong>请假</strong>*/}
                            {/*<p>1. 新增：  请假大于三天未在人力部办理销假的，将按双倍事假处理。</p>*/}
                            {/*<p>2. 修改：  请假必须提前申请，不能事后补申请（即：申请时间必须早于开始时间，请假必须提前申请，不能后期补申请）。</p>*/}
                            {/*<p>3. 新增：  请假申请生效后，需取消该申请的，可在请假列表中撤销该请假条。审核通过后，该请假无效。</p>*/}
                            {/*<p>4. 新增：  员工提前结束请假的，可在请假列表中进行提前销假申请，审核通过后，请假结束时间自动更新为销假时间，提前销假需在请假结束前发申请，否则无法提前销假。</p>*/}
                            {/*<p>5. 新增：  员工在请假期间需要延长假期的，可在申请列表中进行续假操作。审核通过后，请假结束时间自动更新为续假时间。续假需在请假结束前发申请，否则无法续假。</p>*/}
                            {/*<p>6. 新增：  除事假外，其他请假类型均须有相关证明资料，在提交请假申请时，还未有相关材料，可在后期请假列表中“上传证明材料”进行补充，补充材料需在请假结束前，否则自动转为事假。如婚假需有结婚证复印件；病假需有医院病休假条；丧假需要有直系亲属过世的相关证明，工伤假需有公司安全办证明，产假等其他请假也需有相应证明资料。纸质证明文件次月5号前交人力部。</p>*/}
                            {/*<p>7. 新增：  请假时长不足一个小时按一个小时算，超过一个小时，不足一个半小时算一个半小时，超过一个半小时，不足两个小时按两个小时计算（以此类推）。</p>*/}
                            {/*<p>8. 新增：  请假申请审核通过后，员工必须在打卡界面的“请假打卡”处打卡；<br />*/}
                                {/*非全天请假的：离开公司时打卡 一次，如回公司的，要在回公司第一时间打卡，如不回则不打卡；未到公司直接请假的，在结束请假回公司第一时间打卡；<br />*/}
                                {/*全天请假的不用在请假打卡处打卡；<br />*/}
                                {/*请假结束后回公司未及时在请假打卡处打卡者，造成请假时长延长由个人承担（即请假结束回到公司时忘记在请假打卡处打卡，系统上请假时长将会延长到实际打卡时间，超过原申请的请假时长）。请假进出公司都未打卡者将视为延长请假时间或旷工处理。<br />*/}
                            {/*</p>*/}
                        {/*</div>*/}
                        {/*<div>*/}
                            {/*<strong>加班</strong>*/}
                            {/*<p>1. 新增：  加班申请统一由部门主管提交申请，个人不能提交。加班申请审批通过后，开始加班时，加班人员在加班申请列表点击“开始”开始计算加班时长，未点击，则可在列表中点击“补卡”按钮进行补卡。</p>*/}
                            {/*<p>2. 新增：  加班结束时，加班人员需及时登录系统在加班申请列表点击“结束”计算实际加班时长。如果未点击，则以加班申请结束时间计算加班时长。</p>*/}
                            {/*<p>3. 新增：  需要延长加班时间的，可在加班申请列表中进行“延长加班”操作，审核通过后，加班时间结束时间自动更新为延长时间。</p>*/}
                            {/*<p>4. 新增：  员工加班后未操作开始加班和结束加班按钮的，可在加班申请列表中进行“补卡”操作，审批通过后，此处加班打卡成功。</p>*/}
                            {/*<p>5. 新增：  加班就餐。加班时，需在系统登记加班就餐人员，并上传就餐凭证。</p>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</Modal>*/}
                <Modal className='gonggao_modal'
                       visible={this.state.show}
                       transparent
                       maskClosable={true}
                       title="重要公告"
                       footer={[{ text: '关闭', onPress: () => {
                           this.setState({show:false})
                       } }]}
                       afterClose={() => {this.setState({show:false}) }}
                >
                    <div className='gb_box' style={{
                        height: 80,
                        overflow: 'scroll'}}>
                        <div>
                            <p>每月只能免责补卡2次，超过2次的将每次处理50元</p>
                        </div>
                    </div>
                </Modal>
                {/*<div className='bottom_height'>*/}
                {/*<div className='banquan'>版权所有©2019 襄阳九鼎昊天环保设备有限公司</div>*/}
                {/*</div>*/}
                <div style={{height: '60px'}}></div>
                <BottomTab selectedTab='homeindex'/>
            </div>
        )
    }
}

export default HomeIndex
