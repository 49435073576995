import React from 'react'
import PropTypes from 'prop-types'
import {Flex, WingBlank, WhiteSpace, NavBar, List, Toast, Button, ImagePicker, Modal} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import BottomTab from '../components/bottomTab'
import {postRequest, getUserInfo} from '../../utils/utils'

import Person from '../../images/person.png'
import dakaRecord from '../../images/myinfo/dakaRecord.png'
import buka from '../../images/myinfo/buka.png'
import jiaban from '../../images/myinfo/jiaban.png'
import qingjia from '../../images/myinfo/qingjia.png'
import waichu from '../../images/myinfo/waichu.png'
import nodaka from '../../images/myinfo/nodaka.png'
import help from '../../images/help.png'
import tuichu from '../../images/myinfo/tuichu.png'
import password from '../../images/myinfo/password.png'
import daka from '../../images/myinfo/daka.png'
import daka2 from '../../images/myinfo/daka2.png'

import './index.css'

const Item = List.Item;
const Brief = Item.Brief;

class MyInfo extends React.Component {
    static propTypes = {}

    state = {
        selectedTab: 'myinfo',
        height: document.documentElement.clientHeight - 300,
        chuchai: 0,
        waichu: 0,
        jiaban: 0,
        qingjia: 0,
        files: [],
        candaka: false,
        show: false,
        daka_num: 0,
        start: '',
        end: ''
    };

    componentDidMount() {
        this.getonChuChai();
        this.getWaiChu();
        this.getjiaban();
        this.getqingjia();
        this.candaka();
    }

    //统计出差次数
    getonChuChai = async () => {
        postRequest(`/amount/chuchai`, '').then((result) => {
            this.setState({chuchai: result.count});
        })
    }
    //统计外出次数
    getWaiChu = async () => {
        postRequest(`/amount/waiqing`, '').then((result) => {
            this.setState({waichu: result.count});
        })
    }
    //统计加班时长
    getjiaban = async () => {
        postRequest(`/amount/jiaban`, '').then((result) => {
            this.setState({jiaban: result.count});
        })
    }
    //统计请假时长
    getqingjia = async () => {
        postRequest(`/amount/qingia`, '').then((result) => {
            this.setState({qingjia: result.count});
        })
    }
    //是否可以打卡
    candaka = async () => {
        postRequest(`/paizhao/check`, '').then((result) => {
            console.log(result)
            if (result.status === 1) {
                this.setState({candaka: true, daka_num: result.num, start: result.start, end: result.wx_time});
            }
            else this.setState({candaka: false, daka_num: result.num})
        })
    }
    //开门打卡
    handledaka = (files, type, index) => {
        let body = ''
        Toast.loading('打卡中..., 请稍后', 60,
            () => {
                Toast.info('打卡失败，稍后再试', 1);
            }
        );
        if (files[0] != 'undefined' && files[0] != null) {
            body += `imgurl=${files[0].url}`;
        }
        postRequest('/Paizhao/daka', body).then((result) => {
            console.log(result)
            if (result) {
                if (result.suc === 1) {
                    Toast.success('打卡成功', 1);
                }
                else {
                    Toast.fail(result.msg || '打卡失败', 3);
                }
                this.candaka();
            } else {
                if (result.msg === '请登陆') {
                    localStorage.removeItem('daka_token')
                    Toast.info('账号已在别处登录,请重新登录', 1);
                    this.props.history.push('/')
                } else {
                    Toast.hide();
                    Toast.info(result.msg || '不在公司范围内', 3);
                }
            }
        })
    }

    render() {
        const {files, candaka, daka_num, start, end} = this.state
        const daka_user = JSON.parse(localStorage.getItem('daka_user'));
        //console.log(daka_user);

        return (
            <div className='index-content'>
                <div className='my_top'>
                    <div className='photo'>
                        <div className='left'>
                            <img className='touxiang'
                                 src={daka_user.u_avatar == null ? Person : `http://jd.stycie.com/Upload/${daka_user.u_avatar}`}/>
                        </div>
                        <div className='right'>
                            <div className='top1'>
                                <div>
                                    <h3>{daka_user.u_fullname}</h3>
                                    <h5>{daka_user.depart}</h5>
                                </div>
                                {/*<div onClick={()=>{*/}
                                {/*this.props.history.push('/help')*/}
                                {/*}} className='help'>*/}
                                {/*<img src={help}/>*/}
                                {/*</div>*/}
                                <a href='https://gps.stycie.com/help.docx' className='help'>
                                    <img src={help}/>
                                </a>
                            </div>
                            <p>汇总本月请假,加班,出差,外出情况</p>
                        </div>

                    </div>
                    <div className='report'>
                        <div>
                            <p className='num'>{this.state.qingjia == 0 ? '0' : Number(this.state.qingjia).toFixed(2)}</p>
                            <p className='desc'>请假(小时)</p>
                        </div>
                        <div>
                            <p className='num'>{this.state.jiaban == 0 ? '0' : Number(this.state.jiaban).toFixed(2)}</p>
                            <p className='desc'>加班(小时)</p>
                        </div>
                        <div>
                            <p className='num'>{this.state.chuchai}</p>
                            <p className='desc'>出差(次)</p>
                        </div>
                        <div>
                            <p className='num'>{this.state.waichu}</p>
                            <p className='desc'>市内外出</p>
                        </div>

                    </div>
                </div>
                <List className="my-list">
                    <Item arrow="horizontal" thumb={nodaka} multipleLine
                          onClick={() => {
                              this.props.history.push('/queka')
                          }}>
                        缺卡记录
                    </Item>
                    <Item arrow="horizontal" thumb={dakaRecord} multipleLine
                          onClick={() => this.props.history.push('/record')}>
                        打卡记录
                    </Item>
                    <Item arrow="horizontal" thumb={buka} multipleLine
                          onClick={() => {
                              this.props.history.push('/buka/list')
                          }}>
                        补卡申请
                    </Item>
                    <Item arrow="horizontal" thumb={qingjia} multipleLine
                          onClick={() => {
                              this.props.history.push('/qingjia/list')
                          }}>
                        请假申请
                    </Item>
                    <Item arrow="horizontal" thumb={waichu} multipleLine
                          onClick={() => {
                              this.props.history.push('/waichu/list')
                          }}>
                        市内外出
                    </Item>
                    <Item arrow="horizontal" thumb={jiaban} multipleLine
                          onClick={() => {
                              this.props.history.push('/jiaban/list')
                          }}>
                        加班申请
                    </Item>
                    {/* <Item arrow="horizontal" thumb={password} multipleLine
                          onClick={() => {
                              this.props.history.push({pathname: '/editpassword', state: {type: 1}})
                          }}>
                        修改密码
                    </Item> */}
                    {candaka ?
                        daka_num == 0 ?
                            <Item arrow="horizontal" thumb={daka} multipleLine
                                  onClick={() => {
                                      var myDate = new Date();
                                      var hours = myDate.getHours();
                                      var min = myDate.getMinutes();
                                      //
                                      // //冬令时
                                      // if ((hours == 12 && min >= 50) || (hours > 12)) {
                                      //     if ((hours == 13 && min <= 2) || (hours < 13))
                                      //         document.getElementById('daka').getElementsByTagName('input')[0].click()
                                      //     else Toast.info('打卡时间已过，不能打卡。打卡截止时间：13:02', 3);
                                      // }
                                      // else Toast.info('还未到时间，不能打卡。打卡时间为：12:50-13:02', 3);
                                      //夏令时
                                      // if ((hours == 13 && min >= 20) || (hours>13)) {
                                      //     if ((hours == 13 && min <=32) || (hours < 13))
                                      //         document.getElementById('daka').getElementsByTagName('input')[0].click()
                                      //     else Toast.info('打卡时间已过，不能打卡。打卡截止时间：13:32', 3);
                                      // }
                                      // else Toast.info('还未到时间，不能打卡。打卡时间为：13:20-13:32', 3);

                                      var start_time = start ? start.split(":") : null
                                      let start_hour=0,start_min=0
                                      if (start_time&&start_time.length>1) {
                                          start_hour=Number(start_time[0])
                                          start_min=Number(start_time[1])
                                      }
                                      var end_time = end ? end.split(":") : null
                                      let end_hour=0,end_min=0
                                      if (end_time&&end_time.length>1) {
                                          end_hour=Number(end_time[0])
                                          end_min=Number(end_time[1])
                                      }
                                      if ((hours == start_hour && min >= start_min) || (hours > start_hour)) {
                                          if ((hours == end_hour && min <= end_min) || (hours < end_hour))
                                              document.getElementById('daka').getElementsByTagName('input')[0].click()
                                          else Toast.info(`打卡时间已过，不能打卡。打卡截止时间：${end}`, 3);
                                      }
                                      else Toast.info(`还未到时间，不能打卡。打卡时间为：${start}-${end}`, 3);
                                  }
                                  }>
                                开门打卡
                            </Item> :
                            <Item arrow="horizontal" thumb={daka} multipleLine
                                  onClick={() => {
                                      this.props.history.push('/openRecord')
                                  }}>
                                开门打卡记录
                            </Item> : ''
                    }

                    <div style={{display: 'none', width: '10px'}} id='daka'>
                        <ImagePicker
                            files={files}
                            length="1"
                            onChange={this.handledaka.bind(this)}
                            onImageClick={(index, fs) => {
                                console.log(index, fs)
                            }}
                            selectable={files.length < 1}
                            multiple={false}
                            // accept="image/jpeg,image/png,image/bmp"
                            style={{display: 'none'}}
                            disableDelete/>
                    </div>
                    <Item arrow="horizontal" thumb={daka2} multipleLine
                          onClick={() => {
                              this.props.history.push('/zufangDaka')
                          }}>
                        租房打卡
                    </Item>
                    {/*<Item arrow="horizontal" thumb={tuichu} multipleLine*/}
                    {/*onClick={() => {*/}
                    {/*localStorage.setItem('daka_token', '')*/}
                    {/*this.props.history.push('/login')*/}
                    {/*}}>*/}
                    {/*退出登录*/}
                    {/*</Item>*/}
                </List>
                {candaka &&
                <Modal className='gonggao_modal'
                       visible={this.state.show}
                       transparent
                       maskClosable={true}
                       title="开门打卡规则"
                       footer={[{
                           text: '关闭', onPress: () => {
                               this.setState({show: false})
                           }
                       }]}
                       afterClose={() => {
                           this.setState({show: false})
                       }}
                >
                    <div className='gb_box' style={{
                        height: this.state.height,
                        overflow: 'scroll'
                    }}>
                        <div>
                            <p>1. 每个部门根据主管们的当日工作情况，安排一位主管负责拍照打卡，如果当日没有开门打卡，部门所有主管均在管理群发50元红包。</p>
                            <p>2.
                                打卡照片必须先设置好手机拍照水印，先拍照再打卡上传照片。照片必须有当天中午13:20--13:30期间的水印时间。打卡时间限制为中午13:20--13:32，超时打卡的主管在管理群发50元红包。主管上传没有水印的照片视为非当时拍照，当月超过2次的在管理群发50元红包。</p>
                            <p>3.
                                如果本部门主管当日全部因公因私不在公司，须提前半小时联系部门在职人员代为拍照后传给主管，再由主管打卡。同时须在打卡界面的说明栏中写明因某某事不在公司，非本人拍照。未按本规定打卡的部门主管，当月超过2次的在管理群发50元红包。</p>
                            <p>4. 客服部、发规部的开门登记拍照，由兼管主管负责，可交由部门人员代为拍照传给主管，再由主管打卡上传照片。</p>
                            <p>5.
                                如遇实在不能拍照登记的特殊情况且部门人员也不能代拍照的，各部门主管需提前在管理群说明原因。原则上此情况每月不得多于2次。没有提前说明也不打卡的，不管是何缘由均发50元红包。</p>
                        </div>
                    </div>
                </Modal>
                }


                <div style={{height: '70px'}}></div>
                <BottomTab selectedTab='myinfo'/>
            </div>
        )
    }
}

export default MyInfo
