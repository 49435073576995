import React from 'react'
import PropTypes from 'prop-types'
import {
    List,
    TextareaItem,
    Button,
    InputItem,
    NavBar,
    Checkbox,
    Icon,
    WhiteSpace,
    Picker,
    Toast,
    DatePicker,
    WingBlank
} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import {createForm} from 'rc-form'
import {postRequest} from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import CustomerSearch from '../../components/customer_search'
import AmapSearch from '../../components/amapSearch'

const Item = List.Item;


class WaiChuBackEditor extends React.Component {
    static propTypes = {
        form: PropTypes.object.isRequired,
        saveWaiChuBack: PropTypes.func.isRequired,
    }

    state = {
        content: '',
        waichu_id: '',
        waichuback_id: '',
    };

    // componentWillMount() {
    //     const waichuback_id = localStorage.getItem('waichuback_id');
    //     this.setState({waichuback_id: waichuback_id});
    //     if (waichuback_id !== '')//判断是编辑还是新增
    //     {
    //         this.getDetail(waichu_id);
    //     }
    // }

    componentDidMount() {
    }

    //获取修改数据
    getDetail = async (waichu_id) => {
        postRequest(`/outapply/edit`, `id=${waichu_id}`).then((result) => {
            //console.log(result)
            if (result && result.status === 1) {
                // console.log(result.data.starttime);
                this.setState({
                    content: result.data.content,
                });

            } else {
                Toast.info(result.msg || '获取失败', 1);
                if (result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }


    onSubmit = () => {
        const {validateFields} = this.props.form
        const {content} = this.state
        const {files, history, saveWaiChuBack} = this.props
        const waichu_id = localStorage.getItem('waichu_id');
        validateFields((err, values) => {
            let body = '';
            if (content) {
                body += `content=${content}`
            } else {
                Toast.info('外出不返回公司事由必填', 1);
                return
            }
            if (!err) {
                if (this.state.waichuback_id != '') {//修改
                    body += `&id=${this.state.waichuback_id}`
                }
                else {  //新增
                    body += `&wc_id=${waichu_id}`
                }
                //console.log(body);
                saveWaiChuBack(body, history)
            }
        });
    }

    render() {
        const {getFieldProps} = this.props.form;
        const {ctype, detail} = this.props;

        return (
            <div className='index-content' id='wipeout_editor'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left"/>}
                    onLeftClick={() => this.props.history.goBack()}
                >外出不归申请</NavBar>
                <List renderHeader='' className="qj-list my_list">

                    <TextareaItem
                        {...getFieldProps('content', {
                            rules: [{require: true}],
                        })}
                        value={this.state.content}
                        onChange={value => this.setState({content: value})}
                        placeholder="请填写外出不回公司事由"
                        data-seed="logId"
                        rows={4}
                        ref={el => this.customFocusInst = el}
                    />
                </List>
                <WhiteSpace/>
                <WhiteSpace/>
                <WhiteSpace/>
                <WingBlank size="md">
                    <Button type='primary' onClick={() => this.onSubmit()}>提交</Button>
                </WingBlank>

            </div>
        )
    }
}

export default createForm()(WaiChuBackEditor)