import { connect } from 'react-redux'
import { getApper, saveBuka } from './actions'
import Setting from './main'


const mapDispatchToProps = {
  getApper,
    saveBuka
}
const mapStateToProps = (state) => {
  return {
    ...state.BukaEditor
  }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
