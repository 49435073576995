import { connect } from 'react-redux'
import { checkJBBuka,upload } from './actions'
import Setting from './main'


const mapDispatchToProps = {
    checkJBBuka,upload
}
const mapStateToProps = (state) => {
  return {
    ...state.JBBukaCheck
  }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
