import React from 'react'
import PropTypes from 'prop-types'
import { NavBar, Icon, WhiteSpace, WingBlank, List, Checkbox, Button, SearchBar } from 'antd-mobile'
import { Link } from 'react-router-dom'
import 'antd-mobile/dist/antd-mobile.css'
import './index.css'
import { postRequest, getUserInfo } from '../../../utils/utils'

const CheckboxItem = Checkbox.CheckboxItem;
const Item = List.Item

class TaskCustomer extends React.Component{

  static propTypes = {
    customers:PropTypes.array.isRequired,
    getCustomers:PropTypes.func.isRequired,
  }

  state = {
    workers:[], //选中用户ID
    data:[],
    update:true,
      inputValue:''
  }


    componentWillReceiveProps() {
        const { customers } = this.props
        this.setState({data:customers})
    // if(this.state.data.length === 0 && this.state.update ) {
    //   this.setState({data:this.props.customers, update:false})
    // }
     //console.log(customers)
  }


  
  onChange = (val) => {
    const worker = this.props.selectvalue;
    if(worker.length === 0) {
      worker.push(val)
    } else {
      let pos = worker.indexOf(val);
      if (pos < 0){
        worker.push(val)
      } else {
        worker.splice(pos, 1)
      } 
    }
    this.setState({workers:worker})
  }

  onSubmit = () => {
    //console.log(this.state.workers)
    this.props.getCustomers(this.state.workers)
      this.setState({inputValue:''})
  }

  onSearch = (val) => {
    const { customers } = this.props
    const arr = customers.filter((jj) => {
      if(jj.u_fullname) {
        return jj.u_fullname.toLocaleLowerCase().indexOf(val) > -1
      }
    });
    //console.log(arr)
    this.setState({
      inputValue: val,
      data:arr
    });
  }
  
  render() {
    const { data, workers } = this.state;
    //const{customers,selectvalue} =this.props;
    return (
      <div style={{backgroundColor:'#f5f5f7', Height:document.documentElement.clientHeight, zIndex:9999999 }}>
        <NavBar
        mode="dark"
        >员工列表</NavBar>
        <SearchBar
            value={this.state.inputValue}
            placeholder="搜索"
            onChange={this.onSearch}
          />
        <List style={{marginBottom:45, overflow:''}}>
          {data.map(i => (
            <CheckboxItem key={i.u_id || i.cid || i.value} onChange={() => this.onChange(i.u_id || i.cid || i.value)}
                          checked={(workers.indexOf(i.u_id)>-1)||(this.props.selectvalue.indexOf(i.u_id)>-1)}>
              {i.u_fullname || i.name || i.label  }
            </CheckboxItem>
          ))}
        </List>
        <div style={{position:'fixed', left:0, bottom:0, width:'100%', zIndex:100 }}>
          <Button type='primary'  onClick={() => this.onSubmit()}>确 定</Button>
          </div>
      </div>
    )
  }
}

export default TaskCustomer