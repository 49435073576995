import { JIABAN_LIST } from './actions'

// -----------------------------------------
// Reducer 处理方法，更新 state 数据，重新渲染 UI
// -----------------------------------------

// ------------------------------------
// 定义Reducer
// ------------------------------------
const initialState = { 
    data:{},
    ctype:{},
    approvals:[],
    data:[],
    jblistdata:[],
    nojb:false,
    jbcheckdata:[],
    nocheck:false,
    count:0,
    count2:0
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
      case JIABAN_LIST:
        return {
          ...state,
          ...action.payload
        };
      default:
        return state;
    }
  };

export default reducer;
