import { QXIAOJIA_EDITOR } from './actions'

// -----------------------------------------
// Reducer 处理方法，更新 state 数据，重新渲染 UI
// -----------------------------------------

// ------------------------------------
// 定义Reducer
// ------------------------------------
const initialState = { 
  customers : [],
  apper:[]
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
      case QXIAOJIA_EDITOR:
        return {
          ...state,
          ...action.payload
        };
      default:
        return state;
    }
  };

export default reducer;
