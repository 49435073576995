import { postRequest, getUserInfo } from '../../../utils/utils'
// ------------------------------------
// Constants
// ------------------------------------
export const SETTING_LIST = 'SETTING_LIST'

// ------------------------------------
// Actions
// ------------------------------------

export const getSetting = (history) => {
  return async (dispatch, getState) => {
    let data = {}
    postRequest('/User/getUinfo').then((value) => {
      if(value.suc === 0) {
        alert(value.msg)
        history.push('/')
      } else {
        data = value.data
        dispatch({ type: SETTING_LIST, payload:{ data } })
      }
    })
  }
}


