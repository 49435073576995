import { connect } from 'react-redux'
import { getJiaBanBuka,getJiaBanBukaChecklist,deleteJiaBanBuka} from './actions'
import Setting from './main'


const mapDispatchToProps = {
    getJiaBanBuka,
    getJiaBanBukaChecklist,
    deleteJiaBanBuka
}
const mapStateToProps = (state) => {
  return {
    ...state.JiaBanBKList
  }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
