import React from 'react'
import PropTypes from 'prop-types'
import { List, TextareaItem, Button, InputItem, NavBar, Checkbox, Icon, WhiteSpace, Picker, Toast, DatePicker,WingBlank } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import { Link } from 'react-router-dom'
import { createForm } from 'rc-form'
import { postRequest } from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import CustomerSearch from '../../components/customer_search'
import Customer from '../../components/customer'
import addtime from '../../../images/addtime.png'
import deltime from '../../../images/deletetime.png'
const Item = List.Item;

let minDate ='';
let maxDate = '';
let i=0;
class JiabanEditor extends React.Component {
  static propTypes = {
    form:PropTypes.object.isRequired,
    getApper:PropTypes.func.isRequired,
      getuser:PropTypes.func.isRequired,
    apper:PropTypes.array.isRequired,
      users:PropTypes.array.isRequired,
    postJiabanEditor:PropTypes.func.isRequired,
  }

  state = {
    files:null,
    data:[],
    start_time:'',
    end_time:'',
    apper:[],
      check_username:'',
      users:[],
      userName:[],
      userid:[],
      visible:false,
      jiaban_id:'',
      shiyou:'',
      times:[{
        id:0,
          starttime:'',
          endtime:'',
          SminDate:'',
          EminDate:'',
          EmaxDate:''
      }],//多组开始结束时间数组
  };
    componentWillMount(){
        const jiaban_id = localStorage.getItem('jiaban_id');
        this.setState({jiaban_id:jiaban_id});
        if(jiaban_id!=='')//判断是编辑还是新增
        {
            this.getDetail(jiaban_id);
        }
    }
  componentDidMount() {
    this.props.getApper();
      this.props.getuser();
  }

    getDetail = async(jiaban_id) => {
        postRequest(`/jiaban/edit`, `id=${jiaban_id}`).then((result) => {
            console.log(result)
            if(result && result.suc === 1) {
              const yuangong=result.data.yuangong;
              let username=[];
              let userid=[];
              if(yuangong)
              {
                for(var i=0;i<yuangong.length; i++)
                {
                    userid.push(yuangong[i].u_id);
                    username.push(yuangong[i].u_fullname)
                }
              }
              const shijian=result.data.shijian.split('|');
                const lists=[];
              for(var i=0; i<shijian.length;i++){
                  const startime=shijian[i].split(',')[0];
                  const endtime=new Date(shijian[i].split(',')[1]);
                  const EminDate=new Date(startime);
                  const EmaxDate=new Date(startime.split(' ')[0]+' 23:59');
                  lists.push({"id":i,"starttime": new Date(startime),"endtime":endtime,"EminDate":EminDate,"EmaxDate":EmaxDate});
              }

                this.setState({times:lists});
                //this.setState({end_time:new Date(result.data.endtime)});
                this.setState({apper:[result.data.agree_id]});
                this.setState({check_username:result.data.a_name});
                this.setState({userid:userid});
                this.setState({userName:username});
                this.setState({shiyou:result.data.shiyou});
            } else {
                Toast.fail(result.msg || '获取失败', 1);
                if(result.msg.indexOf('请登陆')!=-1) {
                    localStorage.removeItem('daka_token')
                    this.props.history.push('/')
                }
            }
        })
    }

  onSegChange = (e) => {
    const index = e.nativeEvent.selectedSegmentIndex;
    this.setState({
      multiple: index === 1,
    });
  }

  onSubmit = () => {
    const { validateFields } = this.props.form
    const { start_time, end_time,apper,userid,shiyou,times } = this.state
    const { files, history, postJiabanEditor } = this.props

    validateFields((err, values) => {
      let body =''
      if(times[0].starttime==''&&times[0].endtime=='') {
          Toast.info('加班时间必填', 1);
          return
      } else {
          var shijian='';
          for(var i=0;i<times.length;i++){
             shijian+=`${moment(times[i].starttime).format('YYYY-MM-DD HH:mm')},${moment(times[i].endtime).format('YYYY-MM-DD HH:mm')}|`
          }
          body += `shijian=${shijian.substring(0,shijian.length-1)}`
      }


      if(shiyou) {
        body += `&shiyou=${shiyou}`
      } else {
          Toast.info('加班事由为必填项', 1);
        return
      }

      if(apper && apper[0]) {
        body += `&agree_id=${apper[0]}`
      } else {
          Toast.info('审批人为必填项', 1);
        return
      }
        if(userid&&userid[0]) {
            const len=userid.join(',');
            body += `&yuangong=${len}`
        } else {
            Toast.info('加班人员为必填项', 1);
            return
        }

      if (!err) {

          if(this.state.jiaban_id!='')
          {
              body += `&id=${this.state.jiaban_id}`
          }
          //console.log(body);
        //Toast.loading('新建中, 请稍等....')
        postJiabanEditor(body, history)
        
       }
    });
  }
  //添加时间
    addtime=()=>{
        const lists=this.state.times;
        let minDate='';
        if(lists&&lists[0]){
            const last=lists[lists.length-1];
            if(last.endtime==''){ Toast.info('请填写上一组时间', 2);return}
            const starttime=moment(last.endtime).format('YYYY-MM-DD HH:mm');
            minDate=new Date(starttime);
            // maxDate=new Date(starttime.split(' ')[0]+' 23:59');
        }
        if(this.state.jiaban_id!=''){
            lists.push({"id": lists.length,"SminDate":minDate});
        }
        else
        lists.push({"id": ++i,"SminDate":minDate});
        this.setState({times:lists})
    }
    //选择时间
    timeChange=(index, fileds,value)=>{
        const lists=this.state.times;
        console.log(lists);
        lists[index][fileds]=value;
        if(fileds=="starttime"){
            const starttime=moment(value).format('YYYY-MM-DD HH:mm');
            lists[index]['EminDate']=new Date(starttime);
            lists[index]['EmaxDate']=new Date(starttime.split(' ')[0]+' 23:59');
        }
        this.setState({times: lists });
    }
    //删除时间
    delDate=(id)=>{
        const lists=this.state.times;
        lists.splice(id,1)
        this.setState({times:lists})
    }

  render() {
    const { getFieldProps } = this.props.form
    const { ctype, detail,users } = this.props
      //console.log(users);
    let apper = []
    apper = this.props.apper.map((value, index) => {
      return {
        value:value.u_id,
        label:value.u_fullname
      }
    })
    return (
      <div className='index-content' id='wipeout_editor'>
      <NavBar
        mode="dark"
        icon={<Icon type="left" />}
        onLeftClick={() => this.props.history.goBack() }
        rightContent={
            <a onClick={() => {
                this.addtime()
            }} style={{color: '#fff', paddingTop: 3}}>
               追加时间
            </a>
        }
        >加班申请</NavBar>
          <List className="picker-list my_list"  renderHeader=''>
              {/*<Item className='time_item' extra={*/}
                  {/*<div className='times'>*/}
                      {/*<DatePicker*/}
                          {/*mode="datetime"*/}
                          {/*title="选择开始时间"*/}
                          {/*extra="选择开始时间"*/}
                          {/*value={this.state.start_time}*/}
                          {/*onChange={date =>{this.setState({ start_time:date });*/}
                              {/*const starttime=moment(date).format('YYYY-MM-DD HH:mm')*/}
                              {/*minDate=new Date(starttime);*/}
                              {/*maxDate=new Date(starttime.split(' ')[0]+' 23:59');*/}
                          {/*}}>*/}
                          {/*<List.Item arrow="horizontal"></List.Item>*/}
                      {/*</DatePicker>*/}
                      {/*<DatePicker*/}
                          {/*minDate={minDate}*/}
                          {/*maxDate={maxDate}*/}
                          {/*mode="datetime"*/}
                          {/*title="选择结束时间"*/}
                          {/*extra="选择结束时间"*/}
                          {/*value={this.state.end_time}*/}
                          {/*onChange={date => {this.setState({ end_time:date })}}>*/}
                          {/*<List.Item arrow="horizontal"></List.Item>*/}
                      {/*</DatePicker>*/}
                  {/*</div>*/}
              {/*}><div className='time_title'>*/}
                  {/*<div>*/}
                      {/*<p>加班时间</p>*/}
                      {/*<a onClick={this.addtime}> <img src={addtime}/></a>*/}
                  {/*</div>*/}
                  {/*<div className='line'></div></div></Item>*/}
              {this.state.times.map((item) =>
                  <Item key={item.id} index={item.id} className='time_item' extra={
                      <div className='times'>
                          <DatePicker
                              minDate={item.SminDate}
                              mode="datetime"
                              title="选择开始时间"
                              extra="选择开始时间"
                              value={item.starttime}
                              onChange={date =>{this.timeChange(item.id,'starttime',date)
                              }}>
                              <List.Item arrow="horizontal"></List.Item>
                          </DatePicker>
                          <DatePicker
                              minDate={item.EminDate}
                              maxDate={item.EmaxDate}
                              mode="datetime"
                              title="选择结束时间"
                              extra="选择结束时间"
                              value={item.endtime}
                              onChange={date => {this.timeChange(item.id,'endtime',date)}}>
                              <List.Item arrow="horizontal"></List.Item>
                          </DatePicker>
                      </div>
                  }>
                      <div className='time_title'>
                          <div>
                              <p>加班时间</p>
                              <a onClick={()=>{this.delDate(item.id)}}> <img src={deltime}/></a>
                          </div>
                          <div className='line'></div></div>
                  </Item>
              )}

              <Item multipleLine wrap extra={this.state.userName.join(',')} onClick={(e) => {
                e.preventDefault()
                this.setState({visible:true})
            }}>加班人员</Item>
            <div style={{position:'fixed', top:0, bottom:0, width:'100%', display:this.state.visible ? 'block' : 'none', minHeight:document.documentElement.clientHeight, zIndex:99999, overflow:'scroll', backgroundColor:'#fff' }}>
              <Customer selectvalue={this.state.userid} customers={users} getCustomers={(value) => {
                  let apperName = [];
                  let user_id='';
                  value.forEach((v,i) => {
                      user_id+=v+',';
                      users.forEach((val, index) => {
                          if(v === val.u_id) {
                              apperName.push(val.u_fullname)
                          }
                      })
                  })
                  this.setState({userName:apperName, userid:value, visible:false})
              }} />
            </div>
              <CustomerSearch selectvalue={this.state.apper} initialValue={this.state.check_username}  customers={apper} onOk={(v) => {
                  this.setState({ apper: v })
              }} name='审批人' />


          </List>
        <List renderHeader='' className="jiaban-list">
          <TextareaItem
              {...getFieldProps('shiyou', {
                  rules:[{require: true}],
              })}
              title=''
              value={this.state.shiyou}
              onChange={value=>this.setState({shiyou:value})}
              placeholder="请填写事由"
              data-seed="logId"
              rows={4}
              ref={el => this.customFocusInst = el}
          />
        </List>
          <WhiteSpace />
          <WhiteSpace />
          <WhiteSpace />
        <WingBlank size="md">
          <Button type='primary' onClick={() => this.onSubmit()}>提交</Button>
        </WingBlank>
          <div className='daka_rule'>
              <span>注意事项</span>
              <p>
                  1）工作日加班根据下班打卡时间计算加班时长，不需要“开始”，“结束”按钮<br/>
                  2）跨天加班时，需按时间段追加<br/>
                  3）加班时，需要在申请列表中点击“开始加班”和“结束加班”按钮进行加班打卡，以此来计算加班时长<br/>
                  4）未加班打卡的，可在加班列表中申请补卡<br/>
                  5）员工仍需按上下班正常打卡记录出勤时间<br/>
              </p>
          </div>
      </div>
    )
  }
}

export default createForm()(JiabanEditor)