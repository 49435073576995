import { postRequest } from '../../../utils/utils';
import {Toast} from 'antd-mobile';
// ------------------------------------
// Constants
// ------------------------------------
export const JIABAN_EDITOR = 'JIABAN_EDITOR'

// ------------------------------------
// Actions
// ------------------------------------

//获取审核人
export const getApper = () => {
  return async (dispatch, getState) => {
    postRequest('/jiaban/check_user').then((value) => {
      //console.log(value)
      if(value.suc === 0) {
        if(value.msg === '请登陆！') {
          localStorage.removeItem('daka_token')
          alert('账号已在别处登录,请重新登录')
          this.props.history.push('/')
        }
        else  Toast.fail(value.msg,2)
      } else {
        dispatch({ type:'JIABAN_EDITOR', payload:{ apper: value.data } })
      }
      
    })
  }
}
//获取加班人列表
export const getuser = () => {
    return async (dispatch, getState) => {
        postRequest('/jiaban/user').then((value) => {
            //console.log(value)
            if(value.suc === 0) {
                if(value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
                else  Toast.fail(value.msg,2)
            } else {
                dispatch({ type:'JIABAN_EDITOR', payload:{ users: value.data } })
            }

        })
    }
}


export const postJiabanEditor = (body, history) => {
  return async (dispatch, getState) => {
    postRequest(`/jiaban/overtime`, body).then((value) => {
      if(value.suc === 0) {
        if(value.msg === '请登陆！') {
          localStorage.removeItem('daka_token')
          alert('账号已在别处登录,请重新登录')
          this.props.history.push('/')
        }
        else  Toast.fail(value.msg,2)
      } else {
          Toast.success(value.msg, 1,()=>{history.push('/jiaban/list');});
      }
    })
  }
}
