import React from 'react'
import PropTypes from 'prop-types'
import { NavBar, Icon, WhiteSpace, WingBlank, List, TextareaItem, Picker, Radio, Modal, Button, Toast, InputItem,Steps } from 'antd-mobile'
import { Link } from 'react-router-dom'
import { postRequest, getUserInfo } from '../../../utils/utils'
import 'antd-mobile/dist/antd-mobile.css'
import './index.css'
import moment from 'moment'


import daishenhe from '../../../images/icons/daishenhe.png'
import yitongguo from '../../../images/icons/yitongguo.png'
import shenpizhong from '../../../images/icons/shenpizhong.png'
import fushen from '../../../images/icons/fushen.png'
import yijujue from '../../../images/icons/yijujue.png'

const Item = List.Item;
const Step = Steps.Step;
const RadioItem = Radio.RadioItem;
class JiaBanMealsDetail extends React.Component{
  state = {
      data:{}
  }

  componentDidMount = () => {
    this.getDetail()
  }
  getDetail = async() => {
    const waichu_id = localStorage.getItem('waichu_id');
    postRequest(`/jiucan/view`, `id=${waichu_id}`).then((result) => {
      console.log(result)
      if(result && result.status === 1) {
        this.setState({data:result.data})
      } else {
          Toast.fail(result.msg || '获取失败', 1);
        if(result.msg === '请登陆！') {
          localStorage.removeItem('daka_token')
          alert('账号已在别处登录,请重新登录')
          this.props.history.push('/')
        }
      }
    })
  }

  
  render() {
      const { data } = this.state
    return (
      <div style={{backgroundColor:'#f5f5f7', minHeight:document.documentElement.clientHeight }}>
        <NavBar
        mode="dark"
        icon={<Icon type="left" />}
        onLeftClick={() => this.props.history.goBack() }
        >加班就餐详情</NavBar>
        <List className="my-list detail_list" renderHeader=''>
          <Item multipleLine wrap extra={data.user_id}>申请人</Item>
          <Item multipleLine wrap extra={data.infotime}>就餐时间</Item>
            <Item multipleLine wrap extra={data.car_id}>就餐人员</Item>
            <Item multipleLine wrap extra={data.feiyong}>就餐费用</Item>
            <Item multipleLine wrap extra={data.content}>申请事由</Item>
        </List>
          <div className='img_box'>
              <p>就餐签字单凭证</p>
              {
                  data.imgurl &&
                  <img onClick={() => {
                      const large = this.state.isLarge;
                      this.setState({isLarge: !large});
                      this.setState({imgurl: data.imgurl})
                  }} src={data.imgurl} alt=""/>
              }
          </div>
        <List renderHeader={() => '审核意见'} className="my-list">
          <Steps className='check_step' size="small" current={5}>
              {data.list==null?'':
                  data.list.map(i=>(i.check_id==null?'':
                          <Step key={i.id}  status={i.status==2?'error':'process'}
                                title=
                                    {<div className='check_user'>
                                        <span>{i.check_id}</span>
                                        <span className='check_time'>{i.reply_time}</span>
                                    </div>}
                                description={<div><span className='check_status'>{i.status==2?'审核未通过':'审核通过'}</span>
                                    {i.content==''?'':<span>({i.content})</span>}</div>}
                          />
                  ))
              }
          </Steps>
        </List>
          <div style={{height:'50px'}}></div>
          {this.state.isLarge ?
              <div onClick={() => {
                  const large = this.state.isLarge;
                  this.setState({isLarge: !large})
              }} className='img_view'>
                  <img src={this.state.imgurl}/>
              </div> : ''
          }
      </div>
    )
  }
}

export default JiaBanMealsDetail