import React from 'react'
import PropTypes from 'prop-types'
import { List, TextareaItem, Button, InputItem, NavBar, Checkbox, Icon, WhiteSpace, Picker, Toast, DatePicker,WingBlank,Steps } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import { Link } from 'react-router-dom'
import { createForm } from 'rc-form'
import { postRequest } from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import CustomerSearch from '../../components/customer_search'
const Item = List.Item;

const Step = Steps.Step;
class QingJiaFileCheck extends React.Component {
  static propTypes = {
    form:PropTypes.object.isRequired,
      checkQingJiafile:PropTypes.func.isRequired
  }

  state = {
      qingjiafile_id:'',
      data:{},
      option:'',
      checkers:[],
      showbtn:true,
  };

  componentDidMount() {
    this.getDetail();
  }
    getDetail = async() => {
        const qingjiafile_id = localStorage.getItem('qingjiafile_id');
        this.setState({qingjiafile_id:qingjiafile_id})
        postRequest(`/qingjiabucailiao/checkview`, `id=${qingjiafile_id}`).then((result) => {
            console.log(result)
            if (result && result.status === 1) {
                this.setState({data: result.data})
            } else {
                Toast.fail(result.msg || '获取失败', 1);
                if (result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }
    //获取主管
    getChecks = async() => {
        postRequest(`/yijiao/zhuguan`, ``).then((result) => {
            //console.log(result)
            if(result && result.status === 1) {
                this.setState({checkers:result.data})
            }
        })
    }

    //移交保存
    YiJiao = async(checkerid) => {
        const buka_id = localStorage.getItem('qingjia_id');
        let body2=`id=${buka_id}&mokuai=qingjia`;
        //获取移交前的数据
        postRequest(`/yijiao/yj`, body2).then((result) => {
            if(result && result.status === 1) {
                const shijian=result.data.shijian
                let body=`check_id=${checkerid}&id=${buka_id}&mokuai=qingjia&shijian=${shijian}`;
                postRequest(`/yijiao/save`, body).then((result) => {
                    console.log(result)
                    if(result && result.status === 1) {
                        Toast.success('移交成功',1);
                    }
                })
            }
        })

    }

    agree = (status) => {
        const {history, checkQingJiafile } = this.props
        let body =`id=${this.state.qingjiafile_id}&status=${status}&content=${this.state.option}`
        if(status==2){
            if(this.state.option) {

            } else {
                Toast.info('审核不通过时，必须填写理由', 1);
                this.setState({showbtn:true})
                return
            }
        }
        console.log(body);
        checkQingJiafile(body, history)
    }

    render() {
        const { data,checkers ,showbtn} = this.state;
        const user = JSON.parse(localStorage.getItem('daka_user'))
        const u_id = user.u_id;
        let checkername = [];
        checkername = checkers.map((value, index) => {
            return {
                value: value.u_id,
                label: value.u_fullname
            }
        });
        //console.log(data);
        return (
            <div style={{backgroundColor:'#f5f5f7', minHeight:document.documentElement.clientHeight }}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack() }
                >请假证明材料审批</NavBar>
                <List className="my-list" renderHeader=''>
                    <Item multipleLine wrap extra={data.starttime}>请假开始时间</Item>
                    <Item multipleLine wrap extra={data.endtime}>请假结束时间</Item>
                    <Item multipleLine wrap extra={data.type}>请假类型</Item>
                    <Item multipleLine wrap extra={data.content}>材料说明</Item>

                </List>
                <div className='img_box'>
                    <p>证明材料</p>
                    {
                        data.imgurl &&
                        <img onClick={() => {
                            const large = this.state.isLarge;
                            this.setState({isLarge: !large});
                            this.setState({imgurl: data.imgurl})
                        }} src={data.imgurl} alt=""/>
                    }
                </div>
                {data.type=='特批出勤'&&
                    <div className='img_box'>
                        <p>核检结果</p>
                        {
                            data.tpurl &&
                            <img onClick={() => {
                                const large = this.state.isLarge;
                                this.setState({isLarge: !large});
                                this.setState({imgurl: data.tpurl})
                            }} src={data.tpurl} alt=""/>
                        }
                    </div>
                }
                { u_id==data.check_id&&data.status==0?
                <List renderHeader='' className="qj-list">
                    <TextareaItem
                        value={this.state.option}
                        onChange={value=>this.setState({option:value})}
                        placeholder="请填写意见"
                        data-seed="logId"
                        rows={4}
                        ref={el => this.customFocusInst = el}
                    />
                </List>:''}
                {data.record == '' ? '' :
                    <List renderHeader={() => '审核意见'} className="my-list">
                        <Steps className='check_step' size="small" current={5}>
                            {data.record == null ? '' :
                                data.record.map(i => (i.check_id == null ? '' :
                                        <Step key={i.id} status={i.status == 2 ?  'error' : 'process'}
                                              title=
                                                  {<div className='check_user'>
                                                      <span>{i.check_id}</span>
                                                      <span className='check_time'>{i.infotime}</span>
                                                  </div>}
                                              description={<div><span className='check_status'>{i.status==1?'审核通过':'审核未通过'}</span>
                                                  {i.content == '' ? '' : <span>({i.content})</span>}</div>}
                                        />
                                ))
                            }
                        </Steps>
                    </List>
                }
                <div style={{height:'40px'}}></div>
                {(data.status==1||data.status==2)?'':
                    !showbtn?'':
                        (data.status==0&&data.check_id==u_id)?
                            <div className='bottom_btn'>
                                <div className='bottom_left' onClick={()=>{this.setState({showbtn:false});this.agree(2)}}>不同意</div>
                                <div className='bottom_right' onClick={()=>{this.setState({showbtn:false});this.agree(1)}}>同意</div>
                            </div>:''
                }
                {this.state.isLarge ?
                    <div onClick={() => {
                        const large = this.state.isLarge;
                        this.setState({isLarge: !large})
                    }} className='img_view'>
                        <img src={this.state.imgurl}/>
                    </div> : ''
                }
            </div>
        )
    }
}

export default createForm()(QingJiaFileCheck)