import { connect } from 'react-redux'
import { checkJiaBanMeals } from './actions'
import Setting from './main'


const mapDispatchToProps = {
    checkJiaBanMeals
}
const mapStateToProps = (state) => {
  return {
    ...state.JiaBanMealsCheck
  }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
