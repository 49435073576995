import { postRequest, getUserInfo } from '../../../utils/utils';
import {Toast} from 'antd-mobile';
// ------------------------------------
// Constants
// ------------------------------------
export const JIABAN_LIST = 'JIABAN_LIST'

// ------------------------------------
// Actions
// ------------------------------------


export const getJiaban = (pagesize,oldData,type, keyword) => {
  return async (dispatch, getState) => {
    postRequest(`/jiaban/overtimelist`, `keyword=${keyword || ''}&&page_size=${pagesize}`).then((value) => {
     console.log(value);
      if(value.suc === 0) {
          dispatch({type: JIABAN_LIST, payload: {jblistdata: [],nojb: false,count:0 }})
        if(value.msg === '请登陆！') {
          localStorage.removeItem('daka_token')
          alert('账号已在别处登录,请重新登录')
          this.props.history.push('/')
        }
      } else {
        //console.log(value)
          if(value.data!='') {
              let newlist=[];
              newlist = value.data.shuju
              if(oldData && oldData.length > 0) {
                  newlist = oldData.concat(value.data.shuju)
              }
              dispatch({type: JIABAN_LIST, payload: {jblistdata: newlist,nojb: true,count:value.data.count }})
          }
          else {
              dispatch({ type: JIABAN_LIST, payload:{nojb: false,count:0 } })
          }
      }
    })
  }
}

export const getOverTime = (pagesize,oldData,type, keyword) => {
  return async (dispatch, getState) => {
    postRequest(`/jiaban/check_list`, `keyword=${keyword || ''}&&page_size=${pagesize}`).then((value) => {
        console.log(value);
      if(value.suc === 0) {
       // alert(value.msg)
          //Toast.info(value.msg, 1);
          dispatch({type: JIABAN_LIST, payload: {jbcheckdata: [],nocheck: false,count2:0 }})
        if(value.msg === '请登陆！') {
          localStorage.removeItem('daka_token')
          alert('账号已在别处登录,请重新登录')
          this.props.history.push('/')
        }
      } else {
        //console.log(value)
          if(value.data!='') {
              let newlist=[];
              newlist = value.data.shuju
              if(oldData && oldData.length > 0) {
                  newlist = oldData.concat(value.data.shuju)
              }
              dispatch({type: JIABAN_LIST, payload: {jbcheckdata: newlist,nocheck: true,count2:value.data.count}})
          }
          else {
              dispatch({ type: JIABAN_LIST, payload:{nocheck: false,count2:0 } })
          }
      }
    })
  }
}


export const deleteJiaban = (body,history) => {
    return async (dispatch, getState) => {
        postRequest(`/jiaban/del`, body).then((value) => {
            console.log(value);
            if(value.suc === 0) {
                Toast.info(value.msg, 1);
                if(value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            } else {
                Toast.success('删除成功！', 1);
                dispatch(getJiaban());
            }
        })
    }
}