import { connect } from 'react-redux'
import { getIndex } from './actions'
import Index from './main'


const mapDispatchToProps = {
  getIndex
}
const mapStateToProps = (state) => {

  return {
    ...state.home
  }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Index)
