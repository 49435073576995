import React from 'react'
import PropTypes from 'prop-types'
import { NavBar, Icon, WhiteSpace, WingBlank, List, TextareaItem, Picker, Radio, Modal, Button, Toast, InputItem,Steps } from 'antd-mobile'
import { Link } from 'react-router-dom'
import { postRequest, getUserInfo } from '../../../utils/utils'
import 'antd-mobile/dist/antd-mobile.css'
import './index.css'
import moment from 'moment'

import daishenhe from '../../../images/icons/daishenhe.png'
import yitongguo from '../../../images/icons/yitongguo.png'
import shenpizhong from '../../../images/icons/shenpizhong.png'
import fushen from '../../../images/icons/fushen.png'
import yijujue from '../../../images/icons/yijujue.png'

const Item = List.Item
const Step = Steps.Step;
const RadioItem = Radio.RadioItem;
class JiabanDetail extends React.Component{
  state = {
    data:{
      info:{},
      flow:{},
      ctype:{},
      nextApper:[]
    },
    state:'1',
    apper:'',
    opinion:'',
    show:false,
    paytotal:''
  }

  componentDidMount = () => {
    
    
    this.getDetail()
  }
  getDetail = async() => {
    const jiaban_id = localStorage.getItem('jiaban_id')
    postRequest(`/jiaban/view_overtime`, `id=${jiaban_id}`).then((result) => {
      //console.log(result)
      if(result && result.suc === 1) {
        this.setState({data:result.data})
      } else {
          Toast.fail(result.msg || '获取失败', 1);
        if(result.msg === '请登陆！') {
          localStorage.removeItem('daka_token')
          alert('账号已在别处登录,请重新登录')
          this.props.history.push('/')
        }
      }
    })
  }

  
  render() {
    const { data } = this.state
    const daka_user = JSON.parse(localStorage.getItem('daka_user'))
    const uId = daka_user.u_id
    const status = [
      { value: '1', label: '通过' },
      { value: '2', label: '拒绝' },
    ];
    const time = new Date(Number(data.info && data.info.addtime + '000'))
    const date = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()} ${time.getHours()}:${time.getMinutes()}`

    let timeLine = []
    if(data.approverShow && data.approverShow.length > 0) {
      timeLine = data.approverShow.map((value, index) => {
        let addTime = ''
        let addDate = ''
        if(value.notes && value.notes.addtime) {
          addTime = new Date(Number(value.notes.addtime + '000'))
          addDate = moment(addTime).format('YYYY-MM-DD hh:ss')
        }
        return(
          <div className="vertical-timeline-block" key={index}>
            <div className="vertical-timeline-icon">
              <img src={value.avatar} alt='avatar' width='40' />
            </div>
            <div className="vertical-timeline-content">
              {
                value.notes && <span style={{color:'#23c6c8'}}>{value.notes.state == '1' ? '通过' : '不通过'}</span>
              }
              <span>{value.fullname}</span>
              <br/>
              {
                !value.notes && <span style={{ color:'#1ab394', fontSize:11 }}>进行中...</span>
              }
              {
                value.notes && <small>{addDate}</small> 
              }
              {
                value.notes && <div className="well"><span className="text-navy">{value.notes.opinion}</span></div>
              }
            </div>
          </div>
        )
      })
    }

    return (
      <div style={{backgroundColor:'#f5f5f7', minHeight:document.documentElement.clientHeight }}>
        <NavBar
        mode="dark"
        icon={<Icon type="left" />}
        onLeftClick={() => this.props.history.goBack() }
        >加班详情</NavBar>
        <List className="my-list jb_list detail_list" renderHeader={() => '加班信息'}>
          <Item multipleLine wrap extra={<span dangerouslySetInnerHTML={{ __html: data.shijian}}></span>}>开始时间</Item>
          {/*<Item multipleLine wrap extra={data.xiaoshi}>加班时长</Item>*/}
          <Item multipleLine wrap extra={data.u_fullname}>申请人</Item>
          <Item multipleLine wrap extra={data.yuangong}>加班人</Item>
          {/*<Item multipleLine wrap extra={data.agree_name}>审批人</Item>*/}
          {/*<Item multipleLine wrap extra={data.status}>审批状态</Item>*/}
          <Item multipleLine wrap extra={data.shiyou}>申请事由</Item>
        </List>
          {data.check==''?'':
              <List renderHeader={() => '审核意见'} className="my-list">
                <Steps className='check_step' size="small" current={5}>
                    {
                        data.check==null?'':
                        data.check.map(i=>(i.agree_id==null?'':
                                <Step key={i.id}  status={i.status==2 ? 'error':'process'}
                                      title=
                                          {<div className='check_user'>
                                            <span>{i.agree_id}</span>
                                            <span className='check_time'>{i.infotime}</span>
                                          </div>}
                                      description={<div><span className='check_status'>{i.status==1||i.status==3?'审核通过':'审核未通过'}</span>
                                          {i.beizhu==''?'':<span>({i.beizhu})</span>}</div>}
                                />
                        ))
                    }
                </Steps>
              </List>
  }
        <div id="vertical-timeline" className="vertical-container dark-timeline">
          {timeLine}
        </div>
          {/*{*/}
            {/*data.status === '待审批' && uId === data.agree_id &&*/}
            {/*<div style={{width:40, height:40, borderRadius:40, backgroundColor:'#007aff', textAlign:'center', position:'fixed', bottom:20, right:20, lineHeight:'40px', zIndex:99}}*/}
            {/*onClick={() => {*/}
              {/*this.setState({show:true})*/}
            {/*}}>*/}
              {/*<span style={{color:'#fff'}}>审批</span>*/}
            {/*</div>*/}
          {/*}*/}
        {/*<Modal*/}
          {/*popup*/}
          {/*visible={this.state.show}*/}
          {/*onClose={() => {*/}
            {/*this.setState({show:false})*/}
          {/*}}*/}
          {/*animationType="slide-up"*/}
        {/*>*/}
          {/*<List>*/}
              {/*{*/}
                {/*status.map(i => (*/}
                  {/*<RadioItem key={i.value} checked={this.state.state === i.value} onChange={() => {*/}
                    {/*this.setState({state:i.value})*/}
                  {/*}}>*/}
                    {/*{i.label}*/}
                  {/*</RadioItem>*/}
                {/*))*/}
              {/*}*/}
              {/*<TextareaItem*/}
                  {/*title="审批意见"*/}
                  {/*placeholder="请填写审批意见"*/}
                  {/*data-seed="logId"*/}
                  {/*rows={2}*/}
                  {/*ref={el => this.customFocusInst = el}*/}
                  {/*onChange={(value) => {*/}
                    {/*this.setState({opinion:value})*/}
                    {/*//console.log(value)*/}
                  {/*}}*/}
                {/*/>*/}
            {/*</List>*/}
            {/*<Button type='primary' */}
              {/*onClick={() => {*/}
                {/*Toast.loading('审批中, 请稍等....')*/}
                {/*let body = `id=${data.id}&beizhu=${this.state.opinion}&status=${this.state.state}`*/}

                {/*postRequest(`/jiaban/save_check`, body).then((result) => {*/}
                  {/*console.log(result)*/}
                  {/*if(result && result.suc == 1) {*/}
                      {/*Toast.success('审批成功', 1);*/}
                    {/*this.setState({show:false})*/}
                    {/*Toast.hide()*/}
                    {/*this.props.history.goBack()*/}
                  {/*} else {*/}
                      {/*Toast.fail(result.msg || '审批失败', 1);*/}
                  {/*}*/}
                {/*})*/}
                {/**/}
                {/**/}
                {/**/}
              {/*}}>确定</Button>*/}
        {/*</Modal>*/}
        {/*{*/}
          {/*this.state.show && */}
          {/*<div style={{backgroundColor:`rgba(0,0,0,0.2)`, height:document.documentElement.clientHeight,position:'fixed', top:0, left:0, width:'100%'}}>*/}
            {/**/}
          {/*</div>*/}
          {/*}*/}
      </div>
    )
  }
}

export default JiabanDetail