import React from 'react'
import PropTypes from 'prop-types'
import { NavBar, Icon, WhiteSpace, WingBlank, List, TextareaItem, Picker, Radio, Modal, Button, Toast, InputItem,Steps } from 'antd-mobile'
import { Link } from 'react-router-dom'
import { postRequest, getUserInfo } from '../../../utils/utils'
import 'antd-mobile/dist/antd-mobile.css'
import './index.css'
import moment from 'moment'


import daishenhe from '../../../images/icons/daishenhe.png'
import yitongguo from '../../../images/icons/yitongguo.png'
import shenpizhong from '../../../images/icons/shenpizhong.png'
import fushen from '../../../images/icons/fushen.png'
import yijujue from '../../../images/icons/yijujue.png'

const Item = List.Item;
const Step = Steps.Step;
const RadioItem = Radio.RadioItem;
class BukaDetail extends React.Component{
  state = {
      data:{}
  }

  componentDidMount = () => {
    this.getDetail()
  }
  getDetail = async() => {
    const buka_id = localStorage.getItem('buka_id');
    postRequest(`/bk/view`, `id=${buka_id}`).then((result) => {
      console.log(result)
      if(result && result.status === 1) {
        this.setState({data:result.data.list})
      } else {
          Toast.fail(result.msg || '获取失败', 1);
        if(result.msg === '请登陆！') {
          localStorage.removeItem('daka_token')
          alert('账号已在别处登录,请重新登录')
          this.props.history.push('/')
        }
      }
    })
  }

  
  render() {
      const { data } = this.state
    return (
      <div style={{backgroundColor:'#f5f5f7', minHeight:document.documentElement.clientHeight }}>
        <NavBar
        mode="dark"
        icon={<Icon type="left" />}
        onLeftClick={() => this.props.history.goBack() }
        >补卡详情</NavBar>
        <List className="my-list" renderHeader=''>
          <Item multipleLine wrap extra={data.user_id}>申请人</Item>
          <Item multipleLine wrap extra={data.shijian}>补卡时间</Item>
            <Item multipleLine wrap extra={data.type}>补卡类型</Item>
            <Item multipleLine wrap extra={data.address}>打卡地址</Item>
            <Item multipleLine wrap extra={data.content}>补卡事由</Item>
          {/*<Item multipleLine wrap extra={data.check_id}>审批人</Item>*/}
          {/*<Item multipleLine wrap extra={data.status}>审批状态</Item>*/}
            {/*{data.check==null?'':*/}
                {/*data.check.map(i=>(i.content==null?'':*/}
                    {/*<Item key={i.id} multipleLine wrap extra={i.content}>审批意见</Item>*/}
                {/*))*/}
            {/*}*/}

        </List>
          <div className='img_box'>
              <p>监控照片</p>
              {
                  data.imgurl ?
                      <img onClick={() => {
                          const large = this.state.isLarge;
                          this.setState({isLarge: !large});
                          this.setState({imgurl: data.imgurl})
                      }} src={data.imgurl} alt=""/>:
                      <span>暂无图片</span>
              }
          </div>

          <List renderHeader={() => '审核意见'} className="my-list">
              <Steps className='check_step' size="small" current={5}>
                  {data.check==null?'':
                      data.check.map(i=>(i.check_id==null?'':
                              <Step key={i.id}  status={i.status==2?'error':'process'}
                                    title=
                                        {<div className='check_user'>
                                            <span>{i.check_id}</span>
                                            <span className='check_time'>{i.reply_time}</span>
                                        </div>}
                                    description={<div><span className='check_status'>
                                        {i.status==3||i.status==4||i.status==5?'审核通过':(i.status==1?'已登记':'审核未通过')}</span>
                                        {i.content==''?'':<span>({i.content})</span>}</div>}
                              />
                      ))
                  }
              </Steps>
          </List>
          {this.state.isLarge ?
              <div onClick={() => {
                  const large = this.state.isLarge;
                  this.setState({isLarge: !large})
              }} className='img_view'>
                  <img src={this.state.imgurl}/>
              </div> : ''
          }
      </div>
    )
  }
}

export default BukaDetail