import { connect } from 'react-redux'
import { getQingJiaDel,getQingJiaDelChecklist,deleteQingJiaDel} from './actions'
import Setting from './main'


const mapDispatchToProps = {
    getQingJiaDel,
    getQingJiaDelChecklist,
    deleteQingJiaDel
}
const mapStateToProps = (state) => {
  return {
    ...state.QingJiaDelList
  }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
