import { postRequest } from '../../../utils/utils';
import {Toast} from 'antd-mobile';
// ------------------------------------
// Constants
// ------------------------------------
export const JiaBanMeals_EDITOR = 'JiaBanMeals_EDITOR'

// ------------------------------------
// Actions
// ------------------------------------
//获取审核人
export const getApper = () => {
  return async (dispatch, getState) => {
    postRequest('/qingjia/agree').then((value) => {
      //console.log(value)
      if(value.suc === 0) {
          Toast.fail(value.msg, 1);
        if(value.msg === '请登陆！') {
          localStorage.removeItem('daka_token')
          alert('账号已在别处登录,请重新登录')
          this.props.history.push('/')
        }
      } else {
        dispatch({ type:'JiaBanMeals_EDITOR', payload:{ apper: value.data } })
      }
      
    })
  }
}
//获取部门下的人员
export const getuser = () => {
    return async (dispatch, getState) => {
        postRequest('/jiucan/getuser').then((value) => {
            //console.log(value)
            if(value.suc === 0) {
                if(value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            } else {
                dispatch({ type:'JiaBanMeals_EDITOR', payload:{ users: value.data } })
            }

        })
    }
}

export const saveJiaBanMeals = (body, history) => {
    //console.log(body);
    return async (dispatch, getState) => {
        postRequest(`/jiucan/apply`, body).then((value) => {
          //console.log(value);
            if(value.suc === 0) {
                Toast.fail(value.msg, 1);
                if(value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            } else {
                if(value.status==0)
                    Toast.fail(value.msg, 2);
                else
                Toast.success(value.msg, 1,()=>{history.push('/jiabanMeals/list');});
            }
        })
    }
}


