import { postRequest, getUserInfo } from '../../utils/utils'
// ------------------------------------
// Constants
// ------------------------------------
export const INDEX_ACTION = 'INDEX_ACTION'

// ------------------------------------
// Actions
// ------------------------------------

export const getIndex = (history) => {
  return async (dispatch, getState) => {
    let data = {}
    const user = getUserInfo()
    postRequest('/Index/index').then((value) => {
      if(value.suc === 0) {
        alert(value.msg)
        history.push('/')
      } else {
        data = value.data
        dispatch({ type: INDEX_ACTION, payload:{ data, user } })
      }
    })
  }
}


