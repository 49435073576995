import React from 'react'
import PropTypes from 'prop-types'
import {Flex, WingBlank, WhiteSpace, NavBar, List, Icon, Toast, Button, DatePicker} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {postRequest, getUserInfo} from '../../utils/utils'
import BottomTab from '../components/bottomTab'


import './index.css'

const Item = List.Item;
const Brief = Item.Brief;

class TongJiIndex extends React.Component {
    static propTypes = {}

    state = {
        selectedTab: 'tongji',
        shijian: moment().format('YYYY-MM'),
        chidao: 0,
        zaotui: 0,
        chucai: 0,
        qingjia: 0,
        waichu: 0,
        buka: 0,
        jiaban: 0,
        worknum: 0,
        queqing: 0,
        queka:0
    };

    componentDidMount() {
        this.report();
    }

    report = async (shijian) => {
        var body='';
        if(shijian)
        {
            body+=`shijian=${shijian}`;
        }
        localStorage.setItem('body', body)
        this.getWorkNum(body);
        this.getChuChai(body);
        this.getWaiChu(body);
        this.getqingjia(body);
        this.getchidao(body);
        this.getzaotui(body);
        this.getbuka(body);
        this.getjiaban(body);
        this.getqueka(body);
    }
    //统计总天数和缺勤
    getWorkNum = async (body) => {
        postRequest(`/zongji/tianshu`, body).then((result) => {
            this.setState({worknum: result.tianshu});
            this.setState({queqing: result.queka});
        })
    }
    //统计迟到
    getchidao = async (body) => {
        postRequest(`/zongji/chidao`, body).then((result) => {
            this.setState({chidao: result.chidao});
        })
    }
    //统计早退
    getzaotui = async (body) => {
        postRequest(`/zongji/zaotui`, body).then((result) => {
           // console.log(result)
            this.setState({zaotui: result.zaotui});
        })
    }
    //统计出差打卡次数
    getChuChai = async (body) => {
        postRequest(`/zongji/chuchai`, body).then((result) => {
            console.log(result);
            this.setState({chucai: result.chuhcai});
        })
    }
    //统计外出打卡次数
    getWaiChu = async (body) => {
        postRequest(`/zongji/waichu`, body).then((result) => {
            this.setState({waichu: result.waichu});
        })
    }

    //统计补卡
    getbuka = async (body) => {
        postRequest(`/zongji/buka`, body).then((result) => {
            this.setState({buka: result.buka});
        })
    }
    //统计请假
    getqingjia = async (body) => {
        postRequest(`/zongji/qingjia`, body).then((result) => {
            this.setState({qingjia: result.qingjia});
        })
    }
    //统计加班(点击开始，结束才开始计算加班时长)
    getjiaban = async (body) => {
        postRequest(`/zongji/jiaban`, body).then((result) => {
            //console.log(result);
            this.setState({jiaban: result.jiaban});
        })
    }
    //统计缺卡
    getqueka = async (body) => {
        postRequest(`/zongji/queka`, body).then((result) => {
            //console.log(result);
            this.setState({queka: result.queka});
        })
    }

    // testfuc=()=>{
    //     postRequest(`/login/check`, `status=3&jingdu=${this.state.jingdu}&weidu=${this.state.weidu}`).then((result) => {
    //         if (result.suc == 0) {
    //             return false
    //         }
    //         else return true
    //     })
    // }

    render() {
        const {shijian} = this.state
        return (
            <div className='index-content'>
                <List className="my-list tj_list">
                    <div className='tj_sj'>
                        <div className='line'></div>
                        <div className='search_top'>
                            <DatePicker
                                mode="month"
                                title="选择打卡时间"
                                extra="Optional"
                                value={this.state.date}
                                onChange={date => {
                                    this.setState({shijian: moment(date).format('YYYY-MM'), date});
                                    console.log(moment(date).format('YYYY-MM'));
                                    this.report(moment(date).format('YYYY-MM'));
                                }}
                            >
                                <Button style={{borderRadius: 0, borderColor: '#fff'}}>{shijian}<span
                                    style={{fontSize: 8}}> ▼</span></Button>
                            </DatePicker>
                        </div>
                        <div className='line'></div>
                    </div>
                    <div className='tj_img'>
                        <div className='tj_bz'>
                            缺勤/总天数
                        </div>
                        <div className='tj_num'>
                            <span>{this.state.queqing}</span>/
                            <span>{this.state.worknum}</span>
                        </div>
                        <div className='tj_link'>
                            <Link to='/record'><span>出勤记录</span></Link>
                        </div>
                    </div>
                </List>
                <List renderHeader={() => '考勤统计'} className="my-list">
                    {/*<Item arrow="horizontal" multipleLine onClick={() => {*/}
                        {/*this.testfuc().then((result)=>{*/}
                            {/*console.log(result);*/}
                        {/*})*/}
                    {/*}} >*/}
                        {/*测试*/}
                    {/*</Item>*/}
                    <Item arrow="horizontal" multipleLine onClick={() => {
                        localStorage.setItem('type', 1)
                        this.props.history.push('/chuqing')
                    }} extra={<span>{this.state.chidao}次</span>}>
                        迟到
                    </Item>
                    <Item arrow="horizontal" multipleLine onClick={() => { localStorage.setItem('type', 2)
                        this.props.history.push('/chuqing')
                    }} extra={<span>{this.state.zaotui}次</span>}>
                        早退
                    </Item>
                    <Item arrow="horizontal" multipleLine onClick={() => { localStorage.setItem('type', 3)
                        this.props.history.push('/chuqing')
                    }} extra={<span>{this.state.chucai}次</span>}>
                        出差
                    </Item>
                    <Item arrow="horizontal" multipleLine onClick={() => { localStorage.setItem('type', 4)
                        this.props.history.push('/chuqing')
                    }} extra={<span>{this.state.waichu}次</span>}>
                        外出
                    </Item>
                    <Item arrow="horizontal" multipleLine onClick={() => {
                        localStorage.setItem('type', 6)
                        this.props.history.push('/chuqing')
                    }} extra={<span>{this.state.qingjia}小时</span>}>
                        请假
                    </Item>
                    <Item arrow="horizontal" multipleLine onClick={() => {
                        localStorage.setItem('type', 7)
                        this.props.history.push('/chuqing')
                    }} extra={<span>{this.state.jiaban}小时</span>}>
                        加班
                    </Item>
                    <Item arrow="horizontal" multipleLine onClick={() => {
                        localStorage.setItem('type', 5)
                        this.props.history.push('/chuqing')
                    }} extra={<span>{this.state.buka}次</span>}>
                        补卡
                    </Item>
                    <Item arrow="horizontal" multipleLine onClick={() => {
                        this.props.history.push('/queka')
                    }} extra={<span>{this.state.queka}次</span>}>
                        缺卡
                    </Item>
                </List>
                <div style={{height: 54}}></div>
                <BottomTab selectedTab='tongji'/>
            </div>
        )
    }
}

export default TongJiIndex
