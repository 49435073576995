import React from 'react'
import PropTypes from 'prop-types'
import {
    ListView,
    WhiteSpace,
    NavBar,
    Icon,
    SearchBar,
    Card,
    Button,
    SegmentedControl,
    Checkbox,
    Tabs,
    Toast
} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import {postRequest, getUserInfo} from '../../../utils/utils'
import BottomTab from '../../components/bottomTab'
import './index.css'

import {createForm} from 'rc-form'

//图片引入

import Photo from '../../../images/icons/photo.png'

const CheckboxItem = Checkbox.CheckboxItem
const tabs2 = [
    {title: '申请列表', sub: '1'},
    {title: '审批列表', sub: '2'}
];
const dataSource = new ListView.DataSource({  //这个dataSource有cloneWithRows方法
    rowHasChanged: (row1, row2) => row1 !== row2,
})

class WaiChuBackList extends React.Component {
    static propTypes = {
        getWaiChuBack: PropTypes.func.isRequired,
        getWaiChuBackChecklist: PropTypes.func.isRequired,
        deleteWaiChuBack: PropTypes.func.isRequired
    }
    state = {
        height: document.documentElement.clientHeight-70,
        dataSource,
        hasdata: true,
        hascheckdata: true,
        tabindex: 1,  //tab选项卡索引判断是申请还是审核列表(1:申请列表；2：审核列表)
        page_size: 1,
        hasMore: true,
        page_size2: 1,
        hasMore2: true,
        isLarge: false,  //是否放大
        imgurl: '' //放大图片地址
    }

    componentWillMount() {
        // const index = this.props.location.state
        // if (index == undefined) {
        //     this.setState({tabindex: 0})
        // }
        // else {
        //     this.setState({tabindex: 1})
        // }
    }

    //完成挂载
    componentDidMount() {
        // const index = this.props.location.state
        // if (index == undefined) {
        //     this.props.getWaiChuBack();
        // }
        // else {
        //     this.props.getWaiChuBackChecklist()
        // }
        this.props.getWaiChuBackChecklist()
    }

    //删除补卡
    delete = async (bukaid) => {
        const {history, deleteWaiChuBack} = this.props
        let body = `id=${bukaid}`
        deleteWaiChuBack(body, history);
    }
    //滚动分页
    onEndReached1 = () => {
        const {page_size} = this.state
        const pagesize = Math.ceil(this.props.count / 20) //页数
        let p = page_size;
        if (!this.state.hasMore) {
            return
        }
        else {
            if (p >= pagesize) {
                this.setState({hasMore: false})
            }
            else {
                this.setState({hasMore: true})
                p = page_size + 1;
                this.setState({page_size: p})
                const oldData = this.props.listdata;
                this.props.getWaiChuBack(p, oldData)
            }
        }
    }
    onEndReached2 = () => {
        const {page_size2, hasMore2} = this.state
        const pagesize = Math.ceil(this.props.count2 / 20) //页数
        let p = page_size2;
        if (!hasMore2) {
            return
        }
        else {
            if (p >= pagesize) {
                this.setState({hasMore2: false})
            }
            else {
                this.setState({hasMore2: true})
                p = page_size2 + 1;
                this.setState({page_size2: p})
                const oldData = this.props.checklistdata;
                this.props.getWaiChuBackChecklist(p, oldData)
            }
        }
    }

    render() {
        const {listdata, hasdata, hascheckdata, checklistdata} = this.props;
        //console.log(hasdata);
        const user = JSON.parse(localStorage.getItem('daka_user'))
        const uid = user.uid;
        //console.log(user);
        const separator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    backgroundColor: '#F5F5F9',
                    height: 10,
                }}
            />
        );
        const nodata = () => {
            return (
                <div className='nodata'>
                </div>
            );
        }
        const row = (rowData, sectionID, rowID) => {
            //console.log(rowData);
            const time = new Date(Number(rowData.starttime + '000'))
            const date = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()} ${time.getHours()}:${time.getMinutes()}`
            let stateSpan = ""
            if (rowData.status === 0) {
                stateSpan = <span style={{fontSize: 16, color: '#FF8E2B'}}>待主管审核</span>
            }

            if (rowData.status === 1) {
                stateSpan = <span style={{fontSize: 16, color: '#1296db'}}>已通过</span>
            }
            if (rowData.status === 2) {
                stateSpan = <span style={{fontSize: 16, color: '#ff4d4f'}}>未通过</span>
            }

            return (
                <Card>
                    <Card.Header
                        extra={stateSpan}
                        title={`申请人: ${rowData.user_id}`}
                        style={{
                            fontSize: 15
                        }}
                    />

                    <Card.Body style={{padding: 10, paddingLeft: 15, display: 'flex', flexDirection: 'row'}}
                              >
                        <div className='list_txcontent'>

                            <div onClick={() => {
                                localStorage.setItem('waichu_id', rowData.id)
                                this.state.tabindex == 0 ?
                                    this.props.history.push({pathname: '/waichuback/detail'}) :
                                    this.props.history.push({pathname: '/waichuback/check'})
                            }} style={{flex: 1}}>
                                <div className='card-body-list'>{`外出时间： ${rowData.wc_time}`}</div>

                                <div className='card-body-list'>{`外出不返回公司原由： ${rowData.content}`}</div>
                            </div>
                        </div>
                    </Card.Body>
                    {
                        rowData.status === 0 && this.state.tabindex == 0 ?
                            <Card.Footer className='list_footer' content={<div onClick={() => {
                                localStorage.setItem('waichu_id', rowData.id);
                                this.props.history.push({pathname: '/waichuback/editor'})
                            }}>编辑</div>}
                                         extra={<div onClick={() => {
                                             this.delete(rowData.id)
                                         }}>删除</div>}>
                            </Card.Footer> : ''
                    }
                </Card>

            );
        };

        return (
            <div id='wipeout-list' style={{backgroundColor: '#f5f5f9'}}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left"/>}
                    onLeftClick={() => this.props.history.goBack()}
                    // rightContent={
                    //     <Link to='/waichu/editor' onClick={() => {
                    //         localStorage.setItem('waichu_id', '');
                    //     }} style={{color: '#fff', paddingTop: 3}}><Icon key="0" type="plus" size='xs'/></Link>
                    // }
                >外出不返回公司审核</NavBar>
                <div>
                    <ListView
                        ref={el => this.lv = el}
                        dataSource={this.state.dataSource.cloneWithRows(checklistdata)}
                        renderRow={row}
                        style={{
                            height: this.state.height,
                            overflow: 'auto',
                        }}
                        renderSeparator={separator}
                        renderBodyComponent={!hascheckdata ? nodata : null} //无数据时显示
                        onEndReached={this.onEndReached2}
                        onEndReachedThreshold={10}
                        renderFooter={() => (
                            <p>{hascheckdata ? (this.state.hasMore2 && checklistdata.length >= 20 ? '加载中..., 请稍等' : '已经全部加载完毕') : ''}</p>
                        )}
                    />
                    <div style={{height: '53px'}}></div>
                </div>
                <Tabs swipeable={false} tabs={tabs2}
                      initialPage={this.state.tabindex}
                      onChange={(tab, index) => {
                          if (index === 0) {
                              this.props.getWaiChu()
                              this.setState({tabindex: 0})
                          }
                          if (index === 1) {
                              this.props.getWaiChuChecklist()
                              this.setState({tabindex: 1})
                          }
                      }}
                >
                    <div>
                        <ListView
                            ref={el => this.lv = el}
                            dataSource={this.state.dataSource.cloneWithRows(listdata)}
                            renderRow={row}
                            style={{
                                height: this.state.height,
                                overflow: 'auto',
                            }}
                            renderSeparator={separator}
                            renderBodyComponent={!hasdata ? nodata : null} //无数据时显示
                            onEndReached={this.onEndReached1}
                            onEndReachedThreshold={10}
                            renderFooter={() => (
                                <p>{hasdata ? (this.state.hasMore && listdata.length >= 20 ? '加载中..., 请稍等' : '已经全部加载完毕') : ''}</p>
                            )}
                        />

                    </div>
                    <div>
                        <ListView
                            ref={el => this.lv = el}
                            dataSource={this.state.dataSource.cloneWithRows(checklistdata)}
                            renderRow={row}
                            style={{
                                height: this.state.height,
                                overflow: 'auto',
                            }}
                            renderSeparator={separator}
                            renderBodyComponent={!hascheckdata ? nodata : null} //无数据时显示
                            onEndReached={this.onEndReached2}
                            onEndReachedThreshold={10}
                            renderFooter={() => (
                                <p>{hascheckdata ? (this.state.hasMore2 && checklistdata.length >= 20 ? '加载中..., 请稍等' : '已经全部加载完毕') : ''}</p>
                            )}
                        />

                    </div>
                </Tabs>
                {this.state.isLarge ?
                    <div onClick={() => {
                        const large = this.state.isLarge;
                        this.setState({isLarge: !large})
                    }} className='img_view'>
                        <img src={this.state.imgurl}/>
                    </div> : ''
                }
                <div style={{height: 55}}></div>
                <BottomTab selectedTab='buka'/>
            </div>
        )
    }
}

export default WaiChuBackList

