import { connect } from 'react-redux'
import { getWaiChu,getWaiChuChecklist,deleteWaiChu} from './actions'
import Setting from './main'


const mapDispatchToProps = {
    getWaiChu,
    getWaiChuChecklist,
    deleteWaiChu
}
const mapStateToProps = (state) => {
  return {
    ...state.WaiChuList
  }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
