import React from 'react'
import PropTypes from 'prop-types'
import { NavBar, Icon, WhiteSpace, WingBlank, List, TextareaItem, Picker, Radio, Modal, Button, Toast, InputItem,Steps } from 'antd-mobile'
import { Link } from 'react-router-dom'
import { postRequest, getUserInfo } from '../../../utils/utils'
import 'antd-mobile/dist/antd-mobile.css'
import './index.css'
import moment from 'moment'


import daishenhe from '../../../images/icons/daishenhe.png'
import yitongguo from '../../../images/icons/yitongguo.png'
import shenpizhong from '../../../images/icons/shenpizhong.png'
import fushen from '../../../images/icons/fushen.png'
import yijujue from '../../../images/icons/yijujue.png'

const Item = List.Item;
const Step = Steps.Step;
const RadioItem = Radio.RadioItem;
class BukaDetail extends React.Component{
  state = {
      data:{}
  }

  componentDidMount = () => {
    this.getDetail()
  }
  getDetail = async() => {
    const waichu_id = localStorage.getItem('waichu_id');
    postRequest(`/out/view`, `id=${waichu_id}`).then((result) => {
      console.log(result)
      if(result && result.status === 1) {
        this.setState({data:result.data})
      } else {
          Toast.fail(result.msg || '获取失败', 1);
        if(result.msg === '请登陆！') {
          localStorage.removeItem('daka_token')
          alert('账号已在别处登录,请重新登录')
          this.props.history.push('/')
        }
      }
    })
  }

  
  render() {
      const { data } = this.state
    return (
      <div style={{backgroundColor:'#f5f5f7', minHeight:document.documentElement.clientHeight }}>
        <NavBar
        mode="dark"
        icon={<Icon type="left" />}
        onLeftClick={() => this.props.history.goBack() }
        >外出详情</NavBar>
          <div className='out_list'>
        <List className="my-list detail_list" renderHeader={
            <div className='flex_title'>
                <span>外出申请</span>
                {data.status ==1||data.status ==2 ?
                    <span style={{color: '#1296db'}}>已审核</span>:
                    <span style={{color: '#ff8e2b'}}>{data.next}</span>
                }
            </div>
        }>
          <Item multipleLine wrap extra={data.user_id}>申请人</Item>
          <Item multipleLine wrap extra={data.starttime}>外出时间</Item>
            <Item className='text_left' multipleLine wrap extra={data.content}>外出事由</Item>
            <Item multipleLine wrap extra={data.work==0?'是':'否'}>是否从公司出发</Item>
        </List>
              <Steps className='check_step' size="small" current={5}>
                  {data.list==null?'':
                      data.list.map(i=>(i.check_id==null?'':
                              <Step key={i.id}  status={i.status==2?'error':'process'}
                                    title=
                                        {<div className='check_user'>
                                            <span>{i.check_id}</span>
                                            <span className='check_time'>{i.reply_time}</span>
                                        </div>}
                                    description={<div><span className='check_status'>{i.status==2?'审核未通过':'审核通过'}</span>
                                        {i.content==''?'':<span>({i.content})</span>}</div>}
                              />
                      ))
                  }
              </Steps>
          </div>
          {
              data.back?
                  <div className='out_list'>
                  <List className="my-list detail_list" renderHeader={
                      <div className='flex_title'>
                          <span>不返回公司申请</span>
                          {data.back.status =='待审批' ?
                              <span style={{color: '#ff8e2b'}}>待{data.check_id}审核</span>:
                              <span style={{color: '#1296db'}}>已审核</span>
                          }
                      </div>
                  }>
                      <Item multipleLine wrap extra={data.back.content}>原由</Item>
                      <Item multipleLine wrap extra={data.back.status}>审批状态</Item>
                      <Item multipleLine wrap extra={data.back.back}>审批意见</Item>
                  </List>
                  </div>:''
          }


      </div>
    )
  }
}

export default BukaDetail