import React from 'react'
import PropTypes from 'prop-types'
import {Flex, WingBlank, WhiteSpace, InputItem, NavBar, List, Toast, Button} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import {postRequest, getUserInfo} from '../../utils/utils'

import './index.css'

const Item = List.Item;
const Brief = Item.Brief;

class EditPassword extends React.Component {
    static propTypes = {}

    state = {
        phone: '',
        newpassword: '',
        password2: '',
    };

    componentDidMount() {

    }

    onSubmit = () => {
        localStorage.setItem('type_password', 1)
        const {phone, newpassword, password2} = this.state;
        const edtype = this.props.location.state.type;
        let body = ''
        var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (phone) {
            if (!myreg.test(phone)) {
                Toast.info('手机号码格式不对', 1);
                return
            }
            else
                body += `phone=${phone}`
        } else {
            Toast.info('手机号码必填', 1);
            return
        }
        if (newpassword) {
            body += `&newpas=${newpassword}`
        } else {
            Toast.info('新密码必填', 1);
            return
        }
        if (password2) {
            body += `&okpas=${password2}`
        } else {
            Toast.info('确认新密码必填', 1);
            return
        }
        if (newpassword != password2) {
            Toast.info('两次输入的密码不一致', 1);
            return
        }
        console.log(body);
        if (edtype == 1) {
            postRequest(`/xiugai/mima`, body).then((value) => {
                //console.log(value);
                if (value.suc === 0) {
                    Toast.fail(value.msg, 1);
                    if (value.msg === '请登陆！') {
                        localStorage.removeItem('daka_token')
                        alert('账号已在别处登录,请重新登录')
                        this.props.history.push('/')
                    }
                } else {
                    if (value.status == 1) {
                        Toast.success(value.msg, 1);
                        localStorage.removeItem('daka_token')
                        this.props.history.push('/login')
                    }
                    else {
                        Toast.fail(value.msg, 1);
                    }
                }
            })
        }
        else {
            postRequest(`/xiugai/forget`, body).then((value) => {
                if (value.suc === 0) {
                    Toast.fail(value.msg, 1);
                    if (value.msg === '请登陆！') {
                        localStorage.removeItem('daka_token')
                        alert('账号已在别处登录,请重新登录')
                        this.props.history.push('/')
                    }
                } else {
                    if (value.status == 1) {
                        Toast.success(value.msg, 1);
                        localStorage.removeItem('daka_token')
                        this.props.history.push('/login')
                    }
                    else {
                        Toast.fail(value.msg, 1);
                    }
                }
            })
        }

    }

    render() {
        return (
            <div className='index-content'>
                <List className="my-list">
                    <InputItem
                        clear
                        placeholder="请输入手机号码"
                        value={this.state.phone}
                        onChange={value => this.setState({phone: value})}
                        ref={el => this.autoFocusInst = el}
                    >手机号码</InputItem>
                    <InputItem
                        clear
                        placeholder="请输入新密码"
                        value={this.state.newpassword}
                        onChange={value => this.setState({newpassword: value})}
                        ref={el => this.autoFocusInst = el}
                    >新密码</InputItem>
                    <InputItem
                        clear
                        placeholder="确认新密码"
                        value={this.state.password2}
                        onChange={value => this.setState({password2: value})}
                        ref={el => this.autoFocusInst = el}
                    >确认新密码</InputItem>
                </List>
                <WhiteSpace/>
                <WhiteSpace/>
                <WhiteSpace/>
                <WingBlank size="md">
                    <Button type='primary' onClick={() => this.onSubmit()}>提交</Button>
                </WingBlank>
            </div>
        )
    }
}

export default EditPassword
