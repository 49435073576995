import { postRequest, getUserInfo } from '../../../utils/utils';
import {Toast} from 'antd-mobile';
// ------------------------------------
// Constants
// ------------------------------------
export const JIABANYC_LIST = 'JIABANYC_LIST'

// ------------------------------------
// Actions
// ------------------------------------
export const getJiaBanYC = (pagesize,oldData,type, keyword) => {
    return async (dispatch, getState) => {
        postRequest(`/yanchang/getlist`, `keyword=${keyword || ''}&&page_size=${pagesize}`).then((value) => {
            //console.log(value);
            if(value.status === 0) {
                dispatch({ type: JIABANYC_LIST, payload:{hasdata: false , listdata:[],count:0} })
                if(value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            } else {
                if(value.data!='') {
                    let newlist=[];
                    newlist = value.data.list
                    if(oldData && oldData.length > 0) {
                        newlist = oldData.concat(value.data.list)
                    }
                    dispatch({type: JIABANYC_LIST, payload: {listdata: newlist,hasdata: true,count:value.data.count }})
                }
                else {
                    dispatch({ type: JIABANYC_LIST, payload:{hasdata: false , listdata:[],count:0} })
                }
            }
        })
    }
}

export const getJiaBanYCChecklist = (pagesize,oldData,type, keyword) => {
    return async (dispatch, getState) => {
        postRequest(`/yanchang/checklist`, `keyword=${keyword || ''}&&page_size=${pagesize}`).then((value) => {
            //console.log(value);
            if(value.status === 0) {
                dispatch({ type: JIABANYC_LIST, payload:{checklistdata:[],hascheckdata: false,count2:0 } })
                if(value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token');
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            } else {
                 if(value.data!=''){
                     let newlist=[];
                     newlist = value.data.data
                     if(oldData && oldData.length > 0) {
                         newlist = oldData.concat(value.data.data)
                     }
                     dispatch({ type: JIABANYC_LIST, payload:{checklistdata: newlist,hascheckdata: true,count2:value.data.count } })
                 }
                 else {
                     //alert(value.msg)
                     dispatch({ type: JIABANYC_LIST, payload:{checklistdata:[],hascheckdata: false,count2:0 } })
                 }
            }
        })
    }
}

export const deleteJiaBanYC = (body,history) => {
    return async (dispatch, getState) => {
        postRequest(`/yanchang/del`, body).then((value) => {
            //console.log(value);
            if(value.Status === 0) {
                Toast.info(value.msg, 1);
                if(value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            } else {
                Toast.success('删除成功！', 1);
                dispatch(getJiaBanYC());
            }
        })
    }
}

