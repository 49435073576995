import { postRequest } from '../../../utils/utils';
import {Toast} from 'antd-mobile';
// ------------------------------------
// Constants
// ------------------------------------
export const JIABANYC_EDITOR = 'JIABANYC_EDITOR'

// ------------------------------------
// Actions
// ------------------------------------
//获取审核人
export const getApper = () => {
    return async (dispatch, getState) => {
        postRequest('/jiaban/check_user').then((value) => {
            //console.log(value)
            if(value.suc === 0) {
                if(value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            } else {
                dispatch({ type:'JIABANYC_EDITOR', payload:{ apper: value.data } })
            }

        })
    }
}


export const saveJiaBanYC = (body, history) => {
    //console.log(body);
    return async (dispatch, getState) => {
        postRequest(`/yanchang/apply`, body).then((value) => {
          //console.log(value);
            if(value.status === 0) {
                Toast.fail(value.msg, 1);
                if(value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            } else {
                Toast.success(value.msg, 1,()=>{history.push('/JiabBanYanChang/list');});
            }
        })
    }
}


