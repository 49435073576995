import React from 'react'
import PropTypes from 'prop-types'
import {Flex, WingBlank, WhiteSpace, NavBar, Icon, Toast, ListView, List,Button} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {postRequest, getUserInfo} from '../../utils/utils'


import './index.css'

const Item = List.Item;
const Brief = Item.Brief;
const dataSource = new ListView.DataSource({  //这个dataSource有cloneWithRows方法
    rowHasChanged: (row1, row2) => row1 !== row2,
})

class QueKaList extends React.Component {
    static propTypes = {}

    state = {
        height: document.documentElement.clientHeight,
        dataSource,
        listdata: [],
        hasdata: true
    };

    componentDidMount() {
        this.getqueka();
    }

    getqueka = async () => {
        postRequest(`/amount/queka`, '').then((result) => {
            //console.log(result);
            if (result.status == 1) {
                this.setState({listdata: result.data.data});
                this.setState({hasdata: true});
            }
            else
            {
                this.setState({hasdata: false});
            }
        })
    }

    render() {
        const {listdata, hasdata} = this.state;
        const separator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    backgroundColor: '#F5F5F9',
                    height: 10,
                }}
            />
        );
        const nodata = () => {
            return (
                <div className='nodata'>
                </div>
            );
        }
        const row = (rowData, sectionID, rowID) => {
            const time = rowData == '' ? '' : rowData.split("|")[0]
            const type = rowData == '' ? '' : rowData.split("|")[1]
            return (
                <List className="my-list queka_list">
                    <Item multipleLine extra={
                        <Button onClick={()=>{
                            localStorage.setItem('buka_id','');
                            this.props.history.push({pathname:'/buka/editor'})}} type="primary" size="small" inline>补卡</Button>
                    }>
                        {time}<Brief>未打卡类型：{type}</Brief>
                    </Item>
                </List>
            );
        };
        return (
            <div className='index-content'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left"/>}
                    onLeftClick={() => this.props.history.goBack()}
                >缺卡记录</NavBar>

                <ListView
                    ref={el => this.lv = el}
                    dataSource={this.state.dataSource.cloneWithRows(listdata)}
                    renderRow={row}
                    style={{
                        height: this.state.height,
                        overflow: 'auto',
                    }}
                    renderSeparator={separator}
                    renderBodyComponent={!hasdata ? nodata : null} //无数据时显示
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}
                />
                <div style={{height: '53px'}}></div>

            </div>
        )
    }
}

export default QueKaList
