import { connect } from 'react-redux'
import { getApper, saveXuJia } from './actions'
import Setting from './main'


const mapDispatchToProps = {
    getApper,
    saveXuJia
}
const mapStateToProps = (state) => {
  return {
    ...state.XuJiaEditor
  }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
