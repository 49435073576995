import { postRequest } from '../../../utils/utils';
import {Toast} from 'antd-mobile';
// ------------------------------------
// Constants
// ------------------------------------
export const QINGJIA_EDITOR = 'QINGJIA_EDITOR'

// ------------------------------------
// Actions
// ------------------------------------

export const getApper = (type) => {
  console.log(type)
  return async (dispatch, getState) => {
    postRequest('/qingjia/agree',`type=${type}`).then((value) => {
      //console.log(value)
      if(value.suc === 0) {
          Toast.info(value.msg, 1);
        if(value.msg === '请登陆！') {
          localStorage.removeItem('daka_token')
          alert('账号已在别处登录,请重新登录')
          this.props.history.push('/')
        }
      } else {
        dispatch({ type:'QINGJIA_EDITOR', payload:{ apper: value.data } })
      }
      
    })
  }
}


export const postQingjiaEditor = (body, history) => {
  return async (dispatch, getState) => {
    postRequest(`/qingjia/apply`, body).then((value) => {
      console.log(value);
      if(value.suc === 0) {
          Toast.info(value.msg, 2);
        if(value.msg === '请登陆！') {
          localStorage.removeItem('daka_token')
          alert('账号已在别处登录,请重新登录')
          this.props.history.push('/')
        }
      } else {
          Toast.success(value.msg, 1,()=>{history.push('/qingjia/list');});
      }
    })
  }
}
