import React from 'react'
import PropTypes from 'prop-types'
import { List, WingBlank, NavBar, Icon, ImagePicker, Toast } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import fetch from 'cross-fetch'
import './index.css'
import moment from 'moment'
import { postRequest } from '../../../utils/utils.js'

const Item = List.Item
let timeInterval = null
var map, geolocation
class Setting extends React.Component {
  static propTypes = {
    getSetting: PropTypes.func.isRequired,
    data:PropTypes.object.isRequired
  }

  state = {
    data:{},
    files:[],
    code:0,
    position:{},
    address:''
  }

  componentDidMount() {


    const { AMap, } = window
    map = new AMap.Map('mapContainer', {
      resizeEnable: true,
      zoom: 12
    });

    map.plugin('AMap.Geolocation', () => {
        geolocation = new AMap.Geolocation({
            enableHighAccuracy: true,//是否使用高精度定位，默认:true
            //timeout: 10000,          //超过10秒后停止定位，默认：无穷大
            buttonOffset: new AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
            zoomToAccuracy: true,      //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
            buttonPosition:'RT'
        });
        map.addControl(geolocation);
        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, 'complete', (data) => {
          if(this.state.code === 0 ) {
            this.setState({position:data.position, address:data.formattedAddress})
          } else {
            this.handleSendDaka(data)
          }
        });//返回定位信息
        AMap.event.addListener(geolocation, 'error', (data) => {
          console.log(data);
        });      //返回定位出错信息
    });
  }



  onChange = (files, type, index) => {
    console.log(files, type, index);
    this.setState({
      files,
    });
    this.setState({code:1})
    Toast.loading('打卡中...', 30);
    geolocation.getCurrentPosition();
  }


  handleSendDaka = async(data) => {
    console.log(this.state.files[0].url)
    const body = `jingdu=${data.position.lng}&weidu=${data.position.lat}&address=${data.formattedAddress}&status=1&imgurl=`+this.state.files[0].url
    postRequest('/login/daka', body).then((result) => {
      console.log(result)
      if(result && result.suc === 1) {
        Toast.hide()
        alert('打卡成功')
      } else {
        if(result.msg === '请登陆！') {
          Toast.hide()
          localStorage.removeItem('daka_token')
          alert('账号已在别处登录,请重新登录')
          this.props.history.push('/')
        } else {
          Toast.hide()
          console.log(result)
          alert(result.msg)
        }
      }
    })
  }




  render() {
    const { data, files, position, address } = this.state
    return (
      <div className='index-content'>
        <NavBar
          mode="dark"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.props.history.goBack()
          } }
          >外出打卡</NavBar>
        <div id="mapContainer" style={{ width: '100vw', height: '100vh' }}>
          
        </div>
        <div style={{position:'absolute', bottom:0, left:0, background:'rgba(255, 255, 255, 0.2)', width:'100%', height:200  }}>
          <div style={{height:90}}>
            {
              address ? 
              <div style={{textAlign:'center'}}>
                <div style={{height:30}}><span style={{color:'#007aff', fontSize:10, lineHeight:2}}>经度: {position.lng || 0}</span></div>
                <div style={{height:30}}><span style={{color:'#007aff', fontSize:10, lineHeight:2}}>纬度: {position.lat || 0}</span></div>
                <div style={{height:30}}><span style={{color:'#007aff', fontSize:10, lineHeight:2}}>{address}</span></div>
              </div> :
              <div style={{textAlign:'center', height:90}}>
                <span style={{color:'#007aff', fontSize:14, lineHeight:7 }}>定位中...</span>
              </div> 
            }
          </div>
          
          
          <div style={{height:110}}>
            <div>
              {
                files && files.length > 0 || <span className='daka'>打卡</span>
              }
            </div>
            <ImagePicker
              files={files}
              length="1"
              onChange={this.onChange}
              capture='camera'
              onImageClick={(index, fs) => console.log(index, fs)}
              selectable={files.length < 1}
              multiple={false}
              style={{textAlign:'center'}}
              disableDelete
            />
          </div>
          
        </div>
        
      </div>
    )
  }
}


export default Setting
