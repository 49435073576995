import React from 'react'
import PropTypes from 'prop-types'
import { List, TextareaItem, Button, InputItem, NavBar, Checkbox, Icon, WhiteSpace, Picker, Toast, DatePicker,WingBlank } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import { Link } from 'react-router-dom'
import { createForm } from 'rc-form'
import { postRequest } from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import CustomerSearch from '../../components/customer_search'
import AmapSearch from '../../components/amapSearch'
import deltime from '../../../images/deletetime.png'
const Item = List.Item;

class JiaBanYCEditor extends React.Component {
  static propTypes = {
    form:PropTypes.object.isRequired,
    getApper:PropTypes.func.isRequired,
    apper:PropTypes.array.isRequired,
      saveJiaBanYC:PropTypes.func.isRequired,
  }

  state = {
    files:null,
    end_time:'',
      apper:[],
      check_username:'',
      content:'',
      buka_id:'',
      showMap:false,
      times:[],
  };
    componentWillMount(){
    const jiabanbuka_id = localStorage.getItem('jiabanyc_id');
    const jiaban_id = localStorage.getItem('jiaban_id');
    this.setState({buka_id:jiabanbuka_id});
    if(jiabanbuka_id!=='')//编辑获取数据
    {
        this.getDetail(jiabanbuka_id);
    }
    else { //新增时获取加班的数据
        this.getJiaBan(jiaban_id);
    }
}
  componentDidMount() {
    this.props.getApper();
  }
   //获取修改数据
    getDetail = async(buka_id) => {
        postRequest(`/yanchang/edit`, `id=${buka_id}`).then((result) => {
            //console.log(result)
            if(result && result.status === 1) {
                const shijian=result.data.shijian.split('|');
                const lists=[];
                for(var i=0; i<shijian.length;i++){
                    const startime=shijian[i].split(',')[0];
                    const endtime=new Date(shijian[i].split(',')[1]);
                    const EminDate=new Date(startime);
                    const EmaxDate=new Date(startime.split(' ')[0]+' 23:59');
                    lists.push({"id":i,"starttime": new Date(startime),"endtime":endtime,"EminDate":EminDate,"EmaxDate":EmaxDate});
                }
                this.setState({times:lists});
                this.setState({
                    content:result.data.content,
                    apper:[result.data.check_id],
                    check_username:result.data.check
                });
            } else {
                Toast.info(result.msg || '获取失败', 1);
                if(result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }
    //获取加班数据
    getJiaBan = async(buka_id) => {
        postRequest(`/yanchang/look`, `id=${buka_id}`).then((result) => {
            if(result && result.status === 1) {
                const shijian=result.data.shijian.split('|');
                const lists=[];
                for(var i=0; i<shijian.length;i++){
                    const startime=shijian[i].split(',')[0];
                    const endtime=new Date(shijian[i].split(',')[1]);
                    const EminDate=new Date(startime);
                    const EmaxDate=new Date(startime.split(' ')[0]+' 23:59');
                    lists.push({"id":i,"starttime": new Date(startime),"endtime":endtime,"EminDate":EminDate,"EmaxDate":EmaxDate});
                }

                this.setState({times:lists});
            }
        })
    }

  onSubmit = () => {
    const { validateFields } = this.props.form
    const { start_time,end_time, apper,content,times } = this.state
    const { files, history, saveJiaBanYC } = this.props
    const jiaban_id = localStorage.getItem('jiaban_id');
    validateFields((err, values) => {
      let body =`jb_id=${jiaban_id}`
        if(times[0].starttime==''&&times[0].endtime=='') {
            Toast.info('加班时间必填', 1);
            return
        } else {
            var shijian='';
            for(var i=0;i<times.length;i++){
                shijian+=`${moment(times[i].starttime).format('YYYY-MM-DD HH:mm')},${moment(times[i].endtime).format('YYYY-MM-DD HH:mm')}|`
            }
            body += `&shijian=${shijian.substring(0,shijian.length-1)}`
        }
      if(apper && apper[0]) {
        body += `&check_id=${apper[0]}`
      } else {
          Toast.info('审批人为必填项', 1);
        return
      }
        if(content) {
            body += `&content=${content}`
        } else {
            Toast.info('加班延长事由为必填项', 1);
            return
        }
      if (!err) {
        //Toast.loading('新建中, 请稍等....')
          if(this.state.buka_id!='')
          {
              body += `&id=${this.state.buka_id}`
          }
          //console.log(body);
          saveJiaBanYC(body, history)
       }
    });
  }
    //添加时间
    addtime=()=>{
        const lists=this.state.times;
        let minDate='';
        if(lists&&lists[0]){
            const last=lists[lists.length-1];
            if(last.endtime==''){ Toast.info('请填写上一组时间', 2);return}
            const starttime=moment(last.endtime).format('YYYY-MM-DD HH:mm');
            minDate=new Date(starttime);
        }
        lists.push({"id": lists.length,"SminDate":minDate});
        this.setState({times:lists})
    }
    //选择时间
    timeChange=(index, fileds,value)=>{
        const lists=this.state.times;
        lists[index][fileds]=value;
        if(fileds=="starttime"){
            const starttime=moment(value).format('YYYY-MM-DD HH:mm');
            lists[index]['EminDate']=new Date(starttime);
            lists[index]['EmaxDate']=new Date(starttime.split(' ')[0]+' 23:59');
        }
        this.setState({times: lists });
    }
    //删除时间
    delDate=(id)=>{
        const lists=this.state.times;
        lists.splice(id,1)
        this.setState({times:lists})
    }
  render() {
    const { getFieldProps } = this.props.form;
    const {  ctype, detail } = this.props;
    let apper = [];
      apper = this.props.apper.map((value, index) => {
      return {
        value:value.u_id,
        label:value.u_fullname
      }
    })
    return (
      <div className='index-content' id='wipeout_editor'>
      <NavBar
        mode="dark"
        icon={<Icon type="left" />}
        onLeftClick={() => this.props.history.goBack() }
        rightContent={
            <a onClick={() => {
                this.addtime()
            }} style={{color: '#fff', paddingTop: 3}}>
                追加时间
            </a>
        }
        >加班延长申请</NavBar>
          <List  className="picker-list my_list" renderHeader=''>
              {/*<DatePicker*/}
                  {/*mode="datetime"*/}
                  {/*title="选择延长时间"*/}
                  {/*extra="选择延长时间"*/}
                  {/*value={this.state.end_time}*/}
                  {/*onChange={date => {this.setState({ end_time:date });}}*/}
              {/*>*/}
                  {/*<List.Item arrow="horizontal">延长时间<span className="xing">*</span></List.Item>*/}
              {/*</DatePicker>*/}
              {this.state.times.map((item) =>
                  <Item key={item.id} index={item.id} className='time_item' extra={
                      <div className='times'>
                          <DatePicker
                              minDate={item.SminDate}
                              mode="datetime"
                              title="选择开始时间"
                              extra="选择开始时间"
                              value={item.starttime}
                              onChange={date =>{this.timeChange(item.id,'starttime',date)
                              }}>
                              <List.Item arrow="horizontal"></List.Item>
                          </DatePicker>
                          <DatePicker
                              minDate={item.EminDate}
                              maxDate={item.EmaxDate}
                              mode="datetime"
                              title="选择结束时间"
                              extra="选择结束时间"
                              value={item.endtime}
                              onChange={date => {this.timeChange(item.id,'endtime',date)}}>
                              <List.Item arrow="horizontal"></List.Item>
                          </DatePicker>
                      </div>
                  }>
                      <div className='time_title'>
                          <div>
                              <p>加班时间</p>
                              {/*<a onClick={()=>{this.delDate(item.id)}}> <img src={deltime}/></a>*/}
                          </div>
                          <div className='line'></div></div></Item>
              )}
            <CustomerSearch selectvalue={this.state.apper} initialValue={this.state.check_username} customers={apper} onOk={(v) => {
                {this.setState({ apper: v })}
            }}  name='审批人'/>
          </List>
        <List renderHeader='' className="qj-list">
          <TextareaItem
              value={this.state.content}
              onChange={value=>this.setState({content:value})}
              placeholder="请填写延长理由"
              data-seed="logId"
              rows={4}
              ref={el => this.customFocusInst = el}
          />
        </List>
          <WhiteSpace />
          <WhiteSpace />
          <WhiteSpace />
        <WingBlank size="md">
          <Button type='primary' onClick={() => this.onSubmit()}>提交</Button>
        </WingBlank>
      </div>
    )
  }
}

export default createForm()(JiaBanYCEditor)