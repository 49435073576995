import React from 'react'
import PropTypes from 'prop-types'
import { List, TextareaItem, Button, InputItem, NavBar, Checkbox, Icon, WhiteSpace, Picker, Toast, DatePicker,WingBlank,Steps } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import { Link } from 'react-router-dom'
import { createForm } from 'rc-form'
import { postRequest } from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import CustomerSearch from '../../components/customer_search'
const Item = List.Item;

const Step = Steps.Step;
class JiabanCheck extends React.Component {
  static propTypes = {
    form:PropTypes.object.isRequired,
      checkJiaban:PropTypes.func.isRequired
  }

  state = {
      jiaban_id:'',
      data:{},
      option:'',
      showbtn:true,
  };

  componentDidMount() {
    this.getDetail();
  }
    getDetail = async() => {
        const jiaban_id = localStorage.getItem('jiaban_id');
        this.setState({jiaban_id:jiaban_id});
        postRequest(`/jiaban/view_overtime`, `id=${jiaban_id}`).then((result) => {
            console.log(result)
            if(result && result.suc === 1) {
                this.setState({data:result.data})
            } else {
                Toast.fail(result.msg || '获取失败', 1);
                if(result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }


    agree = (status) => {

        const {history, checkJiaban } = this.props
        let body =`id=${this.state.jiaban_id}&status=${status}&beizhu=${this.state.option}`
        if(status==2){
            if(this.state.option) {

            } else {
                Toast.info('审核不通过时，必须填写理由', 1);
                this.setState({showbtn:true})
                return
            }
        }        //console.log(body);
        checkJiaban(body, history)
  }

    render() {
        const { data,showbtn } = this.state;
        const user = JSON.parse(localStorage.getItem('daka_user'))
        const u_id = user.u_id;
        //console.log(data);
        return (
            <div style={{backgroundColor:'#f5f5f7', minHeight:document.documentElement.clientHeight }}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack() }
                >加班审核</NavBar>
                <List className="my-list jb_list detail_list" renderHeader=''>
                    <Item multipleLine wrap extra={<span
                        dangerouslySetInnerHTML={{__html: data.shijian}}></span>}>加班时间</Item>
                    <Item multipleLine wrap extra={data.xiaoshi}>加班时长</Item>
                    <Item multipleLine wrap extra={data.u_fullname}>申请人</Item>
                    <Item multipleLine wrap extra={data.yuangong}>加班人员</Item>
                    {/*<Item multipleLine wrap extra={data.status}>审批状态</Item>*/}
                    <Item multipleLine wrap extra={data.shiyou}>申请事由</Item>

                </List>
                { data.status == 1 ?'':
                <List renderHeader='' className="qj-list detail_list">
                    <TextareaItem
                        value={this.state.option}
                        onChange={value=>this.setState({option:value})}
                        placeholder="请填写审批意见"
                        data-seed="logId"
                        rows={4}
                        ref={el => this.customFocusInst = el}
                    />
                </List>}
                { data.check==''?'':
                <List renderHeader={() => '审核意见'} className="my-list">
                    <Steps className='check_step' size="small" current={5}>
                        {
                            data.check==null?'':
                            data.check.map(i=>(i.agree_id==null?'':
                                    <Step key={i.id}  status={i.status==2?'error':'process'}
                                          title=
                                              {<div className='check_user'>
                                                  <span>{i.agree_id}</span>
                                                  <span className='check_time'>{i.infotime}</span>
                                              </div>}
                                          description={<div><span className='check_status'>{i.status==1||i.status==3?'审核通过':'审核未通过'}</span>
                                              {i.beizhu==''?'':<span>({i.beizhu})</span>}</div>}
                                    />
                            ))}
                    </Steps>
                </List>
                }
                <div style={{height:'40px'}}></div>
                { data.status == 1||data.status == 2 ?'':
                    !showbtn?'':
                    data.status == 3&&data.agree_id!=u_id ?
                    <div className='bottom_btn'>
                        <div className='bottom_right' onClick={() => {this.setState({showbtn:false});this.agree(1)}}>登记</div>
                    </div>
                    :
                        data.status == 0?
                    <div className='bottom_btn'>
                        <div className='bottom_left' onClick={() => {this.setState({showbtn:false});this.agree(2)}}>不同意</div>
                        <div className='bottom_right' onClick={() => {this.setState({showbtn:false});this.agree(1)}}>同意</div>
                    </div>:''
                }
            </div>
        )
    }
}

export default createForm()(JiabanCheck)