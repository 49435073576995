import { postRequest, getUserInfo } from '../../../utils/utils';
import {Toast} from 'antd-mobile';
// ------------------------------------
// Constants
// ------------------------------------
export const QINGJIAFILE_LIST = 'QINGJIAFILE_LIST'

export const getQingJiaFileList = (pagesize,oldData,type, keyword) => {
    return async (dispatch, getState) => {
        postRequest(`/qingjiabucailiao/getlist`, `keyword=${keyword || ''}&&page_size=${pagesize}`).then((value) => {
            //console.log(value);
            if(value.status === 0) {
                dispatch({type: QINGJIAFILE_LIST, payload: {listdata: [],hasdata: false,count:0 }})
                if(value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/login')
                }
            } else {
                if(value.data!='') {
                    let newlist=[];
                    newlist = value.data.list
                    if(oldData && oldData.length > 0) {
                        newlist = oldData.concat(value.data.list)
                    }
                    dispatch({type: QINGJIAFILE_LIST, payload: {listdata: newlist,hasdata: true,count:value.data.count }})
                }
                else {
                    dispatch({ type: QINGJIAFILE_LIST, payload:{hasdata: false , listdata:[],count:0} })
                }
            }
        })
    }
}
export const getQingJiaFileChecklist = (pagesize,oldData,type, keyword) => {
    return async (dispatch, getState) => {
        postRequest(`/qingjiabucailiao/checklist`, `keyword=${keyword || ''}&&page_size=${pagesize}`).then((value) => {
            console.log(value);
            if(value.status === 0) {
                dispatch({ type: QINGJIAFILE_LIST, payload:{checklistdata: [],hascheckdata: false,count2:0 } })
                if(value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token');
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            } else {
                 if(value.data!=''){
                     let newlist=[];
                     newlist = value.data.list
                     if(oldData && oldData.length > 0) {
                         newlist = oldData.concat(value.data.list)
                     }
                     dispatch({ type: QINGJIAFILE_LIST, payload:{checklistdata: newlist,hascheckdata: true,count2:value.data.count } })
                 }
                 else {
                     //alert(value.msg)
                     dispatch({ type: QINGJIAFILE_LIST, payload:{checklistdata:[],hascheckdata: false,count2:0 } })
                 }
            }
        })
    }
}

