import React from 'react'
import PropTypes from 'prop-types'
import { Flex, WingBlank, WhiteSpace, NavBar, List, Toast, Button, Icon, Tabs, ImagePicker } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import { Link } from 'react-router-dom'
import BottomTab from '../components/bottomTab'

import Work from '../../images/work.png'
import offwork from '../../images/offwork.png'
import Person from '../../images/person.png'
import address from '../../images/address.png'
import { postRequest } from '../../utils/utils.js'

import './index.css'

const Item = List.Item;
const Brief = Item.Brief;
var map, geolocation
const BMap = window.BMap;

class tempDaka extends React.Component {
    static propTypes = {}

    state = {
        selectedTab: 'index',
        clheight: '',
        data: [],
        files: [],
        address: '',
        jingdu: '',
        weidu: '',
        btnstate1: true,
        btnstate2: true,
        amtime: '',
        pmtime: '',
        offworkTime: '17:00',
        juli: 0, //家到公司的距离
        isdaka: false
    };

    componentDidMount() {
        this.getbaiduGPS()
    }
    //百度定位
    getbaiduGPS = () => {
        const that = this;
        var map = new BMap.Map("mapContainer");
        Toast.loading('定位中..., 请稍后', 30);
        const BMapGeolocation = new BMap.Geolocation();
        BMapGeolocation.getCurrentPosition((r) => {
            console.log(r)
            var point = new BMap.Point(r.point.lng, r.point.lat);  // 创建点坐标  
            map.centerAndZoom(point, 15);
            var marker = new BMap.Marker(point);  // 创建标注
            map.addOverlay(marker);              // 将标注添加到地图中
            map.panTo(point);
            const address = r.address.province + r.address.city + r.address.district + r.address.street + r.address.street_number
            this.setState(
                {
                    jingdu: r.point.lng,
                    weidu: r.point.lat,
                    address: address,
                    province: r.address.province,
                    city: r.address.city,
                    qu: r.address.district
                })
            this.CheckDaka()
            Toast.hide()

        }, function (error) {
            Toast.fail('未获取到定位信息，请重新定位', 6);
        }, {
            enableHighAccuracy: true,//是否要求高精度的地理位置信息
            timeout: 10000,//对地理位置信息的获取操作做超时限制，如果再该事件内未获取到地理位置信息，将返回错误
            maximumAge: 0//设置缓存有效时间，在该时间段内，获取的地理位置信息还是设置此时间段之前的那次获得的信息，超过这段时间缓存的位置信息会被废弃
        });
    }

    onChange = (files, type, index) => {
        this.setState({
            files,
        });
        this.handleSendDaka();
    }
    //打卡
    handleSendDaka = async () => {
        console.log(this.state.juli)
        Toast.loading('打卡中..., 请稍后', 60);
        let body = `jingdu=${this.state.jingdu}&weidu=${this.state.weidu}&address=${this.state.address}&juli=${this.state.juli}`
        let imgurl;
        if (this.state.files[0] != 'undefined' && this.state.files[0] != null) {
            imgurl = this.state.files[0].url;
        }
        if (imgurl != '' && imgurl != 'undefined' && imgurl != null) {
            body += `&imgurl=${imgurl}`;
        }
        //console.log(body);
        postRequest('/home/daka', body).then((result) => {
            console.log(result);
            if (result && result.status === 1) {
                console.log('cc')
                Toast.hide();
                Toast.success('打卡成功', 2);
            } else {
                Toast.hide()
                if (result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    Toast.info('账号已在别处登录,请重新登录', 2);
                    this.props.history.push('/')
                } else {
                    Toast.info(result.msg || '不在公司范围内', 2);
                }
            }
        })
    }
    //检查是否打卡
    CheckDaka = () => {
        const { jingdu, weidu } = this.state
        postRequest(`/home/check`, ` jingdu=${jingdu}&weidu=${weidu}`).then((result) => {
            if (result.status == 0) {
                this.setState({ isdaka: true, address: result.data.address })
            }
            else this.setState({ isdaka: false, address: result.data.address })
        })
    }
    //重新定位
    getLocation = async () => {
        geolocation.getCurrentPosition();
    }

    render() {
        const { data, files } = this.state
        const daka_user = JSON.parse(localStorage.getItem('daka_user'))
        console.log(daka_user)
        return (
            <div className='index-content'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack()}
                >临时打卡</NavBar>
                <div className="contentBox">
                    {/*头部*/}
                    <List className="my-list">
                        <Item className="top_list" align="top" thumb={
                            daka_user.u_avatar == null ? Person : `http://jd.stycie.com/Upload/${daka_user.u_avatar}`}
                            multipleLine>
                            <div>
                                {daka_user.u_fullname}
                                <Brief>部门：{daka_user.depart}</Brief>
                            </div>

                        </Item>
                    </List>
                    {/*头部*/}
                    <div className='address_content'>
                        <div id="mapContainer"></div>
                    </div>
                    <List className="my-list address">
                        <Item>
                            <div className='site_title'>
                                <img src={address} />
                                <span>我的位置</span>
                                <span onClick={this.getbaiduGPS} style={{ color: '#3182D2' }}>（重新定位）</span>
                            </div>
                            <Brief>{this.state.address}</Brief>
                        </Item>
                    </List>
                    <WhiteSpace />
                    <div>
                        <div className="daka_bottom">
                            {daka_user.u_id == 327 ?
                                <div>
                                    {files && files.length > 0 ?
                                        <ImagePicker className='homeimg'
                                            files={files}
                                            length="1"
                                            selectable={files.length < 1}
                                            multiple={false}
                                            disableDelete
                                        /> :
                                        <span className='daka-btn' onClick={() => {
                                            console.log('aa')
                                            var myDate = new Date();
                                            var hours = myDate.getHours();
                                            var min = myDate.getMinutes();
                                            if (this.state.isdaka) {
                                                document.getElementById('tempdk').getElementsByTagName('input')[0].click()
                                            }
                                            else Toast.info('今日已经打过卡了！', 3);
                                        }}>家里打卡1</span>}
                                </div>
                                :
                                <div>
                                    {files && files.length > 0 ?
                                        <ImagePicker className='homeimg'
                                            files={files}
                                            length="1"
                                            capture='camera'
                                            selectable={files.length < 1}
                                            multiple={false}
                                            disableDelete
                                        /> :
                                        <span className='daka-btn' onClick={() => {
                                            console.log('aa')
                                            var myDate = new Date();
                                            var hours = myDate.getHours();
                                            var min = myDate.getMinutes();
                                            if (this.state.isdaka) {
                                                if ((hours > 21 || (hours == 21 && min >= 0)))
                                                    document.getElementById('tempdk').getElementsByTagName('input')[0].click()
                                                else { console.log('bbb'); Toast.info('打卡时间为21：00点以后！', 3); }
                                            }
                                            else Toast.info('今日已经打过卡了！', 3);
                                        }}>家里打卡</span>}
                                </div>
                            }
                            <WhiteSpace />
                        </div>
                        {daka_user.u_id == 327 ?
                            <div style={{ display: 'none' }} id='tempdk'>
                                <ImagePicker className='homeimg'
                                    files={files}
                                    length="1"
                                    onChange={
                                        (files, type, index) => {
                                            this.onChange(files, type, index)
                                        }
                                    }
                                    onImageClick={(index, fs) => console.log(index, fs)}
                                    selectable={files.length < 1}
                                    multiple={false}
                                    style={{ display: 'none' }}
                                    disableDelete
                                />
                            </div>
                            :
                            <div style={{ display: 'none' }} id='tempdk'>
                                <ImagePicker className='homeimg'
                                    files={files}
                                    length="1"
                                    onChange={
                                        (files, type, index) => {
                                            this.onChange(files, type, index)
                                        }
                                    }
                                    capture='camera'
                                    onImageClick={(index, fs) => console.log(index, fs)}
                                    selectable={files.length < 1}
                                    multiple={false}
                                    style={{ display: 'none' }}
                                    disableDelete
                                />
                            </div>}

                        <div style={{ height: '53px' }}></div>
                    </div>


                </div>
            </div>
        )
    }
}

export default tempDaka
