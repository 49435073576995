import { connect } from 'react-redux'
import { getQingJiaFileList,getQingJiaFileChecklist} from './actions'
import Setting from './main'


const mapDispatchToProps = {
    getQingJiaFileList,
    getQingJiaFileChecklist
}
const mapStateToProps = (state) => {
  return {
    ...state.QingJiaFileList
  }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
