import { connect } from 'react-redux'
import { getJiaBanMeals,getJiaBanMealsChecklist,deleteJiaBanMeals} from './actions'
import Setting from './main'


const mapDispatchToProps = {
    getJiaBanMeals,
    getJiaBanMealsChecklist,
    deleteJiaBanMeals
}
const mapStateToProps = (state) => {
  return {
    ...state.JiaBanMealsList
  }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
