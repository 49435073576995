import React from 'react'
import PropTypes from 'prop-types'
import {Flex, WingBlank, WhiteSpace, NavBar, List, Icon, Card} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {postRequest, getUserInfo} from '../../utils/utils'
import BottomTab from '../components/bottomTab'


import './index.css'

const Item = List.Item;
const Brief = Item.Brief;

class HelpIndex extends React.Component {
    static propTypes = {}

    state = {

    };

    componentDidMount() {

    }

    render() {
        return (
            <div className='index-content'>
                <NavBar
                mode="dark"
                icon={<Icon type="left" />}
                onLeftClick={() => this.props.history.goBack() }
                >帮助手册</NavBar>
                <Card className='help_sc'>
                    <Card.Header
                        title="一，打卡"
                    />
                    <Card.Body>
                        <p>打卡分为：上下班打卡，市内外出打卡，出差打卡。</p>
                        <p><span>1，</span>上下班打卡在指定考勤时间内打卡视为正常，否则视为迟到或早退</p>
                        <p><span>2，</span>打卡时需先核对“我的位置”，位置不在公司范围内不能打卡</p>
                        <p><span>3，</span>非考勤时间内打卡，系统会提示，请谨慎选择</p>
                        <p><span>4，</span>打卡时需要公司范围内拍照打卡</p>
                        <p><span>5，</span>正常上下班请选择上下班打卡，出差和市内外出选择对应的打卡功能</p>
                        <p><span>6，</span>出差打卡打卡时需要拍摄车票和出差现场</p>
                        <p><span>7，</span>打卡结束，会在右上角“打卡记录”中查到</p>
                    </Card.Body>
                    <Card.Header
                        title="二，补卡"
                    />
                    <Card.Body>
                        <p>因某些原因未打卡的用户可在补卡界面进行补卡，用户提交补卡申请后需要主管审批—门卫调监控审核—孙部长审核，全部审核通过视为补卡成功。
                            此时会将信息同步到打卡记录里
                        </p>
                        <p><span>1，</span>登录系统我的—未打卡记录，显示当月未打卡日期</p>
                        <p><span>2，</span>进入打卡界面会统计前7天内未打卡的记录，并弹框提醒，此时需员工自行登录系统在补卡界面进行补卡</p>
                    </Card.Body>
                    <Card.Header
                        title="三，外出"
                    />
                    <Card.Body>
                        <p>需要外出在市内办公的，需要在首页—外出界面提交申请。用户提交外出申请后需要主管审批—门卫审批。全部审批通过视为有效外出。
                            因某些原因不能及时提交申请的可在事后补充申请
                        </p>
                        <p><span>1，</span>外出时，在规定考勤时间内不能返回到公司，此时打卡应该为“市内外出”打卡</p>
                    </Card.Body>
                    <Card.Header
                        title="四，请假"
                    />
                    <Card.Body>
                        <p><span>1，</span>请假时间小于3天时，由主管审批通过即可
                        </p>
                        <p><span>2，</span>请假时间大于3天时，由主管审批—耿总审批—孙部长审批，全部审批通过视为同意</p>
                        <p><span>3，</span>请假时间大于3天，返回公司时需要在孙部长那里销假</p>
                        <p><span>4，</span>部门主管请假时，不管是否超过3天，均由何总审批</p>
                    </Card.Body>
                    <Card.Header
                        title="五，加班"
                    />
                    <Card.Body>
                        <p><span>1，</span>加班只有部门主管有权利发起申请，填写部门下的加班人员
                        </p>
                        <p><span>2，</span>加班开始时，需要点击申请列表中的“开始加班”按钮，此时开始计算加班时长</p>
                        <p><span>3，</span>加班结束时，需要点击申请列表中的“结束加班”按钮，计算出时间加班时长</p>
                    </Card.Body>
                    <Card.Header
                        title="六，出勤"
                    />
                    <Card.Body>
                        <p>记录每月考勤情况
                        </p>
                        <p><span>1，</span>所有打卡记录均在此处显示</p>
                        <p><span>2，</span>补卡审批通过的会同步到出勤记录里</p>
                    </Card.Body>
                    <Card.Header
                        title="七，统计"
                    />
                    <Card.Body>
                        <p>按月统计当月考勤，请假、加班、缺卡情况
                        </p>
                        <p><span>1，</span>每条统计记录点击进去可查看详情</p>
                    </Card.Body>
                </Card>

            </div>
        )
    }
}

export default HelpIndex
