import React from 'react'
import PropTypes from 'prop-types'
import {
    List,
    TextareaItem,
    Button,
    InputItem,
    NavBar,
    Checkbox,
    Icon,
    WhiteSpace,
    Picker,
    Toast,
    DatePicker,
    WingBlank,
    ImagePicker
} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import {createForm} from 'rc-form'
import {postRequest} from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import CustomerSearch from '../../components/customer_search'

const Item = List.Item;
// const types = [
//     {
//         label: '事假',
//         value: 1,
//     },
//     {
//         label: '病假',
//         value: 2,
//     },
//     {
//         label: '婚假',
//         value: 3,
//     },
//     {
//         label: '丧假',
//         value: 4,
//     },
//     {
//         label: '产假',
//         value: 5,
//     },
//     {
//         label: '工伤',
//         value: 7,
//     },
//     {
//         label: '特批出勤',
//         value: 8,
//     }
// ]
class QingjiaEditor extends React.Component {
    static propTypes = {
        form: PropTypes.object.isRequired,
        getApper: PropTypes.func.isRequired,
        apper: PropTypes.array.isRequired,
        postQingjiaEditor: PropTypes.func.isRequired,
    }

    state = {
        data: [],
        start_time: '',
        end_time: '',
        apper: [],
        qingjiaid: '',
        shichang: '',
        shiyou: '',
        check_username: '',
        type: [],
        files: [],
        files2: [],
        showimg: false,
        types: [],//请假类型
    };

    componentWillMount() {
        this.getType()
        const qingjia_id = localStorage.getItem('qingjia_id');
        this.setState({qingjiaid: qingjia_id});
        if (qingjia_id !== '')//判断是编辑还是新增
        {
            this.getDetail(qingjia_id);
        }
    }

    componentDidMount() {
        this.props.getApper()
    }

    //获取请假类型
    getType = () => {
        postRequest(`/qingjia/qj_type`).then((result) => {
            if (result) {
                if (result && result.status === 1){
                    this.setState({types:result.data})
                }
                else  Toast.fail(result.msg || '获取失败', 1);
            }
            else  Toast.fail( '获取请假类型接口错误，请联系信息开发部', 2);
        })
    }
    getDetail = async (qingjia_id) => {
        postRequest(`/qingjia/view`, `id=${qingjia_id}`).then((result) => {
            console.log(result)
            if (result && result.suc === 1) {
                let imgurl = result.data.imgurl;
                const list = [];
                if (imgurl != null)
                    list.push({"id": result.data.id, "url": imgurl});
                this.setState({start_time: new Date(result.data.starttime)});
                this.setState({end_time: new Date(result.data.endtime)});
                this.setState({shichang: result.data.xiaoshi});
                this.setState({apper: [result.data.agree_id]});
                this.setState({check_username: result.data.agree_name});
                this.setState({shiyou: result.data.shiyou});
                this.setState({files: list});
                this.setState({type: [result.data.qj_type]});
                this.setState({guanxi: result.data.guanxi})
            } else {
                Toast.fail(result.msg || '获取失败', 1);
                if (result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }
//上传材料
    onChange = (files, type, index) => {
        //console.log(files, type, index);
        this.setState({
            files,
        });
    }
    onChange2 = (files, type, index) => {
        //console.log(files, type, index);
        this.setState({
            files2: files,
        });
    }
    onSegChange = (e) => {
        const index = e.nativeEvent.selectedSegmentIndex;
        this.setState({
            multiple: index === 1,
        });
    }

    onSubmit = () => {
        const {validateFields} = this.props.form
        const {start_time, end_time, apper, shiyou, type} = this.state
        const {files, history, postQingjiaEditor} = this.props

        validateFields((err, values) => {
            let body = ''
            if (start_time) {
                body += `starttime=${moment(start_time).format('YYYY-MM-DD HH:mm')}`
            } else {
                Toast.info('开始时间为必填项', 1);
                return
            }

            if (end_time) {
                body += `&endtime=${moment(end_time).format('YYYY-MM-DD HH:mm')}`
            } else {
                Toast.info('结束时间为必填项', 1);
                return
            }
            if (type && type[0]) {
                body += `&type=${type[0]}`
                if (type[0] == 4) { //丧假需要亲属关系
                    if (values.guanxi) {
                        body += `&guanxi=${values.guanxi}`;
                    }
                    else {
                        Toast.info('亲属关系为必填项', 1);
                        return
                    }
                }
            }
            else {
                Toast.info('请假类型为必填项', 1);
                return
            }
            if (this.state.files[0] != 'undefined' && this.state.files[0] != null) {
                body += `&imgurl=${this.state.files[0].url}`;
            }
            if (this.state.files2[0] != 'undefined' && this.state.files2[0] != null) {
                body += `&tpurl=${this.state.files2[0].url}`;
            }
            if (shiyou) {
                body += `&shiyou=${shiyou}`
            } else {
                Toast.info('请假事由为必填项', 1);
                return
            }

            if (apper && apper[0]) {
                body += `&agree_id=${apper[0]}`
            } else {
                Toast.info('审批人为必填项', 1);
                return
            }

            if (!err) {
                if (this.state.qingjiaid != '') {
                    body += `&id=${this.state.qingjiaid}`
                }
                console.log(body);
                Toast.loading('新建中, 请稍等....')
                postQingjiaEditor(body, history)
            }
        });
    }


    render() {
        const {getFieldProps} = this.props.form
        const {ctype, detail} = this.props;
        const {files, type, files2} = this.state;
        let apper = []
        apper = this.props.apper.map((value, index) => {
            return {
                value: value.u_id,
                label: value.u_fullname
            }
        })
        return (
            <div className='index-content' id='wipeout_editor'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left"/>}
                    onLeftClick={() => this.props.history.goBack()}
                >请假申请</NavBar>
                <List className="picker-list my_list" renderHeader=''>
                    <DatePicker
                        mode="datetime"
                        title="选择开始时间"
                        extra="选择开始时间"
                        value={this.state.start_time}
                        onChange={date => {
                            this.setState({start_time: date})
                            const newdata=new Date(date)
                           const enddata= newdata.setHours(newdata.getHours() + 1);
                            if(type[0] == 9)
                                this.setState({end_time:new Date(enddata)})
                        }}
                    >
                        <List.Item arrow="horizontal">开始时间<span className="xing">*</span></List.Item>
                    </DatePicker>
                    <DatePicker
                        disabled={type[0] == 9}
                        mode="datetime"
                        title="选择结束时间"
                        extra="选择结束时间"
                        value={this.state.end_time}
                        onChange={date => this.setState({end_time: date})}
                    >
                        <List.Item arrow="horizontal">结束时间<span className="xing">*</span></List.Item>
                    </DatePicker>
                    <Picker
                        data={this.state.types}
                        extra="请假类型"
                        value={this.state.type}
                        cols={1}
                        onChange={v => {
                            this.setState({type: v});
                             //清空审批人
                             this.setState({apper: null});
                             this.setState({checkername:''})
                            if(v[0] == 9&&this.state.start_time){
                                const newdata=new Date(this.state.start_time)
                                const enddata= newdata.setHours(newdata.getHours() + 1);
                                this.setState({end_time:new Date(enddata)})
                            }
                            //特批考勤，切换审批人
                            if(v[0]==8){
                                this.props.getApper(v[0])
                            }
                            else{
                                this.props.getApper()
                            }
                        }}
                    >
                        <List.Item arrow="horizontal">请假类型<span className="xing">*</span></List.Item>
                    </Picker>
                    {type.length == 0 ? '' :
                        (type[0] == 1||type[0] == 9) ? '' :
                            <div>
                                <div className='upload_title'>证明材料</div>
                                <ImagePicker className='upload_img'
                                             files={files}
                                             multiple={false}
                                             length={1}
                                             onChange={this.onChange}
                                             onImageClick={(index, fs) => console.log(index, fs)}
                                             selectable={files.length < 1}
                                />
                            </div>
                    }
                    {
                        type[0] == 8 &&
                        <div>
                            <div className='upload_title'>核检结果</div>
                            <ImagePicker className='upload_img'
                                         files={files2}
                                         multiple={false}
                                         length={1}
                                         onChange={this.onChange2}
                                         onImageClick={(index, fs) => console.log(index, fs)}
                                         selectable={files2.length < 1}
                            />
                        </div>
                    }
                    {type.length == 0 ? '' :
                        type[0] == 4 ?
                            <InputItem
                                {...getFieldProps('guanxi', {
                                    initialValue: this.state.guanxi
                                })}
                                placeholder="请填写与去世人亲属关系"
                                clear
                            >亲属关系<span className="xing">*</span></InputItem> : ''
                    }
                    <CustomerSearch selectvalue={this.state.apper} name={this.state.checkername||''} initialValue={this.state.check_username}
                                    customers={apper} 
                        onOk={(v,name) => {
                             this.setState({apper: v})
                             this.setState({checkername:name})
                        }} />
                </List>
                <List renderHeader='' className="qj-list">
                    <TextareaItem
                        {...getFieldProps('shiyou', {
                            rules: [{require: true}],
                        })}
                        value={this.state.shiyou}
                        onChange={value => {
                            this.setState({shiyou: value})
                        }}
                        title={<div><span>事由</span><span className="xing">*</span></div>}
                        placeholder="请填写事由"
                        data-seed="logId"
                        rows={4}
                        ref={el => this.customFocusInst = el}
                    />
                </List>
                <WhiteSpace/>
                <WhiteSpace/>
                <WhiteSpace/>
                <WingBlank size="md">
                    <Button type='primary' onClick={() => this.onSubmit()}>提交</Button>
                </WingBlank>
                <div style={{height: 15}}></div>
                <div className='daka_rule'>
                    <span>注意事项</span>
                    <p>
                        1）请假必须提前申请，不能后期补申请<br/>
                        2）请假时长不足一个小时按一个小时算，超过一个小时，不足一个半小时算一个半小时，超过一个半小时，不足两个小时按两个小时计算（以此类推）<br/>
                        3）除事假外，其他均需要提供证明材料，且由人力部审批<br/>
                        4）证明材料审批未通过或请假结束前未补充的，则自动转为事假<br/>
                        5）请假时长大于3天的，返回公司时需到人力部销假，不办理销假人员将按双倍事假处理<br/>
                        6）请假超过15天的人员，必须在人力部办理销假手续后才能再次发起请假申请<br/>
                        7）请假生效后，需在打卡界面进行请假打卡，未及时打卡者，造成请假时长延长，由个人承担<br/>
                        8）如果是防疫隔离，请上传隔离证明和核酸检测结果。申请时若无相关材料，可在后期补充材料<br/>
                    </p>
                </div>
            </div>
        )
    }
}

export default createForm()(QingjiaEditor)