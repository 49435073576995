import React from 'react'
import PropTypes from 'prop-types'
import { NavBar, Icon, WhiteSpace, WingBlank, List, TextareaItem, Picker, Radio, Modal, Button, Toast, InputItem,Steps } from 'antd-mobile'
import { Link } from 'react-router-dom'
import { postRequest, getUserInfo } from '../../../utils/utils'
import 'antd-mobile/dist/antd-mobile.css'
import './index.css'
import moment from 'moment'

const Item = List.Item;
const Step = Steps.Step;
const RadioItem = Radio.RadioItem;
class XuJiaDetail extends React.Component{
  state = {
      data:{}
  }

    componentWillMount = () => {
    this.getDetail()
  }
  getDetail = () => {
    const qingjia_id = localStorage.getItem('qingjia_id');
    postRequest(`/qingjia/jindu`, `id=${qingjia_id}`).then((result) => {
      console.log(result)
      if(result && result.status === 1) {
        this.setState({data:result.data})
      } else {
          Toast.fail(result.msg || '获取失败', 1);
        if(result.msg === '请登陆！') {
          localStorage.removeItem('daka_token')
          alert('账号已在别处登录,请重新登录')
          this.props.history.push('/')
        }
      }
    })
  }

  
  render() {
      const { data } = this.state
    return (
      <div style={{backgroundColor:'#f5f5f7', minHeight:document.documentElement.clientHeight }}>
        <NavBar
        mode="dark"
        icon={<Icon type="left" />}
        onLeftClick={() => this.props.history.goBack() }
        >续假审批进度</NavBar>
          {data.length>0?
              data.map((item) =>
              <div className='xujiabox' key={item.id}>
                  <List className="my-list detail_list" renderHeader=''>
                      <Item multipleLine wrap extra={item.starttime}>开始日期</Item>
                      <Item multipleLine wrap extra={item.endtime}>原始结束日期</Item>
                      <Item multipleLine wrap extra={item.jieshutime}>续假日期</Item>
                      <Item multipleLine wrap extra={item.content}>续假原由</Item>
                    <Item multipleLine wrap extra={item.status==1?'已通过':item.status==2?'未通过':item.status==4?'待登记':item.status==5?'待销假':'待审核'}>审核状态</Item>
                      {item.status==1||item.status==2?'':
                          <Item multipleLine wrap extra={item.check_name}>下级审核人</Item>
                      }

                  </List>
                  <List renderHeader={() => '审核意见'} className="my-list">
                      <Steps className='check_step' size="small" current={5}>
                          {item.record==null?'':
                              item.record.map(i=>(i.check_id==null?'':
                                      <Step key={i.id}  status={i.status==2?'error':'process'}
                                            title=
                                                {<div className='check_user'>
                                                    <span>{i.check_id}</span>
                                                    <span className='check_time'>{i.reply_time}</span>
                                                </div>}
                                            description={<div><span className='check_status'>{i.status==2?'审核未通过':'审核通过'}</span>
                                                {i.content==''?'':<span>({i.content})</span>}</div>}
                                      />
                              ))
                          }
                      </Steps>
                  </List>
              </div>
              )
              :''}


      </div>
    )
  }
}

export default XuJiaDetail