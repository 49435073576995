import React from 'react'
import PropTypes from 'prop-types'//检测props数据类型
import { List, InputItem, Button, WingBlank, WhiteSpace } from 'antd-mobile';
import { createForm } from 'rc-form'
import logo from '../../images/logo.jpg'
import {Link} from 'react-router-dom'
import './main.css'
import 'antd-mobile/dist/antd-mobile.css'
import { postRequest } from '../../utils/utils.js'
import phonepng from '../../images/icons/phone.png'
import pwdpng from '../../images/icons/pwd.png'


class LoginForm extends React.Component {
    static propTypes = {
        form:PropTypes.object,
    }

    state = {
        isCode:1,
        count: 300,//验证码倒计时
        issend:false,//是否请求验证码
    }

    onLogin = () => {
        this.props.form.validateFields((err, value) => {
            if(err) {
                alert('用户名或验证码不能为空')
            } else {
                this.handleLogin(value)
            }
        })
    }

    handleLogin = async(value) => {
        let body = {}
        if(this.state.isCode) {
            body = `username=${value.phone}&code=${value.code}&iscode=1`
        } else {
            body = `username=${value.username}&password=${value.password}`
        }
        postRequest('/denglu/login', body).then((users) => {
            console.log(users);
            if(users && users.suc === 1) {
                const token = users.data ? users.data.u_token_2 : ''
                if(token) {
                    localStorage.setItem('daka_token', token)//存储数据
                    localStorage.setItem('daka_user', JSON.stringify(users.data))
                }
                this.props.history.push({pathname:'/shouye', user:users.data})//跳转页面
            } else {
                alert(users.msg || '登录失败')
            }
        })
    }

    //发送验证码
    handleSend = () => {
        this.setState({issend:true})
        this.props.form.validateFields((err, value) => {
            if (err) {
                alert('手机号不能为空')
                this.setState({issend:false})
            } else {
                const body = `phone=${value.phone}`
                postRequest('/denglu/sendcode', body).then((result) => {
                    if (result && result.status === 1) {
                        let count=this.state.count
                        const timer = setInterval(() => {
                            this.setState({count:(count--)},()=>{
                                if(count===0){
                                    clearInterval(timer);
                                    this.setState({count:300,issend:false})
                                }
                            })
                        }, 1000);
                    } else {
                        this.setState({issend:false})
                        alert(result ? result.msg : '手机号格式有误或未注册')
                    }
                })
            }
        })
    }




    render() {
        const { getFieldProps } = this.props.form;
        return (
            <div id='login'>
              <header className='header'>
                <img src={logo} alt='logo' width='100' style={{ margin:'auto' }} />
                <p className='p-1'>九鼎昊天</p>
                <p className='p-2'>考勤打卡系统</p>
              </header>

              <WingBlank>
                  {
                      !this.state.isCode ?
                          <List>
                            <InputItem
                                {...getFieldProps('username',{
                                    rules:[{required:true}]
                                })}
                                clear
                                placeholder="请输入账号"
                                ref={el => this.autoFocusInst = el}
                            >账号</InputItem>
                            <InputItem
                                {...getFieldProps('password',{
                                    rules:[{required:true}]
                                })}
                                clear
                                type='password'
                                placeholder="请输入密码"
                                ref={el => this.inputRef = el}
                            >密码</InputItem>
                          </List> :
                          <List>
                            <InputItem className='login_ico_title'
                                       {...getFieldProps('phone',{
                                           rules:[{required:true}]
                                       })}
                                       clear
                                       placeholder="请输入手机号"
                                       ref={el => this.autoFocusInst = el}
                            >
                              <img src={phonepng} width='30' style={{margin: 'auto'}}/>
                            </InputItem>
                            <InputItem className='login_ico_title'
                                       extra={<Button disabled={this.state.issend}
                                                      onClick={() => this.handleSend()} type="primary" inline
                                                      size="small">
                                           {!this.state.issend?'获取验证码':`重新获取（${this.state.count}S）`}
                                       </Button>}
                                       {...getFieldProps('code')}
                                       clear
                                       placeholder="请输入验证码"
                                       ref={el => this.inputRef = el}
                            >
                              <img src={pwdpng} width='30' style={{margin: 'auto'}}/>
                            </InputItem>

                          </List>
                  }

                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <Button type='primary' onClick={() => this.onLogin()}>
                  登录
                </Button>
                <WhiteSpace />
                <div style={{textAlign: 'center'}}>
                  注：验证码将发送到企业微信
                </div>
                  {/*<a onClick={() => {*/}
                  {/*localStorage.setItem('type_password', 0)//存储数据*/}
                  {/*this.props.history.push({pathname:'/editpassword',state:{type:0}})*/}
                  {/*}} className='los_password'>忘记密码？</a>*/}
              </WingBlank>



            </div>
        )
    }
}

export default createForm()(LoginForm)