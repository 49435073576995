import React from 'react'
import PropTypes from 'prop-types'
import {
    NavBar,
    Icon,
    WhiteSpace,
    WingBlank,
    List,
    TextareaItem,
    Picker,
    Radio,
    Modal,
    Button,
    Toast,
    InputItem,
    Steps
} from 'antd-mobile'
import {Link} from 'react-router-dom'
import {postRequest, getUserInfo} from '../../../utils/utils'
import 'antd-mobile/dist/antd-mobile.css'
import './index.css'
import moment from 'moment'


import daishenhe from '../../../images/icons/daishenhe.png'
import yitongguo from '../../../images/icons/yitongguo.png'
import shenpizhong from '../../../images/icons/shenpizhong.png'
import fushen from '../../../images/icons/fushen.png'
import yijujue from '../../../images/icons/yijujue.png'

const Item = List.Item
const Step = Steps.Step;
const RadioItem = Radio.RadioItem;

class QingjiaFileDetail extends React.Component {
    static propTypes = {
    }
    state = {
        data: {
            info: {},
            flow: {},
            ctype: {},
            nextApper: []
        },
        state: '1',
        apper: '',
        opinion: '',
        show: false,
        paytotal: ''
    }

    componentDidMount = () => {
        this.getDetail()
    }
    getDetail = async () => {
        const qingjiafile_id = localStorage.getItem('qingjiafile_id')

        ;
        console.log(qingjiafile_id);
        postRequest(`/qingjiabucailiao/view`, `id=${qingjiafile_id}`).then((result) => {
            console.log(result)
            if (result && result.status === 1) {
                this.setState({data: result.data})
            } else {
                Toast.fail(result.msg || '获取失败', 1);
                if (result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }
    //审批
    agree = async (status) => {
        const qingjia_id = localStorage.getItem('qingjia_id');
        let body = `id=${qingjia_id}&beizhu=${this.state.opinion}&status=${status}`
        postRequest(`/qingjia/checksave`, body).then((result) => {
            console.log(result)
            if (result && result.suc == 1) {
                Toast.success('审批成功', 1);
                this.setState({show: false})
                Toast.hide()
                this.props.history.goBack()
            } else {
                Toast.fail(result.msg || '审批失败', 1);
            }
        })
    }

    render() {
        const {data} = this.state
        const daka_user = JSON.parse(localStorage.getItem('daka_user'))
        const uId = daka_user.u_id

        const status = [
            {value: '1', label: '通过'},
            {value: '2', label: '拒绝'},
        ];

        const time = new Date(Number(data.info && data.info.addtime + '000'))
        const date = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()} ${time.getHours()}:${time.getMinutes()}`

        let timeLine = []
        if (data.approverShow && data.approverShow.length > 0) {
            timeLine = data.approverShow.map((value, index) => {
                let addTime = ''
                let addDate = ''
                if (value.notes && value.notes.addtime) {
                    addTime = new Date(Number(value.notes.addtime + '000'))
                    addDate = moment(addTime).format('YYYY-MM-DD hh:ss')
                }
                return (
                    <div className="vertical-timeline-block" key={index}>
                        <div className="vertical-timeline-icon">
                            <img src={value.avatar} alt='avatar' width='40'/>
                        </div>
                        <div className="vertical-timeline-content">
                            {
                                value.notes &&
                                <span style={{color: '#23c6c8'}}>{value.notes.state == '1' ? '通过' : '不通过'}</span>
                            }
                            <span>{value.fullname}</span>
                            <br/>
                            {
                                !value.notes && <span style={{color: '#1ab394', fontSize: 11}}>进行中...</span>
                            }
                            {
                                value.notes && <small>{addDate}</small>
                            }
                            {
                                value.notes &&
                                <div className="well"><span className="text-navy">{value.notes.opinion}</span></div>
                            }
                        </div>
                    </div>
                )
            })
        }


        return (
            <div style={{backgroundColor: '#f5f5f7', minHeight: document.documentElement.clientHeight}}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left"/>}
                    onLeftClick={() => this.props.history.goBack()}
                >请假证明材料详情</NavBar>
                <div className='img_box'>
                    <p>证明材料</p>
                    {
                        data.imgurl &&
                        <img onClick={() => {
                            const large = this.state.isLarge;
                            this.setState({isLarge: !large});
                            this.setState({imgurl: data.imgurl})
                        }} src={data.imgurl} alt=""/>
                    }
                </div>
                <List renderHeader={() => '审核意见'} className="my-list detail_list">
                    <Steps className='check_step' size="small" current={5}>
                        {data.record == null ? '' :
                            data.record.map(i => (i.check_id == null ? '' :
                                    <Step key={i.id} status={i.status == '未通过' ?  'error' : 'process'}
                                          title=
                                              {<div className='check_user'>
                                                  <span>{i.check_id}</span>
                                                  <span className='check_time'>{i.infotime}</span>
                                              </div>}
                                          description={<div><span className='check_status'>{i.status==1?'审核通过':'审核未通过'}</span>
                                              {i.content == '' ? '' : <span>({i.content})</span>}</div>}
                                    />
                            ))
                        }
                    </Steps>
                </List>

                <div style={{height:'50px'}}></div>
                {this.state.isLarge ?
                    <div onClick={() => {
                        const large = this.state.isLarge;
                        this.setState({isLarge: !large})
                    }} className='img_view'>
                        <img src={this.state.imgurl}/>
                    </div> : ''
                }
            </div>
        )
    }
}

export default QingjiaFileDetail