import { connect } from 'react-redux'
import { getJiaban, getOverTime ,deleteJiaban} from './actions'
import Setting from './main'


const mapDispatchToProps = {
  getJiaban,
  getOverTime,
    deleteJiaban
}
const mapStateToProps = (state) => {
  return {
    ...state.JiabanList
  }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
