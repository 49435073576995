import React from 'react'
import PropTypes from 'prop-types'
import {
    ListView,
    WhiteSpace,
    NavBar,
    Icon,
    SearchBar,
    Card,
    Button,
    SegmentedControl,
    Checkbox,
    Tabs
} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import BottomTab from '../../components/bottomTab'
import './index.css'

import {createForm} from 'rc-form'

//图片引入

import beginimg from '../../../images/begin.png'
import endimg from '../../../images/end.png'

const CheckboxItem = Checkbox.CheckboxItem
const dataSource = new ListView.DataSource({  //这个dataSource有cloneWithRows方法
    rowHasChanged: (row1, row2) => row1 !== row2,
})
const tabs2 = [
    {title: '申请列表', sub: '1'},
    {title: '审批列表', sub: '2'}
];

class QingjiaList extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        getQingjia: PropTypes.func.isRequired,
        ctype: PropTypes.object.isRequired,
        approvals: PropTypes.array.isRequired,
        getOverTime: PropTypes.func.isRequired,
        data: PropTypes.array.isRequired,
    }

    state = {
        dataSource,
        height: document.documentElement.clientHeight-70,
        check_money: [],
        tabindex: 0,  //tab选项卡索引判断是申请还是审核列表(0:申请列表；1：审核列表)
        hasMore: true,
        page_size: 1,  //页码
        page_size2: 1, //审核列表页码
        hasMore2: true  //审核列表是否分页
    }

    componentWillMount() {
        const index = this.props.location.state
        if (index == undefined) {
            this.setState({tabindex: 0})
        }
        else {
            this.setState({tabindex: 1})
        }
    }

    componentDidMount() {
        const index = this.props.location.state
        if (index == undefined) {
            this.props.getQingjia()
        }
        else {
            this.props.getOverTime()
        }
    }

    //删除请假
    delete = async (qingjiaid) => {
        //this.setState({buka_id:bukaid});
        const {history, deleteQingjia} = this.props
        let body = `id=${qingjiaid}`
        deleteQingjia(body, history);
    }

    onEndReached1 = () => {
        const {page_size} = this.state
        const pagesize = Math.ceil(this.props.count / 20) //页数
        let p = page_size;
        if (!this.state.hasMore) {
            return
        }
        else {
            if (p >= pagesize) {
                this.setState({hasMore: false})
            }
            else {
                this.setState({hasMore: true})
                p = page_size + 1;
                this.setState({page_size: p})
                const oldData = this.props.qjlistdata;
                this.props.getQingjia(p, oldData)
            }
        }
    }
    onEndReached2 = () => {
        const {page_size2, hasMore2} = this.state
        const pagesize = Math.ceil(this.props.count2 / 20) //页数
        let p = page_size2;
        if (!hasMore2) {
            return
        }
        else {
            if (p >= pagesize) {
                this.setState({hasMore2: false})
            }
            else {
                this.setState({hasMore2: true})
                p = page_size2 + 1;
                this.setState({page_size2: p})
                const oldData = this.props.qjcheckData;
                this.props.getOverTime(p, oldData)
            }
        }
    }

    render() {
        const {qjlistdata, qjcheckData, noqj, noqjcheck, ctype} = this.props

        const user = JSON.parse(localStorage.getItem('daka_user'))
        //console.log(user)
        const uid = user.uid
        const separator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    backgroundColor: '#F5F5F9',
                    height: 10,
                }}
            />
        );
        const nodata = () => {
            return (
                <div className='nodata'>
                </div>
            );
        }
        const row = (rowData, sectionID, rowID) => {
//console.log(rowData)
            const time = new Date(Number(rowData.addtime + '000'))
            const date = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()} ${time.getHours()}:${time.getMinutes()}`
            let stateSpan = ""
            if (rowData.status === 0||rowData.status === 3) {
                stateSpan = <span style={{fontSize: 16, color: '#FF8E2B'}}>待{rowData.check_name}审批</span>
            }

            if (rowData.status === 1) {
                stateSpan = <span style={{fontSize: 16, color: '#1296db'}}>已通过</span>
            }
            if (rowData.status === 1 && rowData.xiaoshi >= 22.5) {
                stateSpan = <span style={{fontSize: 16, color: '#1296db'}}>已销假</span>
            }
            if (rowData.status === 2) {
                stateSpan = <span style={{fontSize: 16, color: '#ff4d4f'}}>未通过</span>
            }
            if (rowData.status === 4) {
                stateSpan = <span style={{fontSize: 16, color: '#FF8E2B'}}>待{rowData.check_name}登记</span>
            }
            if (rowData.status === 5) {
                stateSpan = <span style={{fontSize: 16, color: '#FF8E2B'}}>待{rowData.check_name}销假</span>
            }if (rowData.status === 6) {
                stateSpan = <span style={{fontSize: 16, color: '#1296db'}}>已撤销</span>
            }
            return (
                <Card>
                    <Card.Header
                        extra={stateSpan}
                        title={`申请人: ${rowData.user_id}`}
                        style={{
                            fontSize: 15
                        }}
                    />
                    <Card.Body style={{padding: 10, paddingLeft: 15}}>
                        <div onClick={() => {
                            localStorage.setItem('qingjia_id', rowData.id);
                            this.state.tabindex == 0 ?
                                this.props.history.push({pathname: '/qingjia/detail'}) :
                                this.props.history.push({pathname: '/qingjia/check'})
                        }} style={{flex: 1}}>
                            <div className='card-body-list qj_list_item'>
                                <img src={beginimg} />
                                <p className='list_time'>{rowData.starttime}</p>
                                <p className='list_border_item'><span>{rowData.type}</span></p>

                                </div>
                            <div className='card-body-list qj_list_item'>
                                <img src={endimg} />
                                <p className='list_time'>{rowData.endtime}</p>
                                {/*<p className='list_count'>{rowData.xiaoshi} 小时</p>*/}
                                </div>
                            {/*<div className='card-body-list'>{`请假时长： ${rowData.xiaoshi} 小时`}</div>*/}
                            {/*<div className='card-body-list'>{`请假类型： ${rowData.type}`}</div>*/}
                            {/*<div className='card-body-list'>{`请假事由： ${rowData.shiyou}`}</div>*/}
                            {/*{this.state.tabindex == 1?'':*/}
                                {/*<div className='card-body-list'>{`是否续假： ${rowData.xujia==false?'否':'是'}`}</div>*/}
                            {/*}*/}
                            {/*{rowData.cx == 2||rowData.cx == 3||rowData.cx == 4?*/}
                                {/*<div className='card-body-list'>{`撤销状态： ${rowData.cx==2?`${rowData.agree_id}审批中`:rowData.cx==3?'已撤销':'撤销审核未通过'}`}</div>*/}
                                {/*:''*/}
                            {/*}*/}
                            {/*{rowData.js == 2||rowData.js == 3||rowData.js == 4?*/}
                                {/*<div className='card-body-list'>{`提前销假状态： ${rowData.js==3?'销假申请通过':rowData.js==4?'销假未通过':rowData.tq_check+'审批中'}`}</div>*/}
                                {/*:''*/}
                            {/*}*/}
                            {/*{this.state.tabindex ==0&&rowData.check_name!=''&&rowData.status!=1 ? //审核通过后不显示待审核人*/}
                                {/*<div className='card-body-list'>{`待审核人： ${rowData.check_name}`}</div>:''*/}
                            {/*}*/}
                        </div>

                        { this.state.tabindex == 1?'':
                            rowData.bc==0?'':
                                (rowData.bc==1||rowData.bc==2)?
                            <div className='card-body-list'>
                                <a style={{color:'#1296db','text-decoration':'underline'}} type='primary' onClick={() => {
                                    localStorage.setItem('qingjia_id', rowData.id);
                                    localStorage.setItem('qingjia_type', rowData.type);
                                    this.props.history.push({pathname: '/qingjiafile/editor'})
                                }} size="small">上传证明材料</a>
                            </div>
                            :
                                <div className='card-body-list'>
                                    <a style={{color:'#1296db','text-decoration':'underline'}} type='primary' onClick={() => {
                                        localStorage.setItem('qingjiafile_id', rowData.bc_id);
                                        this.props.history.push({pathname: '/qingjiafile/detail'})
                                    }} size="small">证明材料审批进度（人力部审批）</a>
                                </div>
                        }
                        {/*{ this.state.tabindex == 1?'':*/}
                            {/*rowData.xujia==false?'':*/}
                                {/*<div className='card-body-list'>*/}
                                    {/*<a style={{color:'#1296db','text-decoration':'underline'}} type='primary' onClick={() => {*/}
                                        {/*localStorage.setItem('qingjia_id', rowData.id);*/}
                                        {/*this.props.history.push({pathname: '/xujia/detail'})*/}
                                    {/*}} size="small">查看续假审批进度</a>*/}
                                {/*</div>*/}
                        {/*}*/}
                    </Card.Body>
                    {
                        rowData.status === 0 && this.state.tabindex == 0 ?
                            <Card.Footer className='list_footer' content={<div onClick={() => {
                                localStorage.setItem('qingjia_id', rowData.id);
                                this.props.history.push({pathname: '/qingjia/editor', qingjia_id: rowData.id})
                            }}>编辑</div>}
                                         extra={<div onClick={() => {
                                             this.delete(rowData.id)
                                         }}>删除</div>}>
                            </Card.Footer> : ''
                    }
                    {
                        (rowData.status === 1||rowData.status === 5)&&(rowData.cx==1||rowData.xj == 1||rowData.js == 1) && this.state.tabindex == 0 ?
                            <Card.Footer className='list_footer normalflex'
                                         extra={
                                             <div className='cardfooterflex'>
                                                 {rowData.cx==1?   //续假状态下不能撤销
                                                     <div className='opbtn_list qj_list_btn' onClick={() => {
                                                         localStorage.setItem('qingjia_id', rowData.id);
                                                         this.props.history.push({pathname: '/qingjiadel/editor', qingjia_id: rowData.id})
                                                     }}>撤销</div>:''}
                                                 {rowData.xj == 1?
                                                     <div className='opbtn_list qj_list_btn'>
                                                     {
                                                         <a type='primary' onClick={() => {
                                                             localStorage.setItem('qingjia_id', rowData.id);
                                                             this.props.history.push({pathname: '/xujia/editor'})
                                                         }} size="small">续假</a>
                                                     }

                                                 </div>:''}
                                                 {rowData.js == 1?
                                                     <div className='opbtn_list qj_list_btn'>
                                                         {
                                                             <a type='primary' onClick={() => {
                                                                 localStorage.setItem('qingjia_id', rowData.id);
                                                                 this.props.history.push({pathname: '/xiaojia/editor'})
                                                             }} size="small">提前销假</a>
                                                         }

                                                     </div>:''
                                                 }

                                             </div>
                                         }
                            >

                            </Card.Footer> : ''
                    }

                </Card>
            );
        };
        return (
            <div id='wipeout-list' style={{backgroundColor: '#f5f5f9'}}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left"/>}
                    onLeftClick={() => this.props.history.goBack()}
                    rightContent={
                        <Link to='/qingjia/editor' onClick={() => {
                            localStorage.setItem('qingjia_id', '');
                        }} style={{color: '#fff', paddingTop: 3}}><Icon key="0" type="plus" size='xs'/></Link>
                    }
                >请假列表</NavBar>
                <Tabs swipeable={false} tabs={tabs2}
                      initialPage={this.state.tabindex}
                      onTabClick={(tab, index) => {
                          //    // this.setState({selectedIndex:index})
                          if (index === 0) {
                              this.setState({tabindex: 0})
                              this.props.getQingjia()
                          }
                          if (index === 1) {
                              this.setState({tabindex: 1})
                              this.props.getOverTime()
                          }
                      }}
                >
                    <div>
                        <ListView
                            ref={el => this.lv = el}
                            dataSource={this.state.dataSource.cloneWithRows(qjlistdata)}
                            renderRow={row}
                            style={{
                                height: this.state.height,
                                overflow: 'auto',
                            }}
                            renderSeparator={separator}
                            renderBodyComponent={!noqj ? nodata : null} //无数据时显示
                            onEndReached={this.onEndReached1}
                            onEndReachedThreshold={20}
                            renderFooter={() => (
                                <p>{noqj ? (this.state.hasMore && qjlistdata.length >= 20 ? '加载中..., 请稍等' : '已经全部加载完毕') : ''}</p>
                            )}
                        />

                    </div>
                    <div>
                        <ListView
                            ref={el => this.lv = el}
                            dataSource={this.state.dataSource.cloneWithRows(qjcheckData)}
                            renderRow={row}
                            style={{
                                height: this.state.height,
                                overflow: 'auto',
                            }}
                            renderSeparator={separator}
                            renderBodyComponent={!noqjcheck ? nodata : null} //无数据时显示
                            onEndReached={this.onEndReached2}
                            onEndReachedThreshold={20}
                            renderFooter={() => (
                                <p>{noqjcheck ? (this.state.hasMore2 && qjcheckData.length >= 20 ? '加载中..., 请稍等' : '已经全部加载完毕') : ''}</p>
                            )}
                        />

                    </div>
                </Tabs>
                {/*<SegmentedControl values={['申请列表', '审批列表']} selectedIndex={this.state.selectedIndex} onChange = {(e) => {*/}
                {/*this.setState({selectedIndex:e.nativeEvent.selectedSegmentIndex})*/}
                {/*if(e.nativeEvent.selectedSegmentIndex === 0){*/}
                {/*this.props.getQingjia()*/}
                {/*}*/}
                {/*if(e.nativeEvent.selectedSegmentIndex === 1){*/}
                {/*this.props.getOverTime()*/}
                {/*} */}
                {/*}}/>*/}
                <WhiteSpace/>
                <div style={{height: 55}}></div>
                <BottomTab selectedTab='buka'/>
            </div>
        )
    }
}

export default QingjiaList

