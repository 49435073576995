import { postRequest, getUserInfo } from '../../../utils/utils';
import {Toast} from 'antd-mobile';
// ------------------------------------
// Constants
// ------------------------------------
export const XIAOJIA_LIST = 'XIAOJIA_LIST'

// ------------------------------------
// Actions
// ------------------------------------
export const getXiaoJia = (pagesize,oldData,type, keyword) => {
    return async (dispatch, getState) => {
        postRequest(`/qingjiatiqian/getlist`, `keyword=${keyword || ''}&&page_size=${pagesize}`).then((value) => {
            //console.log(value);
            if(value.status === 0) {
                dispatch({type: XIAOJIA_LIST, payload: {listdata: [],hasdata: false,count:0 }})
                if(value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/login')
                }
            } else {
                if(value.data!='') {
                    let newlist=[];
                    newlist = value.data.list
                    if(oldData && oldData.length > 0) {
                        newlist = oldData.concat(value.data.list)
                    }
                    dispatch({type: XIAOJIA_LIST, payload: {listdata: newlist,hasdata: true,count:value.data.count }})
                }
                else {
                    dispatch({ type: XIAOJIA_LIST, payload:{hasdata: false , listdata:[],count:0} })
                }
            }
        })
    }
}

export const getXiaoJiaChecklist = (pagesize,oldData,type, keyword) => {
    return async (dispatch, getState) => {
        postRequest(`/qingjiatiqian/checklist`, `keyword=${keyword || ''}&&page_size=${pagesize}`).then((value) => {
            //console.log(value);
            if(value.suc === 0) {
                localStorage.removeItem('daka_token');
                alert('账号已在别处登录,请重新登录')
                this.props.history.push('/')
            } else {
                 if(value.status==1){
                     let newlist=[];
                     newlist = value.data.list
                     if(oldData && oldData.length > 0) {
                         newlist = oldData.concat(value.data.list)
                     }
                     dispatch({ type: XIAOJIA_LIST, payload:{checklistdata: newlist,hascheckdata: true,count2:value.data.count } })
                 }
                 else {
                     //alert(value.msg)
                     dispatch({ type: XIAOJIA_LIST, payload:{checklistdata:[],hascheckdata: false,count2:0 } })
                 }
            }
        })
    }
}

export const deleteXiaoJia = (body,history) => {
    return async (dispatch, getState) => {
        postRequest(`/qingjiatiqian/del`, body).then((value) => {
            //console.log(value);
            if(value.status === 0) {
                Toast.info(value.msg, 1);
                if(value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            } else {
                Toast.success('删除成功！', 1);
                dispatch(getXiaoJia());
            }
        })
    }
}

