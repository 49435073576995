import { postRequest } from '../../../utils/utils';
import {Toast} from 'antd-mobile';
// ------------------------------------
// Constants
// ------------------------------------
export const XIAOJIA_CHECK = 'XIAOJIA_CHECK'

// ------------------------------------
// Actions
// ------------------------------------



export const checkXiaoJia = (body, history) => {
    return async (dispatch, getState) => {
        postRequest(`/qingjiatiqian/checksave`, body).then((value) => {
         // console.log(value);
            if(value.status === 0) {
                Toast.info(value.msg, 1);
                if(value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            } else {
                localStorage.setItem('selectIndex',1);
                history.push('/xiaojia/list');
                Toast.success(value.msg, 1);
            }
        })
    }
}


