import React from 'react'
import PropTypes from 'prop-types'
import {Flex, WingBlank, Button, NavBar, List, Toast, Icon, ListView, DatePicker, Picker} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'

import Work from '../../images/work.png'
import Evection from '../../images/evection.png'
import {postRequest} from '../../utils/utils.js'
import moment from 'moment'

import './index.css'

import {nodataico} from '../../images/ico2.png'

const dataSource = new ListView.DataSource({  //这个dataSource有cloneWithRows方法
    rowHasChanged: (row1, row2) => row1 !== row2,
})
const Item = List.Item

class Index extends React.Component {
    static propTypes = {}

    state = {
        dataSource,
        data: [],
        count: 0,
        shijian: moment().format('YYYY-MM'),
        isLoading: true,
        hasMore: true,
        height: document.documentElement.clientHeight-70,
        page_size: 1,  //页码
        status: '',   //打卡类型1：外勤；2：内部打卡
        hasdata: true,  //是否有数据
        isLarge: false,  //是否放大
        imgurl: '' //放大图片地址
    };

    componentDidMount() {
        this.handleSendGuiji();
    }


    handleSendGuiji = async (shijian, status, page_size, oldData) => {
        Toast.loading('加载中，请稍后...', 60, () => {
            Toast.fail('加载失败，请点击右上角刷新重试', 6);
        });
        //console.log(`shijian=${shijian || this.state.shijian}&status=${status}&page_size=${page_size || this.state.page_size}`)
        postRequest('/login/recordlist', `shijian=${shijian || this.state.shijian}&status=${status}&page_size=${page_size || this.state.page_size}`).then((result) => {
            console.log(result);
            if (result) {
                if(result.suc == 1)
                {
                    Toast.hide()
                    this.setState({hasdata: true})
                    const data = result.data ? result.data.shuju : [];
                    const count = result.data ? result.data.count : 0;
                    if (oldData != null)
                        this.setState({data: oldData.concat(data)});
                    else
                        this.setState({data: data});
                    if (data.length < 20 || count == 20) { //小于20或等于20则不分页
                        this.setState({hasMore: false});
                    }
                    else {
                        this.setState({hasMore: true});
                    }
                    Toast.info(result.msg || '已加载完成', 3,null,false);
                }
               else {
                    this.setState({data: []})
                    this.setState({hasdata: false})
                    Toast.info(result.msg || '暂无数据', 3,null,false);
                    if(result.msg.indexOf('请登陆')!=-1){
                        localStorage.removeItem('daka_token')
                        this.props.history.push('/')
                    }
                }
                // const data=result.data ? result.data.shuju : [];
                // const count=result.data ? result.data.count : 0;
                // const num=Math.ceil(count/20)//获取页数
                //
                // this.setState({hasdata: true})
                // if(data.length < 20){  //小于20条则不会分页
                //     this.state.hasMore = false;
                //    this.setState({page_size:0 });
                // }
                // console.log('pagesize '+page_size);
                // if(page_size>1)  //出现分页，则将新数据追加到之前的数组中，这样才能上滑查看上一页
                // {
                //     const oldlist=this.state.data;
                //     this.setState({data:oldlist.concat(data) , count:count });
                //     if(page_size<num)//当前页小于页数则继续分页，都在停止分页
                //     {
                //         const pagesize=this.state.page_size
                //         this.setState({page_size:pagesize+1 });
                //     }
                //     else {
                //         this.setState({page_size:0 });
                //     }
                // }
                // else
                //     this.setState({data:data , count: count})
            } else {
                Toast.info('接口错误，请刷新再试', 3);
            }
        })
    }

    onEndReached = () => {
        const {page_size} = this.state
        if (!this.state.hasMore) {
            return
        }
        const p = page_size + 1;
        this.setState({page_size:p})
        const oldData = this.state.data;
        this.handleSendGuiji('', '', p, oldData)
    }

    render() {
        const {data, count, shijian, status, page_size} = this.state
        //console.log(page_size);
         //console.log(this.state.hasdata);
        const header = () => (
            <div>
                <div style={{display: 'flex'}}>
                    <div style={{flex: 1}}>
                        <DatePicker
                            mode="month"
                            title="选择打卡时间"
                            extra="Optional"
                            value={this.state.date}
                            onChange={date => {
                                this.setState({shijian: moment(date).format('YYYY-MM'), date});
                                this.handleSendGuiji(moment(date).format('YYYY-MM'), status, page_size)
                            }}
                        >
                            <Button style={{borderRadius: 0, borderColor: '#fff'}}>{shijian}<span
                                style={{fontSize: 10}}> ▼</span></Button>
                        </DatePicker>
                    </div>
                    <div style={{flex: 1}}>
                        <Picker data={[
                            {
                                label: '上下班打卡',
                                value: '2',
                            },
                            {
                                label: '出差打卡',
                                value: '1',
                            },
                            {
                                label: '市内外出打卡',
                                value: '3',
                            },
                            {
                                label: '请假打卡',
                                value: '4',
                            },
                            {
                                label: '离襄打卡',
                                value: '5',
                            },
                            {
                                label: '返襄打卡',
                                value: '6',
                            },
                            {
                                label: '全部',
                                value: '',
                            },
                        ]} cols={1}
                                value={[this.state.status]}
                                onChange={date => {
                                    this.setState({status: date[0]})
                                    this.handleSendGuiji(shijian, date[0], page_size)
                                }}>
                            <Button style={{
                                borderRadius: 0,
                                borderColor: '#fff'
                            }}>{status ? status == 2 ? '上下班打卡' : (status == 1 ? '出差打卡' :(status == 3?'市内外出打卡':'请假打卡')) : '打卡类型'}<span
                                style={{fontSize: 10}}> ▼</span></Button>
                        </Picker>
                    </div>
                </div>
                <div
                    style={{
                        backgroundColor: '#F5F5F9',
                        height: 8,
                        borderTop: '1px solid #ECECED',
                        borderBottom: '1px solid #ECECED',
                    }}
                />
            </div>
        )
        const separator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    backgroundColor: '#F5F5F9',
                    height: 5,
                    borderTop: '1px solid #ECECED',
                    borderBottom: '1px solid #ECECED',
                }}
            />
        );
        const row = (rowData, sectionID, rowID) => {
            //console.log(rowID);
            const time = new Date(Number(rowData.dakatime + '000'))
            const date = `${("0" + time.getHours()).slice(-2)}:${("0" + time.getMinutes()).slice(-2)}`
            const day = `${(time.getMonth() + 1)}/${("0" + time.getDate()).slice(-2)}`
            return (
                <div>
                    <div className='list_item' key={rowID} style={{padding: '10px'}}>
                        <div className='list_img_left'>
                            {
                                rowData.imgurl &&
                                <img onClick={() => {
                                    const large = this.state.isLarge;
                                    this.setState({isLarge: !large});
                                    this.setState({imgurl: rowData.imgurl})
                                }} src={`${rowData.imgurl}`} alt=""/>
                            }
                        </div>
                        <div className='list_right imglist'>
                            <div style={{lineHeight: 1}}>
                                <div className='dk_state'>
                                    <div className='daka_state'>
                                        {rowData.status_name}</div>
                                    <div className='dk_red'>{rowData.state}</div>
                                </div>
                                <div className='daka_address'>{rowData.address}</div>
                            </div>
                        </div>
                        <div className='list_left'>
                            <h3>{date}</h3>
                            <p>{day}</p>
                        </div>
                        {/*{rowData.status == 2 ?*/}
                            {/*<div className='list_right'>*/}
                                {/*<div style={{lineHeight: 1}}>*/}
                                    {/*<div className='dk_state'>*/}
                                        {/*<div className='daka_state'>*/}
                                            {/*{rowData.is_work == 1 ? '上班打卡' :*/}
                                                {/*(rowData.is_work == 2 ? '下班打卡'*/}
                                                    {/*:*/}
                                                    {/*(rowData.status == 1 ? '出差打卡' : '市内外出打卡'))*/}
                                            {/*}</div>*/}
                                        {/*<div className='dk_red'>{rowData.state}</div>*/}
                                    {/*</div>*/}

                                    {/*<div className='daka_address'>{rowData.address}</div>*/}
                                {/*</div>*/}
                            {/*</div> :*/}
                            {/*<div className='list_right imglist'>*/}
                                {/*<div style={{lineHeight: 1}}>*/}
                                    {/*<div className='dk_state'>*/}
                                    {/*<div className='daka_state'>*/}
                                        {/*{rowData.is_work == 1 ? '上班打卡' :*/}
                                            {/*(rowData.is_work == 2 ? '下班打卡'*/}
                                                {/*:*/}
                                                {/*(rowData.status == 1 ? '出差打卡' : '市内外出打卡'))*/}
                                        {/*}</div>*/}
                                        {/*<div className='dk_red'>444</div>*/}
                                    {/*</div>*/}
                                    {/*<div className='daka_address'>{rowData.address}</div>*/}
                                {/*</div>*/}
                                {/*<div className='list_img_left'>*/}
                                    {/*{*/}
                                        {/*rowData.imgurl &&*/}
                                        {/*<img onClick={() => {*/}
                                            {/*const large = this.state.isLarge;*/}
                                            {/*this.setState({isLarge: !large});*/}
                                            {/*this.setState({imgurl: `https://gps.stycie.com${rowData.imgurl}`})*/}
                                        {/*}} src={`https://gps.stycie.com${rowData.imgurl}`} alt=""/>*/}
                                    {/*}*/}
                                {/*</div>*/}
                            {/*</div> }*/}
                    </div>

                </div>
            );
        };
        const nodata = () => {
            return (
                <div className='nodata'>
                    <Button type="ghost" className="am-button-borderfix">刷新</Button>
                </div>
            );
        }
        return (
            <div className='index-content' id='record'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left"/>}
                    onLeftClick={() => {
                        this.props.history.goBack()
                    }}
                    rightContent={
                        <span onClick={() => {
                            this.handleSendGuiji(shijian, status, page_size)
                        }} >刷新</span>
                    }
                >打卡记录</NavBar>
                <ListView
                    ref={el => this.lv = el}
                    dataSource={this.state.dataSource.cloneWithRows(this.state.data)}
                    renderHeader={header}
                    renderRow={row}
                    renderSeparator={separator}
                    renderBodyComponent={!this.state.hasdata ? nodata : null} //无数据时显示
                    style={{
                        height: this.state.height,
                        overflow: 'auto',
                    }}
                    scrollRenderAheadDistance={500}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}
                    renderFooter={() => (
                        <p>{this.state.hasdata ? (this.state.hasMore ? '加载中..., 请稍等' : '已经全部加载完毕') : ''}</p>
                    )}
                />
                {this.state.isLarge ?
                    <div onClick={() => {
                        const large = this.state.isLarge;
                        this.setState({isLarge: !large})
                    }} className='img_view'>
                        <img src={this.state.imgurl}/>
                    </div> : ''
                }
            </div>
        )
    }
}

export default Index
