import React from 'react'
import PropTypes from 'prop-types'
import { NavBar, Icon, WhiteSpace, WingBlank, List, TextareaItem, Picker, Radio, Modal, Button, Toast, InputItem,Steps } from 'antd-mobile'
import { Link } from 'react-router-dom'
import { postRequest, getUserInfo } from '../../../utils/utils'
import 'antd-mobile/dist/antd-mobile.css'
import './index.css'
import moment from 'moment'


import daishenhe from '../../../images/icons/daishenhe.png'
import yitongguo from '../../../images/icons/yitongguo.png'
import shenpizhong from '../../../images/icons/shenpizhong.png'
import fushen from '../../../images/icons/fushen.png'
import yijujue from '../../../images/icons/yijujue.png'

const Item = List.Item;
const Step = Steps.Step;
const RadioItem = Radio.RadioItem;
class JiaBanYCDetail extends React.Component{
  state = {
      data:{}
  }

  componentDidMount = () => {
    this.getDetail()
  }
  getDetail = async() => {
    const buka_id = localStorage.getItem('jiabanyc_id');
    postRequest(`/yanchang/view`, `id=${buka_id}`).then((result) => {
      console.log(result)
      if(result && result.status === 1) {
        this.setState({data:result.data})
      } else {
          Toast.fail(result.msg || '获取失败', 1);
        if(result.msg === '请登陆！') {
          localStorage.removeItem('daka_token')
          alert('账号已在别处登录,请重新登录')
          this.props.history.push('/')
        }
      }
    })
  }

  
  render() {
      const { data } = this.state
    return (
      <div style={{backgroundColor:'#f5f5f7', minHeight:document.documentElement.clientHeight }}>
        <NavBar
        mode="dark"
        icon={<Icon type="left" />}
        onLeftClick={() => this.props.history.goBack() }
        >加班延长详情</NavBar>
        <List className="my-list jb_list detail_list" renderHeader=''>
          <Item multipleLine wrap extra={<span
              dangerouslySetInnerHTML={{__html: data.shijian}}></span>}>延长时间</Item>
            <Item multipleLine wrap extra={data.content}>延长事由</Item>
        </List>
          <List renderHeader={() => '审核意见'} className="my-list">
              <Steps className='check_step' size="small" current={5}>
                  {data.record==null?'':
                      data.record.map(i=>(i.check_id==null?'':
                              <Step key={i.id}  status={i.status==2?'error':'process'}
                                    title=
                                        {<div className='check_user'>
                                            <span>{i.check_id}</span>
                                            <span className='check_time'>{i.reply_time}</span>
                                        </div>}
                                    description={<div><span className='check_status'>
                                        {i.status==3||i.status==4?'审核通过':(i.status==1?'已登记':'审核未通过')}</span>
                                        {i.reply==''?'':<span>({i.reply})</span>}</div>}
                              />
                      ))
                  }
              </Steps>
          </List>
      </div>
    )
  }
}

export default JiaBanYCDetail