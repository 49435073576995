import { connect } from 'react-redux'
import { getXiaoJia,getXiaoJiaChecklist,deleteXiaoJia} from './actions'
import Setting from './main'


const mapDispatchToProps = {
    getXiaoJia,
    getXiaoJiaChecklist,
    deleteXiaoJia
}
const mapStateToProps = (state) => {
  return {
    ...state.XiaoJiaList
  }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
