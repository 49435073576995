import React from 'react'
import PropTypes from 'prop-types'
import {
    List,
    TextareaItem,
    Button,
    ImagePicker,
    NavBar,
    Checkbox,
    Icon,
    WhiteSpace,
    Picker,
    Toast,
    DatePicker,
    WingBlank,
    Steps
} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import {createForm} from 'rc-form'
import {postRequest} from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import CustomerSearch from '../../components/customer_search'

const Item = List.Item;
const types = [
    {
        label: '内部考勤',
        value: 2,
    },
    {
        label: '外出考勤',
        value: 1,
    },
]
const Step = Steps.Step;

class JBBukaCheck extends React.Component {
    static propTypes = {
        form: PropTypes.object.isRequired,
        checkJBBuka: PropTypes.func.isRequired
    }

    state = {
        buka_id: '',
        data: {},
        option: '',
        showbtn: true,
        fileList_in: [],//进入监控
        fileList_out: [],//离开监控
        file_in: '',//上传图片后反馈地址
        file_out: '',//上传图片后反馈地址
    };

    componentDidMount() {
        this.getDetail();
    }

    getDetail = async () => {
        const buka_id = localStorage.getItem('jiabanbuka_id');
        this.setState({buka_id: buka_id});
        postRequest(`/jiabanbk/checkview`, `id=${buka_id}`).then((result) => {
            console.log(result)
            if (result && result.status === 1) {
                this.setState({data: result.data})
            } else {
                Toast.fail(result.msg || '获取失败', 1);
                if (result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }


    agree = (status) => {
        const {history, checkJBBuka} = this.props
        const {file_in, file_out, time_in, time_out, option, buka_id, data} = this.state

        let body = `id=${buka_id}&status=${status}&content=${option}`

        if (data.type == 1&&data.status == 3) {
            if (time_in && file_in != '')
                body += `&start_img=${file_in}&start=${moment(time_in).format('YYYY-MM-DD HH:mm')}`
            else {
                Toast.info('进入时间和进入监控必填', 2);
                this.setState({showbtn: true});
                return
            }
        }
        if (data.type == 2&&data.status == 3) {
            if (time_out && file_out != '')
                body += `&end_img=${file_out}&end=${moment(time_out).format('YYYY-MM-DD HH:mm')}`
            else {
                Toast.info('离开时间和离开监控必填', 2);
                this.setState({showbtn: true});
                return
            }
        }
        if (data.type == 3&&data.status == 3) {
            if (time_out && time_in && file_in != '' && file_out != '')
                body += `&start_img=${file_in}&start=${moment(time_in).format('YYYY-MM-DD HH:mm')}&end_img=${file_out}&end=${moment(time_out).format('YYYY-MM-DD HH:mm')}`
            else {
                Toast.info('进出入时间和进出入监控必填', 2);
                this.setState({showbtn: true});
                return
            }
        }
        if (status == 2) {
            if (option) {

            } else {
                Toast.info('审核不通过时，必须填写理由', 1);
                this.setState({showbtn: true})
                return
            }
        }
        console.log(body);
        checkJBBuka(body, history)
    }
    //上传和删除图片，删除时files为空数组
    onChange = (files, flieListName, fileName) => {
        this.setState({[flieListName]: files})
        if (files.length > 0) {
            const body = `image=${files[0].url}`
            this.props.upload(body).then(result => {
                if (result)
                    this.setState({[fileName]: result.img_url})
            })
        }
    }

    render() {
        const {data, showbtn} = this.state;
        const user = JSON.parse(localStorage.getItem('daka_user'))
        const u_id = user.u_id;
        //console.log(user);
        return (
            <div style={{backgroundColor: '#f5f5f7', minHeight: document.documentElement.clientHeight}}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left"/>}
                    onLeftClick={() => this.props.history.goBack()}
                >加班补卡审核</NavBar>
                <List className="my-list detail_list" renderHeader=''>
                    <Item multipleLine wrap extra={data.user_id}>申请人</Item>
                    <Item multipleLine wrap extra={data.starttime}>开始时间</Item>
                    <Item multipleLine wrap extra={data.endtime}>结束时间</Item>
                    <Item multipleLine wrap extra={data.content}>补卡事由</Item>

                </List>

                <List renderHeader='审批意见'>
                    {(data.type == 1||data.type == 3)&&data.status==3 &&
                    <DatePicker
                        mode="datetime"
                        title="选择时间"
                        extra="选择时间"
                        value={this.state.time_in}
                        onChange={date => {
                            this.setState({time_in: date});
                        }}
                    >
                        <List.Item arrow="horizontal">进入时间<span className="xing">*</span></List.Item>
                    </DatePicker>
                    }
                    {(data.type == 2||data.type == 3)&&data.status==3 &&
                    <DatePicker
                        mode="datetime"
                        title="选择时间"
                        extra="选择时间"
                        value={this.state.time_out}
                        onChange={date => {
                            this.setState({time_out: date});
                        }}
                    >
                        <List.Item arrow="horizontal">离开时间<span className="xing">*</span></List.Item>
                    </DatePicker>
                    }

                    <div class="flex_upload">
                        {(data.type == 1||data.type == 3)&&data.status==3 &&
                        <div>
                            <p>进入公司监控<span className="xing">*</span></p>
                            <ImagePicker className='upload_img'
                                         files={this.state.fileList_in}
                                         multiple={false}
                                         length={1}
                                         selectable={this.state.fileList_in.length < 1}
                                         onChange={(file) => this.onChange(file, 'fileList_in', 'file_in')}
                                         onImageClick={(index, fs) => console.log(index, fs)}
                            />
                        </div>
                        }
                        {(data.type == 2||data.type == 3)&&data.status==3 &&
                        <div>
                            <p>离开公司监控<span className="xing">*</span></p>
                            <ImagePicker className='upload_img'
                                         files={this.state.fileList_out}
                                         multiple={false}
                                         length={1}
                                         selectable={this.state.fileList_out.length < 1}
                                         onChange={(file) => this.onChange(file, 'fileList_out', 'file_out')}
                                         onImageClick={(index, fs) => console.log(index, fs)}
                            />
                        </div>
                        }
                    </div>

                    {data.status == 1 || data.status == 2 ? '' :
                        <TextareaItem
                            value={this.state.option}
                            onChange={value => this.setState({option: value})}
                            placeholder="请填写审批意见"
                            data-seed="logId"
                            rows={4}
                            ref={el => this.customFocusInst = el}
                        />
                    }
                </List>
                {data.record == '' ? '' :
                    <List renderHeader={() => '审核意见'} className="my-list">
                        <Steps className='check_step' size="small" current={5}>
                            {data.record == null ? '' :
                                data.record.map(i => (i.check_id == null ? '' :
                                        <Step key={i.id} status={i.status == 2 ? 'error' : 'process'}
                                              title=
                                                  {<div className='check_user'>
                                                      <span>{i.u_fullname}</span>
                                                      <span className='check_time'>{i.reply_time}</span>
                                                  </div>}
                                              description={<div>
                                                  <div>
                                                  <span className='check_status'>
                                        {i.status == 3 || i.status == 4 ? '审核通过' : (i.status == 1 ? '已登记' : '审核未通过')}</span>
                                                  {i.content == '' ? '' : <span>({i.content})</span>}
                                                  </div>
                                                  {(i.start_img || i.end_img) && <div class="flex_upload">
                                                      {(data.type == 1 || data.type == 3) &&
                                                      <div>
                                                          <p>进入监控</p>
                                                          <img onClick={() => {
                                                              const large = this.state.isLarge;
                                                              this.setState({isLarge: !large});
                                                              this.setState({imgurl: i.start_img})
                                                          }} height={80} width={80} src={i.start_img}/>
                                                      </div>}
                                                      {(data.type == 2 || data.type == 3) &&
                                                      <div>
                                                          <p>离开监控</p>
                                                          <img onClick={() => {
                                                              const large = this.state.isLarge;
                                                              this.setState({isLarge: !large});
                                                              this.setState({imgurl: i.end_img})
                                                          }} height={80} width={80} src={i.end_img}/>
                                                      </div>}
                                                  </div>
                                                  }
                                              </div>}
                                        />
                                ))
                            }
                        </Steps>
                    </List>
                }
                <div style={{height: '40px'}}></div>
                {
                    data.status == 1 || data.status == 2 ? '' :
                        !showbtn ? '' :
                            data.status == 3 && data.check_id == u_id ? '' :
                                <div className='bottom_btn'>
                                    <div className='bottom_left' onClick={() => {
                                        this.setState({showbtn: false});
                                        this.agree(2)
                                    }}>不同意
                                    </div>
                                    {data.status == 0 ?
                                        <div className='bottom_right' onClick={() => {
                                            this.setState({showbtn: false});
                                            this.agree(3)
                                        }}>同意</div> :
                                        <div className='bottom_right' onClick={() => {
                                            this.setState({showbtn: false});
                                            this.agree(1)
                                        }}>同意</div>//待门卫确认
                                    }
                                </div>
                }
                {this.state.isLarge ?
                    <div onClick={() => {
                        const large = this.state.isLarge;
                        this.setState({isLarge: !large})
                    }} className='img_view'>
                        <img src={this.state.imgurl}/>
                    </div> : ''
                }
            </div>
        )
    }
}

export default createForm()(JBBukaCheck)