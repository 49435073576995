import React from 'react'
import PropTypes from 'prop-types'
import { List, TextareaItem, Button, InputItem, NavBar, Checkbox, Icon, WhiteSpace, Picker, Toast, DatePicker,WingBlank } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import { Link } from 'react-router-dom'
import { createForm } from 'rc-form'
import { postRequest } from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import CustomerSearch from '../../components/customer_search'
import AmapSearch from '../../components/amapSearch'
const Item = List.Item;
const types = [
        {
            label: '内部考勤',
            value: 2,
        },
        // {
        //     label: '出差考勤',
        //     value: 1,
        // },
    {
        label: '市内外出',
        value: 3,
    },
    ]

class BukaEditor extends React.Component {
  static propTypes = {
    form:PropTypes.object.isRequired,
    getApper:PropTypes.func.isRequired,
    apper:PropTypes.array.isRequired,
      saveBuka:PropTypes.func.isRequired,
  }

  state = {
    files:null,
    start_time:'',
    type:[],
    apper:[],
      address:'',
      jingdu:'',
      weidu:'',
      content:'',
      buka_id:'',
      check_username:'',
      showMap:false
  };
    componentWillMount(){
    const buka_id = localStorage.getItem('buka_id');
    this.setState({buka_id:buka_id});
    if(buka_id!=='')//判断是编辑还是新增
    {
        this.getDetail(buka_id);
    }
}
  componentDidMount() {
    this.props.getApper();
  }
   //获取修改数据
    getDetail = async(buka_id) => {
        postRequest(`/bk/edit`, `id=${buka_id}`).then((result) => {
            //console.log(result)
            if(result && result.status === 1) {
                this.setState({start_time:new Date(result.data.list.shijian)});
                this.setState({type:[result.data.list.type]});
                this.setState({apper:[result.data.list.check_id]});
                this.setState({check_username:result.data.list.u_fullname});
                this.setState({address:result.data.list.address});
                this.setState({content:result.data.list.content});
            } else {
                Toast.info(result.msg || '获取失败', 1);
                if(result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }


  onSubmit = () => {
    const { validateFields } = this.props.form
    const { start_time,type, apper,address,jingdu,weidu,content } = this.state
    const { files, history, saveBuka } = this.props
    validateFields((err, values) => {
      let body =''
      if(start_time) {
        body += `shijian=${moment(start_time).format('YYYY-MM-DD HH:mm')}`
      } else {
          Toast.info('补卡时间为必填项', 1);
        return
      }
        if(type && type[0]) {
            body += `&type=${type[0]}`
            if(type==1||type==3) {
                if (address) {
                    body += `&address=${address==null?'':address}&jingdu=${jingdu==null?'':jingdu}&weidu=${weidu==null?'':weidu}`
                    //console.log(body);
                } else {
                    Toast.info('打卡地址为必填项', 1);
                    return
                }
            }
        }
        else {
            Toast.info('补卡类型为必填项', 1);
            return
        }

      if(apper && apper[0]) {
        body += `&check_id=${apper[0]}`
      } else {
          Toast.info('审批人为必填项', 1);
        return
      }
        if(content) {
            body += `&content=${content}`
        } else {
            Toast.info('补卡事由为必填项', 1);
            return
        }
      if (!err) {
        //Toast.loading('新建中, 请稍等....')
          if(this.state.buka_id!='')
          {
              body += `&id=${this.state.buka_id}`
          }
          //console.log(body);
          saveBuka(body, history)
       }
    });
  }

  render() {
    const { getFieldProps } = this.props.form;
    const {  ctype, detail } = this.props;
    let apper = [];
      apper = this.props.apper.map((value, index) => {
      return {
        value:value.u_id,
        label:value.u_fullname
      }
    })
    return (
      <div className='index-content' id='wipeout_editor'>
      <NavBar
        mode="dark"
        icon={<Icon type="left" />}
        onLeftClick={() => this.props.history.goBack() }
        >补卡申请</NavBar>
          <List  className="picker-list" renderHeader=''>

            <DatePicker
              mode="datetime"
              title="选择开始时间"
              extra="选择开始时间"
              value={this.state.start_time}
              onChange={date => {this.setState({ start_time:date });}}
            >
              <List.Item arrow="horizontal">补卡时间<span className="xing">*</span></List.Item>
            </DatePicker>
            <Picker
                data={types}
                extra="补卡类型"
                value={this.state.type}
                cols={1}
                onChange={v => {this.setState({ type: v})}}
            >
              <List.Item arrow="horizontal">补卡类型<span className="xing">*</span></List.Item>
            </Picker>
              {this.state.type!=2?
// onClick={()=>{const show=this.state.showMap;this.setState({showMap:!show})}}
            <InputItem
                {...getFieldProps('address',{
                    rules:[{require: true}]})
                }
                clear
                value={this.state.address}
                onChange={value=>this.setState({address:value})}
                onClick={()=>{const show=this.state.showMap;this.setState({showMap:!show})}}
                placeholder="请输入补卡地址"
                ref={el => this.autoFocusInst = el}
            >补卡地址：</InputItem>
                  :''}
            <CustomerSearch selectvalue={this.state.apper} initialValue={this.state.check_username} customers={apper} onOk={(v) => {
                {this.setState({ apper: v })}
            }}  name='审批人'/>
          </List>
        <List renderHeader='' className="qj-list">
          <TextareaItem
              {...getFieldProps('content', {
                  rules:[{require: true}],
              })}
              value={this.state.content}
              onChange={value=>this.setState({content:value})}
              placeholder="请填写事由"
              data-seed="logId"
              rows={4}
              ref={el => this.customFocusInst = el}
          />
        </List>
          <WhiteSpace />
          <WhiteSpace />
          <WhiteSpace />
        <WingBlank size="md">
          <Button type='primary' onClick={() => this.onSubmit()}>提交</Button>
        </WingBlank>
          {this.state.showMap?
          <AmapSearch onSearch={(address,jd,wd)=>{
              this.setState({address:address});
              this.setState({jingdu:jd});
              this.setState({weidu:wd});
          }} />
              :''
          }
          <div className='daka_rule'>
              <span>注意事项</span>
              <p>
                  1）主管审批通过后，需本人到门卫室协助查监控<br/>
                  2）每月只能免责补卡2次，超过2次的将每次处理50元<br />
                  3）出差不需要补卡<br/>
              </p>
          </div>
      </div>
    )
  }
}

export default createForm()(BukaEditor)