import { connect } from 'react-redux'
import { getApper, saveJiaBanYC } from './actions'
import Setting from './main'


const mapDispatchToProps = {
  getApper,
    saveJiaBanYC
}
const mapStateToProps = (state) => {
  return {
    ...state.JiaBanYCEditor
  }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
