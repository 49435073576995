import React from 'react'
import {Link, Route, BrowserRouter as Router, Switch} from 'react-router-dom'
import PropTypes from 'prop-types'

//图片引入
import DakaPng from '../../../images/icons/daka.png'
import DakaBlue from '../../../images/icons/daka-blue.png'
import TongjiPng from '../../../images/icons/tongji.png'
import TongjiBlue from '../../../images/icons/tongji-blue.png'
import HomePng from '../../../images/icons/home.png'
import HomeBlue from '../../../images/icons/homeblue.png'
import myPng from '../../../images/icons/my.png'
import myBlue from '../../../images/icons/my-blue.png'
import './index.css'

class BottomTab extends React.Component {
    static propTypes = {
        selectedTab: PropTypes.string
    }

    state = {
        selectedTab: 'homeindex',
        hidden: false,
        fullScreen: false,
    };

    tabClick = (data) => {

        this.setState({selectedTab: data})
    }

    render() {

        return (
            <div className='flex-bottom'>
                <div onClick={() => this.tabClick('homeindex')}>
                    <Link to='/shouye'>
                        <img src={this.props.selectedTab === 'homeindex' ? HomeBlue : HomePng} alt='homeindex'/>
                        <span style={{color: this.props.selectedTab === 'homeindex' ? '#1296db' : '#000'}}>首页</span>
                    </Link>
                </div>
                <div onClick={() => this.tabClick('daka')}>
                    <Link to='/home'>
                        <img src={this.props.selectedTab === 'daka' ? DakaBlue : DakaPng} alt='daka'/>
                        <span style={{color: this.props.selectedTab === 'daka' ? '#1296db' : '#000'}}>打卡</span>
                    </Link>
                </div>
                <div onClick={() => this.tabClick('tongji')}>
                    <Link to='/tongji'>
                        <img src={this.props.selectedTab === 'tongji' ? TongjiBlue : TongjiPng} alt='tongji'/>
                        <span style={{color: this.props.selectedTab === 'tongji' ? '#1296db' : '#000'}}>统计</span>
                    </Link>
                </div>
                <div onClick={() => this.tabClick('myinfo')}>
                    <Link to='/myinfo'>
                        <img src={this.props.selectedTab === 'myinfo' ? myBlue : myPng} alt='myinfo'/>
                        <span style={{color: this.props.selectedTab === 'myinfo' ? '#1296db' : '#000'}}>我的</span>
                    </Link>
                </div>
            </div>
        )
    }
}

export default BottomTab
