import React from 'react'
import PropTypes from 'prop-types'
import { List, TextareaItem, Button, InputItem, NavBar, Checkbox, Icon, WhiteSpace, Picker, Toast, DatePicker,WingBlank,Steps,Modal } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import { Link } from 'react-router-dom'
import { createForm } from 'rc-form'
import { postRequest } from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import CustomerSearch from '../../components/customer_search'
const Item = List.Item;
const Step = Steps.Step;
const alert = Modal.alert;
const types = [
        {
            label: '内部考勤',
            value: 2,
        },
        {
            label: '外出考勤',
            value: 1,
        },
    ]

class WaiChuCheck extends React.Component {
  static propTypes = {
    form:PropTypes.object.isRequired,
      checkWaiChu:PropTypes.func.isRequired
  }

  state = {
      waichu_id:'',
      data:{},
      option:'',
      status:'',
      out_time:'',//出门时间
      checkers:[],
      showbtn:true,
  };

  componentDidMount() {
    this.getDetail();
      this.getChecks();
  }
    getDetail = async() => {
        const waichu_id = localStorage.getItem('waichu_id');
        this.setState({waichu_id:waichu_id});
        postRequest(`/out/check`, `id=${waichu_id}`).then((result) => {
            console.log(result)
            if(result && result.status === 1) {
                this.setState({data:result.data,status:result.data.status})
            } else {
                Toast.fail(result.msg || '获取失败', 1);
                if(result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }
    //获取主管
    getChecks = async() => {
        postRequest(`/yijiao/zhuguan`, ``).then((result) => {
            //console.log(result)
            if(result && result.status === 1) {
                this.setState({checkers:result.data})
            }
        })
    }
    //移交保存
    YiJiao = async(checkerid) => {
        const buka_id = localStorage.getItem('waichu_id');
        let body2=`id=${buka_id}&mokuai=out`;
        //获取移交前的数据
        postRequest(`/yijiao/yj`, body2).then((result) => {
            if(result && result.status === 1) {
                const shijian=result.data.shijian
                let body=`check_id=${checkerid}&id=${buka_id}&mokuai=out&shijian=${shijian}`;
                postRequest(`/yijiao/save`, body).then((result) => {
                    //console.log(result)
                    if(result && result.status === 1) {
                        Toast.success('移交成功',1);
                    }
                })
            }
        })
    }

    agree = (status) => {

        const {history, checkWaiChu } = this.props
        let body =`id=${this.state.waichu_id}&status=${status}&content=${this.state.option}`
        if(this.state.status==3||this.state.status==4)
        {
            if(this.state.out_time) {
                body += `&out_time=${moment(this.state.out_time).format('YYYY-MM-DD HH:mm')}`
            } else {
                Toast.info('出门时间必填', 1);
                this.setState({showbtn:true});
                return
            }
        }
        if(status==2){
            if(this.state.option) {
            } else {
                Toast.info('审核不通过时，必须填写理由', 1);
                this.setState({showbtn:true})
                return
            }
        }
        //console.log(body);
        checkWaiChu(body, history)
  }

    render() {
        const { data,checkers,showbtn } = this.state;
        const user = JSON.parse(localStorage.getItem('daka_user'))
        const u_id = user.u_id;
        //console.log(user);
        let checkername = [];
        checkername = checkers.map((value, index) => {
            return {
                value: value.u_id,
                label: value.u_fullname
            }
        });
        //console.log(data);
        return (
            <div style={{backgroundColor:'#f5f5f7', minHeight:document.documentElement.clientHeight }}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack() }
                >外出审核</NavBar>
                <List className="my-list detail_list" renderHeader=''>
                    <Item multipleLine wrap extra={data.u_fullname}>申请人</Item>
                    <Item multipleLine wrap extra={data.starttime}>外出时间</Item>
                    <Item className='text_left' multipleLine wrap extra={data.content}>外出事由</Item>
                    <Item multipleLine wrap extra={data.work==0?'是':'否'}>是否从公司出发</Item>
                </List>
                {data.check_name_id==u_id?
                <List renderHeader='审批意见'>
                    {data.status==3||data.status==4?
                        <DatePicker
                            mode="datetime"
                            title="请选择时间"
                            extra="请选择时间"
                            value={this.state.out_time}
                            onChange={date => this.setState({ out_time:date })}
                        >
                            <List.Item arrow="horizontal">{data.status==3?'出门时间':'返回时间'}<span className="xing">*</span></List.Item>
                        </DatePicker>:''}
                        <TextareaItem
                            value={this.state.option}
                            onChange={value=>this.setState({option:value})}
                            placeholder="请填写审批意见"
                            data-seed="logId"
                            rows={4}
                            ref={el => this.customFocusInst = el}
                        />
                </List>:''}
                {data.check == '' ? '' :
                    <List renderHeader={() => '审核流程'} className="my-list">
                        <Steps className='check_step' size="small" current={5}>
                            {data.check == null ? '' :
                                data.check.map(i => (i.check_id == null ? '' :
                                        <Step key={i.id} status={i.status == 2 ? 'error' : 'process'}
                                              title=
                                                  {<div className='check_user'>
                                                      <span>{i.check_id}</span>
                                                      <span className='check_time'>{i.reply_time}</span>
                                                  </div>}
                                              description={<div><span
                                                  className='check_status'>{i.status == 2 ? '审核未通过' : '审核通过'}</span>
                                                  {i.content == '' ? '' : <span>({i.content})</span>}</div>}
                                        />
                                ))
                            }
                        </Steps>
                    </List>
                }
                <div style={{height:'40px'}}></div>

                {(data.status==1||data.status==2)?'':
                    !showbtn?'':
                        data.check_name_id==u_id?
                <div className='bottom_btn'>
                    <div className='bottom_left' onClick={()=>{this.setState({showbtn:false});this.agree(2)}}>不同意</div>
                    <div className='bottom_right noagree' onClick={()=>{
                        alert('', '确定提交吗？', [
                            { text: '取消', onPress: () => {} },
                            { text: '确定', onPress: () => {this.setState({showbtn:false});this.agree(1)} },
                        ])
                        }}>同意</div>
                    <Picker
                        data={checkername}
                        extra="移交主管"
                        value={this.state.checkerID}
                        cols={1}
                        onOk={(v)=>{this.setState({showbtn:false});this.YiJiao(v)}}
                    >
                        <div className='bottom_right'>移交</div>
                    </Picker>
                </div>:''
                }
            </div>
        )
    }
}

export default createForm()(WaiChuCheck)