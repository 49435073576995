import React from 'react'
import { Link, Route, BrowserRouter as Router, Switch} from 'react-router-dom'
import {List, Checkbox, Flex,NavBar,Icon} from 'antd-mobile'
import PropTypes from 'prop-types'
import { postRequest } from '../../../utils/utils';
import './index.css'

const CheckboxItem = Checkbox.CheckboxItem;
const AgreeItem = Checkbox.AgreeItem;

class SelectCheckBoxs extends React.Component {
    static propTypes = {
    }
    state = {
        users:[]
    };
    componentDidMount() {
        this.getuser();
    }
    //获取人员列表
    getuser = async () => {
        postRequest('/jiaban/user').then((value) => {
            //console.log(value)
            if(value.suc === 0) {
                if(value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            } else {
                this.setState({users:value.data})
            }

        })
    }
  render() {
      const { users } = this.state
      //console.log(users);
    return (
        <div >
            <NavBar
                mode="dark"
                icon={<Icon type="left"/>}
                onLeftClick={() => this.props.history.goBack()}
                rightContent={
                    <a onClick={()=>{

                    }} style={{color: '#fff', paddingTop: 3}}>
                       完成
                    </a>
                }
            >选择加班人员</NavBar>
            <List>
                {users.map(i => (
                    <CheckboxItem key={i.u_id} onChange={() => this.onChange(i.u_id)}>
                        {i.u_fullname}
                    </CheckboxItem>
                ))}
            </List>
        </div>
      )
  }
}

export default SelectCheckBoxs
