import React from 'react'
import PropTypes from 'prop-types'
import {
    ListView,
    WhiteSpace,
    NavBar,
    Icon,
    SearchBar,
    Card,
    DatePicker,
    Button,
    SegmentedControl,
    Checkbox,
    Toast,
    Tabs
} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import BottomTab from '../../components/bottomTab'
import {postRequest, getUserInfo} from '../../../utils/utils'
import './index.css'
import moment from 'moment'

import {createForm} from 'rc-form'

//图片引入
import beginimg from '../../../images/begin.png'
import endimg from '../../../images/end.png'

var map, geolocation
const CheckboxItem = Checkbox.CheckboxItem
const tabs2 = [
    {title: '申请列表', sub: '1'},
    {title: '审批列表', sub: '2'}
];
const dataSource = new ListView.DataSource({  //这个dataSource有cloneWithRows方法
    rowHasChanged: (row1, row2) => row1 !== row2,
})

class JiabanList extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        getJiaban: PropTypes.func.isRequired,
        ctype: PropTypes.object.isRequired,
        approvals: PropTypes.array.isRequired,
        getOverTime: PropTypes.func.isRequired,
        data: PropTypes.array.isRequired,
    }

    state = {
        dataSource,
        height: document.documentElement.clientHeight - 70,
        check_money: [],
        tabindex: 0,  //tab选项卡索引判断是申请还是审核列表(1:申请列表；2：审核列表)
        btntext: '',//按钮文字
        realTime: '',  //实际加班时长
        page_size: 1,
        hasMore: true,
        page_size2: 1,
        hasMore2: true,
        jingdu: '',
        weidu: '',
        address:''
    }

    componentWillMount() {
        this.getPosition()
        const index = this.props.location.state
        if (index == undefined) {
            this.setState({tabindex: 0})
        }
        else {
            this.setState({tabindex: 1})
        }
    }

    componentDidMount() {
        const index = this.props.location.state
        if (index == undefined) {
            this.props.getJiaban()
        }
        else {
            this.props.getOverTime()
        }
    }

    //获取定位
    getPosition = () => {
        const {AMap,} = window
        map = new AMap.Map('mapContainer', {
            resizeEnable: true,
            zoom: 12
        });
        map.plugin('AMap.Geolocation', () => {
            geolocation = new AMap.Geolocation({
                enableHighAccuracy: true,//是否使用高精度定位，默认:true
                //timeout: 10000,          //超过10秒后停止定位，默认：无穷大
                buttonOffset: new AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
                zoomToAccuracy: true,      //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                buttonPosition: 'RT'
            });
            map.addControl(geolocation);
            geolocation.getCurrentPosition();
           // Toast.loading('定位中..., 请稍后', 30);
            AMap.event.addListener(geolocation, 'complete', (data) => {
                this.setState({
                        jingdu: data.position.lng,
                        weidu: data.position.lat,
                    address: data.formattedAddress,})//获取经度
                // if (data.formattedAddress) {
                //     Toast.hide()
                //     Toast.success('定位成功，可进行开始，结束打卡', 6);
                // }
                // else {
                //     Toast.hide()
                //     Toast.fail('未获取到定位信息，请刷新重新定位', 6);
                // }
            });//返回定位信息
            AMap.event.addListener(geolocation, 'error', (data) => {
                console.log(data);
            });      //返回定位出错信息
        })
    }
//设置开始，结束加班
    setJiaban = async (jiaban_id, type, time1, time2) => {
        const {jingdu,weidu,address}=this.state
        // if(jingdu){
            postRequest(`/jiaban/shijijiaban`,
                `id=${jiaban_id}&type=${type}&time1=${time1}&time2=${time2}&jingdu=${jingdu}&weidu=${weidu}&address=${address}`).then((result) => {
                console.log(result)
                if (result && result.status === 1) {
                    if (type == 1) {
                        Toast.info('开始计算加班时间', 1);
                    }
                    this.props.getJiaban();
                } else {
                    Toast.fail(result.msg || '获取失败', 2);
                    if (result.msg.indexOf('请登陆')!=-1) {
                        localStorage.removeItem('daka_token')
                        this.props.history.push('/')
                    }
                }
            })

        // }
       // else Toast.fail('未获取到定位信息，请稍后再试', 3);
    }
    //判断是否可以申请加班
    add = async () => {
        localStorage.setItem('jiaban_id', '')
        postRequest(`/Jiaban/check`).then((result) => {
            //console.log(result)
            if (result && result.status === 1) {
                localStorage.setItem('jiaban_id', '')
                this.props.history.push('/jiaban/editor')
            } else {
                Toast.info(result.msg || '不需要加班', 2);
                if (result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
        // localStorage.setItem('jiaban_id', '')
        // this.props.history.push('/jiaban/editor')
    }
    //删除加班
    delete = async (jiabanid) => {
        //this.setState({buka_id:bukaid});
        const {history, deleteJiaban} = this.props
        let body = `id=${jiabanid}`
        deleteJiaban(body, history);
    }

    onEndReached1 = () => {
        const {page_size} = this.state
        const pagesize = Math.ceil(this.props.count / 20) //页数
        let p = page_size;
        if (!this.state.hasMore) {
            return
        }
        else {
            if (p >= pagesize) {
                this.setState({hasMore: false})
            }
            else {
                this.setState({hasMore: true})
                p = page_size + 1;
                this.setState({page_size: p})
                const oldData = this.props.jblistdata;
                this.props.getJiaban(p, oldData)
            }
        }
    }
    onEndReached2 = () => {
        const {page_size2, hasMore2} = this.state
        const pagesize = Math.ceil(this.props.count2 / 20) //页数
        let p = page_size2;
        if (!hasMore2) {
            return
        }
        else {
            if (p >= pagesize) {
                this.setState({hasMore2: false})
            }
            else {
                this.setState({hasMore2: true})
                p = page_size2 + 1;
                this.setState({page_size2: p})
                const oldData = this.props.jbcheckdata;
                this.props.getOverTime(p, oldData)
            }
        }
    }

    render() {
        const {jblistdata, jbcheckdata, nojb, nocheck, ctype} = this.props
        const user = JSON.parse(localStorage.getItem('daka_user'));
console.log(new Date())
        const uid = user.u_id
        const separator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    backgroundColor: '#F5F5F9',
                    height: 10,
                }}
            />
        );
        const nodata = () => {
            return (
                <div className='nodata'>
                </div>
            );
        }
        const row = (rowData, sectionID, rowID) => {
            const time = new Date(Number(rowData.addtime + '000'))
            const date = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()} ${time.getHours()}:${time.getMinutes()}`
            let stateSpan = ''
            if (rowData.status === 0) {
                stateSpan = <span style={{fontSize: 14, color: '#FF8E2B'}}>待{rowData.check_name}审批</span>
            }
            if (rowData.status === 3) {
                stateSpan = <span style={{fontSize: 14, color: '#FF8E2B'}}>待{rowData.check_name}登记</span>
            }
            if (rowData.status === 1) {
                stateSpan = <span style={{fontSize: 14, color: '#1296db'}}>已通过</span>
            }
            if (rowData.status === 2) {
                stateSpan = <span style={{fontSize: 14, color: '#ff4d4f'}}>未通过</span>
            }

            return (
                <Card className='jiaban_list'>
                    <Card.Header
                        extra={stateSpan}
                        title={`申请人： ${rowData.user_id}`}
                        style={{
                            fontSize: 14
                        }}
                    />
                    <Card.Body
                        style={{padding: '10px 10px 10px 15px', paddingLeft: 15, display: 'flex', flexDirection: 'row'}}
                    >
                        <div style={{flex: 1}}>
                            {/*加班申请信息,审核通过前展示*/}
                            {(rowData.status === 1||rowData.status==3) && this.state.tabindex == 0 && rowData.yg == 1&&rowData.work==1 ? '' :
                                <div>
                                    {rowData.shijian.map((time_item, index) => (
                                        <div>
                                            <div className='card-body-list qj_list_item'>
                                                <img src={beginimg}/>
                                                <p className='list_time'>{time_item.split(',')[0]}</p>
                                                {index == 0 ?
                                                    <p className='list_count'>共 {rowData.xiaoshi}小时</p> : ''
                                                }
                                            </div>
                                            <div className='card-body-list qj_list_item'>
                                                <img src={endimg}/>
                                                <p className='list_time'>{time_item.split(',')[1]}</p>
                                            </div>
                                        </div>
                                    ))}
                                    <div className='card-body-list'>{`人员： ${rowData.yuangong}`}</div>
                                </div>
                            }
                            {/*审核通过后，加班人员操作界面,工作日加班不打卡，故工作日不显示该内容*/}
                            {(rowData.status === 1 || rowData.status == 3) && this.state.tabindex == 0 && rowData.yg&&rowData.work==1 ?
                                <div>
                                    {rowData.record.map((item, i) => {
                                        if (item != null)
                                            return (
                                                <div className=' jb_list_item'>
                                                    <div className='card-body-list jb_list_left'>
                                                        <div className='jb_item'>
                                                            <img src={beginimg}/>
                                                            <p className='list_time'>{item.time1}</p>
                                                        </div>
                                                        <div className='jb_item_btn'>
                                                            {/*未点击开始按钮，可补卡*/}
                                                            {(item.bk==1&&item.start==0)&&
                                                            <Button className='lit_btn' type="primary"
                                                            inline
                                                            size="small"
                                                            onClick={() => {
                                                                localStorage.setItem('jiabanbuka_id', '');
                                                                localStorage.setItem('jiaban_id', rowData.id);
                                                                localStorage.setItem('applytime', `${item.time1}|${item.time2}`)
                                                                this.props.history.push({
                                                                    pathname: '/JiabBanbuka/editor',
                                                                    search: `${item.time1}|${item.time2}`
                                                                })
                                                            }}>补卡
                                                    </Button>
                                                            }
                                                            {/* 未点开始按钮且已申请补卡 */}
                                                            {(item.bk==2&&item.start==0)&&
                                                            <p className='list_count'>已补卡</p>
                                                            }
                                                            {/*已点击开始按钮，不可补卡*/}
                                                            {(item.start == 1)&&
                                                            <p className='list_count'>已打开始卡</p>
                                                            }
                                                            {/*在申请的加班时间内且未点击过开始按钮，不可补卡*/}
                                                            {(new Date(item.time2) > new Date()&&item.start == 0&&item.bk==0)&&
                                                            <Button className='lit_btn' type="primary"
                                                                    inline size="small"
                                                                    disabled={item.start == 0 ? false : true}
                                                                    onClick={() => {
                                                                        this.setJiaban(rowData.id, 1, item.time1, item.time2);
                                                                    }}>开始</Button>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='card-body-list jb_list_right'>
                                                        <div className='jb_item'>
                                                            <img src={endimg}/>
                                                            <p className='list_time'>{item.time2}</p>
                                                        </div>
                                                        <div className='jb_item_btn'>
                                                            {/*显示结束按钮，若没点击开始且加班结束前，显示补卡按钮*/}
                                                            { (item.bk==1&&item.end==0)&&
                                                            <Button className='lit_btn' type="primary"
                                                                    inline
                                                                    size="small"
                                                                    onClick={() => {
                                                                        localStorage.setItem('jiabanbuka_id', '');
                                                                        localStorage.setItem('jiaban_id', rowData.id);
                                                                        localStorage.setItem('applytime', `${item.time1}|${item.time2}`)
                                                                        this.props.history.push({
                                                                            pathname: '/JiabBanbuka/editor',
                                                                            search: `${item.time1}|${item.time2}`
                                                                        })
                                                                    }}>补卡
                                                            </Button>
                                                            }
                                                            {(item.bk==2&&item.end==0)&&
                                                            <p className='list_count'>
                                                                已补卡</p>
                                                            }
                                                            {(item.end==1)&&
                                                            <p className='list_count'>已打结束卡</p>
                                                            }
                                                            {(item.bk==0&&item.end==0)&&
                                                            <Button className='lit_btn' type="primary"
                                                            inline
                                                            size="small"
                                                            disabled={item.end == 0 ? false : true}
                                                            onClick={() => {
                                                                this.setJiaban(rowData.id, 2, item.time1, item.time2);
                                                            }}>结束
                                                    </Button>
                                                            }
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    })}
                                </div> : ''
                            }

                        </div>
                    </Card.Body>
                    {/*申请人才可编辑，删除，延长*/}

                    <Card.Footer className='list_footer normalflex jiaban_footer' content=''
                                 extra={
                                     <div className='opbtn_list'>
                                         <a className='lit_btn my_list_btn' onClick={() => {
                                             localStorage.setItem('jiaban_id', rowData.id)
                                             this.state.tabindex == 0 ?
                                                 this.props.history.push({pathname: '/jiaban/detail'}) :
                                                 this.props.history.push({pathname: '/jiaban/check'})
                                         }}>查看详情</a>
                                         {rowData.status === 0 && this.state.tabindex == 0 && uid == rowData.shengqing ?
                                             <div>
                                                 <a className='lit_btn my_list_btn' onClick={() => {
                                                     localStorage.setItem('jiaban_id', rowData.id);
                                                     this.props.history.push({
                                                         pathname: '/jiaban/editor',
                                                         jiaban_id: rowData.id
                                                     })
                                                 }}>编辑</a>
                                                 <a className='lit_btn my_list_btn' onClick={() => {
                                                     this.delete(rowData.id)
                                                 }}>删除</a>
                                             </div> : ''
                                         }
                                         {rowData.status === 1 && this.state.tabindex == 0 && uid == rowData.shengqing ?
                                             <a className='lit_btn my_list_btn' onClick={() => {
                                                 localStorage.setItem('jiabanyc_id', '');
                                                 localStorage.setItem('jiaban_id', rowData.id);
                                                 this.props.history.push({pathname: '/JiabBanYanChang/editor'})
                                             }}>延长加班</a> : ''
                                         }
                                     </div>
                                 }>
                    </Card.Footer>

                    {/*{*/}
                    {/*rowData.status === 1 && this.state.tabindex == 0 && rowData.yg ?*/}
                    {/*<Card.Footer className='list_jbtimes normalflex'*/}
                    {/*content=''*/}
                    {/*extra={*/}
                    {/*rowData.record.map((item, i) => item == null ? '' :*/}
                    {/*<div className='jb_footer_ls' key={i}>*/}
                    {/*<div className='jb_footer_text'>*/}
                    {/*<div>{`实际时间：${item.time1} 至 ${item.time2}`}</div>*/}
                    {/*{item.work == 0 ? '' :*/}
                    {/*<div>实际时长：{item.hour}</div>*/}
                    {/*}*/}
                    {/*</div>*/}
                    {/*{item.work == 0 ? '' :*/}
                    {/*<div className='jb_footer_btns'>*/}
                    {/*<Button type="primary" inline size="small"*/}
                    {/*disabled={item.start == 0 ? false : true}*/}
                    {/*onClick={() => {*/}
                    {/*const type = item.start == 0 ? 1 : 2;*/}
                    {/*this.setJiaban(rowData.id, type, item.time1, item.time2);*/}
                    {/*}}>开始加班</Button>*/}
                    {/*{*/}
                    {/*item.start == 0 && new Date(item.time2) < new Date() ?*/}
                    {/*<Button type="primary" inline size="small"*/}
                    {/*onClick={() => {*/}
                    {/*localStorage.setItem('jiabanbuka_id', '');*/}
                    {/*localStorage.setItem('jiaban_id', rowData.id);*/}
                    {/*this.props.history.push({*/}
                    {/*pathname: '/JiabBanbuka/editor',*/}
                    {/*search: `${item.time1}|${item.time2}`*/}
                    {/*})*/}
                    {/*}}>加班补卡*/}
                    {/*</Button>*/}
                    {/*:*/}
                    {/*<Button type="primary" inline size="small"*/}
                    {/*disabled={item.end == 0 ? false : true}*/}
                    {/*onClick={() => {*/}
                    {/*const type = item.start == 0 ? 1 : 2;*/}
                    {/*this.setJiaban(rowData.id, type, item.time1, item.time2);*/}
                    {/*}}>结束加班*/}
                    {/*</Button>*/}
                    {/*}*/}
                    {/*</div>*/}
                    {/*}*/}

                    {/*</div>*/}
                    {/*)*/}
                    {/*}*/}
                    {/*>*/}
                    {/*</Card.Footer> : ''*/}
                    {/*}*/}
                    {/*{*/}
                    {/*rowData.status === 0 && this.state.tabindex == 0 && uid == rowData.shengqing ?*/}
                    {/*<Card.Footer className='list_footer' content={<div onClick={() => {*/}
                    {/*localStorage.setItem('jiaban_id', rowData.id);*/}
                    {/*this.props.history.push({pathname: '/jiaban/editor', jiaban_id: rowData.id})*/}
                    {/*}}>编辑</div>}*/}
                    {/*extra={<div onClick={() => {*/}
                    {/*this.delete(rowData.id)*/}
                    {/*}}>删除</div>}>*/}
                    {/*</Card.Footer> : ''*/}
                    {/*}*/}
                    {/*{*/}
                    {/*rowData.status === 1 && this.state.tabindex == 0 && uid == rowData.shengqing ?*/}
                    {/*<Card.Footer className='list_footer normalflex' content=''*/}
                    {/*extra={*/}
                    {/*<div className='opbtn_list'>*/}
                    {/*<a type='primary' onClick={() => {*/}
                    {/*localStorage.setItem('jiabanyc_id', '');*/}
                    {/*localStorage.setItem('jiaban_id', rowData.id);*/}
                    {/*this.props.history.push({pathname: '/JiabBanYanChang/editor'})*/}
                    {/*}} size="small">延长加班</a>*/}
                    {/*</div>*/}
                    {/*}>*/}
                    {/*</Card.Footer> : ''*/}
                    {/*}*/}

                </Card>
            );
        };
        return (

            <div id='wipeout-list' style={{backgroundColor: '#f5f5f9'}}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left"/>}
                    onLeftClick={() => this.props.history.goBack()}
                    rightContent={
                        <a onClick={() => {
                            // localStorage.setItem('jiaban_id','');
                            // this.props.history.push({pathname:'/jiaban/editor'})
                            this.add()
                        }} style={{color: '#fff', paddingTop: 3}}>
                            <Icon key="0" type="plus" size='xs'/>
                        </a>
                    }
                >加班列表</NavBar>
                <Tabs swipeable={false} tabs={tabs2}
                      initialPage={this.state.tabindex}
                      onTabClick={(tab, index) => {
                          //    // this.setState({selectedIndex:index})
                          if (index === 0) {
                              this.setState({tabindex: 0})
                              this.props.getJiaban()
                          }
                          if (index === 1) {
                              this.setState({tabindex: 1})
                              this.props.getOverTime()
                          }
                      }}
                >
                    <div>
                        <ListView
                            ref={el => this.lv = el}
                            dataSource={this.state.dataSource.cloneWithRows(jblistdata)}
                            renderRow={row}
                            style={{
                                height: this.state.height,
                                overflow: 'auto',
                            }}
                            renderSeparator={separator}
                            renderBodyComponent={!nojb ? nodata : null} //无数据时显示
                            onEndReached={this.onEndReached1}
                            onEndReachedThreshold={20}
                            renderFooter={() => (
                                <p>{nojb ? (this.state.hasMore && jblistdata.length >= 20 ? '加载中..., 请稍等' : '已经全部加载完毕') : ''}</p>
                            )}
                        />

                    </div>
                    <div>
                        <ListView
                            ref={el => this.lv = el}
                            dataSource={this.state.dataSource.cloneWithRows(jbcheckdata)}
                            renderRow={row}
                            style={{
                                height: this.state.height,
                                overflow: 'auto',
                            }}
                            renderSeparator={separator}
                            renderBodyComponent={!nocheck ? nodata : null} //无数据时显示
                            onEndReached={this.onEndReached2}
                            onEndReachedThreshold={20}
                            renderFooter={() => (
                                <p>{nocheck ? (this.state.hasMore2 && jbcheckdata.length >= 20 ? '加载中..., 请稍等' : '已经全部加载完毕') : ''}</p>
                            )}
                        />

                    </div>
                </Tabs>
                {/*<SegmentedControl values={['申请列表', '审批列表']} selectedIndex={this.state.selectedIndex} onChange = {(e) => {*/}
                {/*this.setState({selectedIndex:e.nativeEvent.selectedSegmentIndex})*/}
                {/*if(e.nativeEvent.selectedSegmentIndex === 0){*/}
                {/*this.props.getJiaban()*/}
                {/*}*/}
                {/*if(e.nativeEvent.selectedSegmentIndex === 1){*/}
                {/*this.props.getOverTime()*/}
                {/*}*/}
                {/*}}/>*/}
                <WhiteSpace/>
                <div style={{height: 55}}></div>
                <BottomTab selectedTab='buka'/>
            </div>

        )
    }
}

export default JiabanList

