import React from 'react'
import PropTypes from 'prop-types'
import { List, TextareaItem, Button, InputItem, NavBar, Checkbox, Icon, WhiteSpace, Picker, Toast, DatePicker,WingBlank,Steps } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import { Link } from 'react-router-dom'
import { createForm } from 'rc-form'
import { postRequest } from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import CustomerSearch from '../../components/customer_search'
const Item = List.Item;

const Step = Steps.Step;
class QingJiaCheck extends React.Component {
  static propTypes = {
    form:PropTypes.object.isRequired,
      checkQingJia:PropTypes.func.isRequired
  }

  state = {
      qingjia_id:'',
      data:{
          type:''
      },
      option:'',
      checkers:[],
      showbtn:true,
  };

  componentDidMount() {
    this.getDetail();
      this.getChecks();
  }
    getDetail = async() => {
        const qingjia_id = localStorage.getItem('qingjia_id');
        postRequest(`/qingjia/view`, `id=${qingjia_id}`).then((result) => {
            console.log(result.data)
            console.log(result.data.type)
            if (result && result.suc === 1) {
                this.setState({data: result.data})
            } else {
                Toast.fail(result.msg || '获取失败', 1);
                if (result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }
    //获取主管
    getChecks = async() => {
        postRequest(`/yijiao/zhuguan`, ``).then((result) => {
            //console.log(result)
            if(result && result.status === 1) {
                this.setState({checkers:result.data})
            }
        })
    }

    //移交保存
    YiJiao = async(checkerid) => {
        const buka_id = localStorage.getItem('qingjia_id');
        let body2=`id=${buka_id}&mokuai=qingjia`;
        //获取移交前的数据
        postRequest(`/yijiao/yj`, body2).then((result) => {
            if(result && result.status === 1) {
                const shijian=result.data.shijian
                let body=`check_id=${checkerid}&id=${buka_id}&mokuai=qingjia&shijian=${shijian}`;
                postRequest(`/yijiao/save`, body).then((result) => {
                    console.log(result)
                    if(result && result.status === 1) {
                        Toast.success('移交成功',1);
                    }
                })
            }
        })

    }

    agree = (status) => {
        const {history, checkQingJia } = this.props
        const qingjia_id = localStorage.getItem('qingjia_id');
        let body =`id=${qingjia_id}&status=${status}&beizhu=${this.state.option}`
        if(status==2){
            if(this.state.option) {

            } else {
                Toast.info('审核不通过时，必须填写理由', 1);
                this.setState({showbtn:true})
                return
            }
        }
        //console.log(body);
        checkQingJia(body, history)
    }

    render() {
        const { data,checkers ,showbtn} = this.state;
        const user = JSON.parse(localStorage.getItem('daka_user'))
        const u_id = user.u_id;
        let checkername = [];
        checkername = checkers.map((value, index) => {
            return {
                value: value.u_id,
                label: value.u_fullname
            }
        });

        return (
            <div style={{backgroundColor:'#f5f5f7', minHeight:document.documentElement.clientHeight }}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left" />}
                    onLeftClick={() => this.props.history.goBack() }
                >请假审批</NavBar>
                <List className="my-list detail_list" renderHeader=''>
                    <Item multipleLine wrap extra={data.u_fullname}>申请人</Item>
                    <Item multipleLine wrap extra={data.starttime}>开始时间</Item>
                    <Item multipleLine wrap extra={data.endtime}>结束时间</Item>
                    <Item multipleLine wrap extra={data.type}>请假类型</Item>
                    <Item multipleLine wrap extra={`${data.xiaoshi}小时`}>请假时长</Item>
                    {data.qj_type==4?
                        <Item multipleLine wrap extra={data.guanxi}>亲属关系</Item>:''
                    }
                    <Item multipleLine wrap extra={data.shiyou}>申请事由</Item>

                </List>
                {data.type=='事假'?'':
                    <div className='img_box'>
                        <p>证明材料</p>
                        {
                            data.imgurl===null?'暂无材料':
                                <img onClick={() => {
                                    const large = this.state.isLarge;
                                    this.setState({isLarge: !large});
                                    this.setState({imgurl: data.imgurl})
                                }} src={data.imgurl} alt=""/>
                        }
                    </div>
                }
                {data.type=='特批出勤'&&
                    <div className='img_box'>
                        <p>核检结果</p>
                        {
                            data.tpurl &&
                            <img onClick={() => {
                                const large = this.state.isLarge;
                                this.setState({isLarge: !large});
                                this.setState({imgurl: data.tpurl})
                            }} src={data.tpurl} alt=""/>
                        }
                    </div>
                }
                { u_id==data.check_name_id?
                <List renderHeader='' className="qj-list">
                    <TextareaItem
                        value={this.state.option}
                        onChange={value=>this.setState({option:value})}
                        placeholder="请填写意见"
                        data-seed="logId"
                        rows={4}
                        ref={el => this.customFocusInst = el}
                    />
                </List>:''}
                {data.check == '' ? '' :
                    <List renderHeader={() => '审核意见'} className="my-list">
                        <Steps className='check_step' size="small" current={5}>
                            {data.check == null ? '' :
                                data.check.map(i => (i.agree_id == null ? '' :
                                        <Step key={i.id} status={i.status == '未通过' ?  'error' : 'process'}
                                              title=
                                                  {<div className='check_user'>
                                                      <span>{i.agree_id}</span>
                                                      <span className='check_time'>{i.infotime}</span>
                                                  </div>}
                                              description={<div><span className='check_status'>{i.status_name}</span>
                                                  {i.beizhu == '' ? '' : <span>({i.beizhu})</span>}</div>}
                                        />
                                ))
                            }
                        </Steps>
                    </List>
                }
                <div style={{height:'40px'}}></div>
                {data.status==1?'':
                    !showbtn?'':
                        u_id==data.check_name_id?
                <div className='bottom_btn'>
                    {((data.xiaoshi>=22.5&&data.status==5) || data.status==4) ? '' :
                            <div className='bottom_left' onClick={()=>{this.setState({showbtn:false});this.agree(2)}}>不同意</div>
                    }
                    {data.xiaoshi >= 22.5?  //大于3天有二级审核，否则一级审核
                        data.status==4 ? <div className='bottom_right' onClick={()=>{this.setState({showbtn:false});this.agree(5)}}>登记</div> :
                        (data.status == 5 ?  
                                <div className='bottom_right' onClick={()=>{this.setState({showbtn:false});this.agree(1)}}>销假</div>:
                                <div className='bottom_right' onClick={()=>{this.setState({showbtn:false});this.agree(1)}}>同意</div>
                        )
                        :
                        <div className='bottom_right' onClick={()=>{this.setState({showbtn:false});this.agree(1)}}>同意</div>
                    }
                    <Picker
                        data={checkername}
                        extra="移交主管"
                        value={this.state.checkerID}
                        cols={1}
                        onOk={(v)=>{this.setState({showbtn:false});this.YiJiao(v)}}
                    >
                        <div className='bottom_right'>移交</div>
                    </Picker>
                </div>:''
                }
                <div style={{height:'50px'}}></div>
                {this.state.isLarge ?
                    <div onClick={() => {
                        const large = this.state.isLarge;
                        this.setState({isLarge: !large})
                    }} className='img_view'>
                        <img src={this.state.imgurl}/>
                    </div> : ''
                }
            </div>
        )
    }
}

export default createForm()(QingJiaCheck)