import {postRequest} from '../../../utils/utils';
import {Toast} from 'antd-mobile';
// ------------------------------------
// Constants
// ------------------------------------
export const JBBUKA_CHECK = 'JBBUKA_CHECK'

// ------------------------------------
// Actions
// ------------------------------------


export const checkJBBuka = (body, history) => {
    return async (dispatch, getState) => {
        postRequest(`/jiabanbk/check_save`, body).then((value) => {
            //console.log(value);
            if (value.status === 0) {
                Toast.info(value.msg, 1);
                if (value.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            } else {
                history.push('/JiabBanbuka/list');
                Toast.success(value.msg, 1);
            }
        })
    }
}
export const upload = (body, history) => {
    return async (dispatch, getState) => {
        return postRequest(`/jiabanbk/upload`, body).then((value) => {
           if(value){
               if (value.status === 0) {
                   Toast.info(value.msg, 2);
                   return null
               } else {
                   return value
               }
           }
            else {
               Toast.info('上传图片接口错误', 2);
               return null
           }
        })
    }
}

