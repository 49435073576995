import React from 'react'
import {Switch, Route, HashRouter as Router, Redirect} from 'react-router-dom'
import {isLogin} from '../utils/utils.js'
import Login from './login'
import Home from './home'
import Record from './record'
import Setting from './setting/list'

import QingjiaList from './qingjia/list'
import QingjiaEditor from './qingjia/editor'
import QingjiaDetail from './qingjia/detail'
import QingjiaCheck from './qingjia/check'

import JiabanList from './jiaban/list'
import JiabanEditor from './jiaban/editor'
import JiabanDetail from './jiaban/detail'
import JiabanCheck from './jiaban/check'

import BukaList from './buka/list'
import BukaDetail from './buka/detail'
import BukaEditor from './buka/editor'
import BukaCheck from './buka/check'

import AmapSearch from './components/amapSearch'
import TempDaka from './tempDaka'
import HomeIndex from './shouye'
import MyInfo from './myinfo'

import WaiChuList from './waichu/list'
import WaiChuEditor from './waichu/editor'
import WaiChuDetail from './waichu/detail'
import WaiChuCheck from './waichu/check'

import TongJi from './tongji'
import QueKa from './queka'
import Help from './help'
import ChuQing from './chuqing'
import SelectCheckBoxs from './components/checkboxs'

import JBBukaList from './JiabBanbuka/list'
import JBBukaDetail from './JiabBanbuka/detail'
import JBBukaEditor from './JiabBanbuka/editor'
import JBBukaCheck from './JiabBanbuka/check'

import JiaBanYCList from './JiabBanYanChang/list'
import JiaBanYCDetail from './JiabBanYanChang/detail'
import JiaBanYCEditor from './JiabBanYanChang/editor'
import JiaBanYCCheck from './JiabBanYanChang/check'

import EditPassword from './editpassword'

import WaiChuBackEditor from './waichuback/editor'
import WaiChuBackCheck from './waichuback/check'
import WaiChuBackList from './waichuback/list'

import XuJiaList from './xujia/list'
import XuJiaDetail from './xujia/detail'
import XuJiaEditor from './xujia/editor'
import XuJiaCheck from './xujia/check'

import QingJiaDelEditor from './qingjiadel/editor'
import QingJiaDelCheck from './qingjiadel/check'
import QingJiaDelList from './qingjiadel/list'

import XiaoJiaList from './xiaojia/list'
import XiaoJiaEditor from './xiaojia/editor'
import XiaoJiaCheck from './xiaojia/check'

import JiaBanMealsList from './jiabanMeals/list'
import JiaBanMealsEditor from './jiabanMeals/editor'
import JiaBanMealsDetail from './jiabanMeals/detail'
import JiaBanMealsCheck from './jiabanMeals/check'

import QingJisFileList from './qingjiafile/list'
import QingjisFileEditor from './qingjiafile/editor'
import QingJisFileDetail from './qingjiafile/detail'
import QingJisFileCheck from './qingjiafile/check'
import ZufangDaka from './zufangDaka'

import OpenRecord from './openRecord'
const Main = () => (

    <Router>
        {
            !localStorage.getItem('daka_token') ?
                localStorage.getItem('type_password') == 0 ?
                    <Switch>
                        <Route exact path='/editpassword' component={EditPassword}/>
                        <Redirect to="/editpassword"/>
                    </Switch> :
                    <Switch>
                        <Route exact path='/' component={Login}/>
                        <Redirect to="/"/>
                    </Switch> :
                <Switch>
                    <Route exact path='/home' component={Home}/>
                    <Route exact path='/record' component={Record}/>
                    <Route exact path='/setting' component={Setting}/>
                    <Route exact path='/qingjia/list' component={QingjiaList}/>
                    <Route exact path='/qingjia/editor' component={QingjiaEditor}/>
                    <Route exact path='/qingjia/detail' component={QingjiaDetail}/>
                    <Route exact path='/qingjia/check' component={QingjiaCheck}/>
                    <Route exact path='/jiaban/list' component={JiabanList}/>
                    <Route exact path='/jiaban/editor' component={JiabanEditor}/>
                    <Route exact path='/jiaban/detail' component={JiabanDetail}/>
                    <Route exact path='/jiaban/check' component={JiabanCheck}/>
                    <Route exact path='/buka/list' component={BukaList}/>
                    <Route exact path='/buka/detail' component={BukaDetail}/>
                    <Route exact path='/buka/editor' component={BukaEditor}/>
                    <Route exact path='/buka/check' component={BukaCheck}/>
                    <Route exact path='/components/amapSearch' component={AmapSearch}/>
                    <Route exact path='/tempDaka' component={TempDaka}/>
                    <Route exact path='/shouye' component={HomeIndex}/>
                    <Route exact path='/myinfo' component={MyInfo}/>
                    <Route exact path='/waichu/list' component={WaiChuList}/>
                    <Route exact path='/waichu/editor' component={WaiChuEditor}/>
                    <Route exact path='/waichu/detail' component={WaiChuDetail}/>
                    <Route exact path='/waichu/check' component={WaiChuCheck}/>
                    <Route exact path='/tongji' component={TongJi}/>
                    <Route exact path='/queka' component={QueKa}/>
                    <Route exact path='/help' component={Help}/>
                    <Route exact path='/chuqing' component={ChuQing}/>
                    <Route exact path='/components/checkboxs' component={SelectCheckBoxs}/>
                    <Route exact path='/JiabBanbuka/list' component={JBBukaList}/>
                    <Route exact path='/JiabBanbuka/detail' component={JBBukaDetail}/>
                    <Route exact path='/JiabBanbuka/editor' component={JBBukaEditor}/>
                    <Route exact path='/JiabBanbuka/check' component={JBBukaCheck}/>
                    <Route exact path='/JiabBanYanChang/list' component={JiaBanYCList}/>
                    <Route exact path='/JiabBanYanChang/detail' component={JiaBanYCDetail}/>
                    <Route exact path='/JiabBanYanChang/editor' component={JiaBanYCEditor}/>
                    <Route exact path='/JiabBanYanChang/check' component={JiaBanYCCheck}/>
                    <Route exact path='/editpassword' component={EditPassword}/>
                    <Route exact path='/waichuback/editor' component={WaiChuBackEditor}/>
                    <Route exact path='/waichuback/check' component={WaiChuBackCheck}/>
                    <Route exact path='/waichuback/list' component={WaiChuBackList}/>
                    <Route exact path='/xujia/list' component={XuJiaList}/>
                    <Route exact path='/xujia/detail' component={XuJiaDetail}/>
                    <Route exact path='/xujia/editor' component={XuJiaEditor}/>
                    <Route exact path='/xujia/check' component={XuJiaCheck}/>
                    <Route exact path='/qingjiadel/editor' component={QingJiaDelEditor}/>
                    <Route exact path='/qingjiadel/check' component={QingJiaDelCheck}/>
                    <Route exact path='/qingjiadel/list' component={QingJiaDelList}/>
                    <Route exact path='/xiaojia/editor' component={XiaoJiaEditor}/>
                    <Route exact path='/xiaojia/check' component={XiaoJiaCheck}/>
                    <Route exact path='/xiaojia/list' component={XiaoJiaList}/>
                    <Route exact path='/jiabanMeals/list' component={JiaBanMealsList}/>
                    <Route exact path='/jiabanMeals/editor' component={JiaBanMealsEditor}/>
                    <Route exact path='/jiabanMeals/detail' component={JiaBanMealsDetail}/>
                    <Route exact path='/jiabanMeals/check' component={JiaBanMealsCheck}/>
                    <Route exact path='/qingjiafile/list' component={QingJisFileList}/>
                    <Route exact path='/qingjiafile/editor' component={QingjisFileEditor}/>
                    <Route exact path='/qingjiafile/detail' component={QingJisFileDetail}/>
                    <Route exact path='/qingjiafile/check' component={QingJisFileCheck}/>
                    <Route exact path='/openRecord' component={OpenRecord}/>
                    <Route exact path='/zufangDaka' component={ZufangDaka}/>
                    <Redirect to="/shouye"/>
                </Switch>
        }
    </Router>
)

export default Main
