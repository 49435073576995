import React from 'react'
import PropTypes from 'prop-types'
import {
    NavBar,
    Icon,
    WhiteSpace,
    WingBlank,
    List,
    TextareaItem,
    Picker,
    Radio,
    Modal,
    Button,
    Tabs,
    Toast,
    InputItem,
    Steps
} from 'antd-mobile'
import {Link} from 'react-router-dom'
import {postRequest, getUserInfo} from '../../../utils/utils'
import 'antd-mobile/dist/antd-mobile.css'
import './index.css'
import moment from 'moment'


import daishenhe from '../../../images/icons/daishenhe.png'
import yitongguo from '../../../images/icons/yitongguo.png'
import shenpizhong from '../../../images/icons/shenpizhong.png'
import fushen from '../../../images/icons/fushen.png'
import yijujue from '../../../images/icons/yijujue.png'

const Item = List.Item
const Step = Steps.Step;
const RadioItem = Radio.RadioItem;
const tabs = [
    {title: '请假信息', sub: '1'},
    {title: '续假', sub: '2'},
    {title: '提前销假', sub: '3'},
    {title: '撤销', sub: '4'},
    {title: '证明材料', sub: '5'},
];
class QingjiaDetail extends React.Component {
    static propTypes = {
    }
    state = {
        data: {
            info: {},
            check:[],
            chexiao:[],
            tiqian_xiaojia:[],
            xujia:[],
            cailiao:[],
        },
        height: document.documentElement.clientHeight,
        state: '1',
        apper: '',
        opinion: '',
        show: false,
        paytotal: ''
    }

    componentDidMount = () => {
        this.getDetail()
    }
    getDetail = async () => {
        const qingjia_id = localStorage.getItem('qingjia_id');
        postRequest(`/qingjia/view`, `id=${qingjia_id}`).then((result) => {
            console.log(result)
            if (result && result.suc === 1) {
                this.setState({data: result.data})
            } else {
                Toast.fail(result.msg || '获取失败', 1);
                if (result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }
    //审批
    agree = async (status) => {
        const qingjia_id = localStorage.getItem('qingjia_id');
        let body = `id=${qingjia_id}&beizhu=${this.state.opinion}&status=${status}`
        postRequest(`/qingjia/checksave`, body).then((result) => {
            console.log(result)
            if (result && result.suc == 1) {
                Toast.success('审批成功', 1);
                this.setState({show: false})
                Toast.hide()
                this.props.history.goBack()
            } else {
                Toast.fail(result.msg || '审批失败', 1);
            }
        })
    }

    render() {
        const {data} = this.state
        const daka_user = JSON.parse(localStorage.getItem('daka_user'))
        const uId = daka_user.u_id
        return (
            <div style={{backgroundColor: '#f5f5f7', minHeight: document.documentElement.clientHeight}}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left"/>}
                    onLeftClick={() => this.props.history.goBack()}
                >请假详情</NavBar>
                <div>
                <Tabs swipeable={false} className='detail_tab' tabs={tabs}
                      initialPage={0}
                      onChange={(tab, index) => {
                          // console.log('onChange', index, tab);
                      }}
                >
                    <div className='tab_content'>
                        <List className="my-list detail_list" renderHeader=''>
                            <Item multipleLine wrap extra={data.starttime}>开始时间</Item>
                            <Item multipleLine wrap extra={data.endtime}>结束时间</Item>
                            <Item multipleLine wrap extra={data.type}>请假类型</Item>
                            {/*<Item multipleLine wrap extra={`${data.xiaoshi}小时`}>请假时长</Item>*/}
                            <Item multipleLine wrap extra={data.u_fullname}>申请人</Item>
                            {data.qj_type==4?
                                <Item multipleLine wrap extra={data.guanxi}>亲属关系</Item>:''
                            }
                            <Item multipleLine wrap extra={data.shiyou}>申请事由</Item>

                                <Item multipleLine wrap extra={data.zt}>审批状态</Item>


                        </List>
                        {data.type == '事假' ? '' :
                            <div className='img_box'>
                                <p>证明材料</p>
                                {
                                    data.imgurl===null?'暂无材料':
                                    <img onClick={() => {
                                        const large = this.state.isLarge;
                                        this.setState({isLarge: !large});
                                        this.setState({imgurl: data.imgurl})
                                    }} src={data.imgurl} alt=""/>
                                }
                            </div>
                        }
                        {data.type=='特批出勤'&&
                            <div className='img_box'>
                                <p>核检结果</p>
                                {
                                    data.tpurl &&
                                    <img onClick={() => {
                                        const large = this.state.isLarge;
                                        this.setState({isLarge: !large});
                                        this.setState({imgurl: data.tpurl})
                                    }} src={data.tpurl} alt=""/>
                                }
                            </div>
                        }
                        {data.check.length>0?
                            <List renderHeader={() => '审核意见'} className="my-list">
                                <Steps className='check_step' size="small" current={5}>
                                    {data.check == null ? '' :
                                        data.check.map(i => (i.agree_id == null ? '' :
                                                <Step key={i.id} status={i.status == '未通过' ?  'error' : 'process'}
                                                      title=
                                                          {<div className='check_user'>
                                                              <span>{i.agree_id}</span>
                                                              <span className='check_time'>{i.infotime}</span>
                                                          </div>}
                                                      description={<div><span className='check_status'>{i.status_name}</span>
                                                          {i.beizhu == '' ? '' : <span>({i.beizhu})</span>}</div>}
                                                />
                                        ))
                                    }
                                </Steps>
                            </List>:''
                        }
                    </div>
                    <div className='tab_content'>
                        {data.xujia.length > 0 ?
                            <div className='detail_item'>
                                {data.xujia.map((item,index)=>(
                                    <div className='new_list'>
                                        <div className="new_list_title">
                                            <span>申请信息</span>
                                            {item.status_name=='审批通过' ?
                                                <span style={{color: '#1296db'}}>{item.status_name}</span>:
                                                <span style={{color: '#ff8e2b'}}>{item.status_name}</span>
                                            }
                                        </div>
                                        <div className="new_list_content">
                                            <div className="new_list_item">
                                                <p>申请人</p>
                                                <div>{item.user_id}</div>
                                            </div>
                                            <div className="new_list_item">
                                                <p>申请时间</p>
                                                <div>{item.infotime}</div>
                                            </div>
                                            <div className="new_list_item">
                                                <p>续假时间</p>
                                                <div>{item.jieshutime}</div>
                                            </div>
                                            <div className="new_list_item">
                                                <p>续假时长</p>
                                                <div>{item.hour}小时</div>
                                            </div>
                                            <div className="new_list_item">
                                                <p>续假事由</p>
                                                <div>{item.content}</div>
                                            </div>
                                        </div>
                                        {item.record.length>0?
                                            <div>
                                                <div className="new_list_title"><span>审核信息</span></div>
                                                <Steps className='check_step' size="small" current={5}>
                                                    {item.record == null ? '' :
                                                        item.record.map(i => (i.check_id == null ? '' :
                                                                <Step key={i.id} status={i.status == '未通过' ?  'error' : 'process'}
                                                                      title=
                                                                          {<div className='check_user'>
                                                                              <span>{i.check_id}</span>
                                                                              <span className='check_time'>{i.infotime}</span>
                                                                          </div>}
                                                                      description={<div><span className='check_status'>
                                                                          {i.status_name}</span>
                                                                          {i.content == '' ? '' : <span>({i.content})</span>}</div>}
                                                                />
                                                        ))
                                                    }
                                                </Steps>
                                            </div>:''
                                        }
                                    </div>
                                ))}
                            </div> :
                            <div className='detail_nodata'>
                                暂无续假申请
                            </div>
                        }
                    </div>
                    <div className='tab_content list_tab'>
                        {data.tiqian_xiaojia.length > 0 ?
                            <div className='detail_item'>
                                {data.tiqian_xiaojia.map((item,index)=>(
                                    <div className='new_list'>
                                        <div className="new_list_title">
                                            <span>申请信息</span>
                                            {item.status_name=='审批通过' ?
                                                <span style={{color: '#1296db'}}>{item.status_name}</span>:
                                                <span style={{color: '#ff8e2b'}}>{item.status_name}</span>
                                            }
                                        </div>
                                        <div className="new_list_content">
                                            <div className="new_list_item">
                                                <p>申请人</p>
                                                <div>{item.user_id}</div>
                                            </div>
                                            <div className="new_list_item">
                                                <p>申请时间</p>
                                                <div>{item.infotime}</div>
                                            </div>
                                            <div className="new_list_item">
                                                <p>提前日期</p>
                                                <div>{item.endtime}</div>
                                            </div>
                                            <div className="new_list_item">
                                                <p>事由</p>
                                                <div>{item.content}</div>
                                            </div>
                                        </div>
                                        {item.record.length>0?
                                            <div>
                                                <div className="new_list_title"><span>审核信息</span></div>
                                                <Steps className='check_step' size="small" current={5}>
                                                    {item.record == null ? '' :
                                                        item.record.map(i => (i.check_id == null ? '' :
                                                                <Step key={i.id} status={i.status == '未通过' ?  'error' : 'process'}
                                                                      title=
                                                                          {<div className='check_user'>
                                                                              <span>{i.check_id}</span>
                                                                              <span className='check_time'>{i.infotime}</span>
                                                                          </div>}
                                                                      description={<div><span className='check_status'>
                                                                          {i.status_name}</span>
                                                                          {i.content == '' ? '' : <span>({i.content})</span>}</div>}
                                                                />
                                                        ))
                                                    }
                                                </Steps>
                                            </div>:''
                                        }
                                    </div>
                                ))}
                            </div>  :
                            <div className='detail_nodata'>
                                暂无提前销假申请
                            </div>
                        }
                    </div>
                    <div className='tab_content list_tab'>
                        {data.chexiao.length > 0 ?
                            <div className='detail_item'>
                                {data.chexiao.map((item,index)=>(
                                    <div className='new_list'>
                                        <div className="new_list_title">
                                            <span>申请信息</span>
                                            {item.status_name=='审批通过' ?
                                                <span style={{color: '#1296db'}}>{item.status_name}</span>:
                                                <span style={{color: '#ff8e2b'}}>{item.status_name}</span>
                                            }
                                        </div>
                                        <div className="new_list_content">
                                            <div className="new_list_item">
                                                <p>申请人</p>
                                                <div>{item.user_id}</div>
                                            </div>
                                            <div className="new_list_item">
                                                <p>申请时间</p>
                                                <div>{item.infotime}</div>
                                            </div>
                                            <div className="new_list_item">
                                                <p>撤销事由</p>
                                                <div>{item.content}</div>
                                            </div>
                                        </div>
                                        {item.record.length>0?
                                            <div>
                                                <div className="new_list_title"><span>审核信息</span></div>
                                                <Steps className='check_step' size="small" current={5}>
                                                    {item.record == null ? '' :
                                                        item.record.map(i => (i.check_id == null ? '' :
                                                                <Step key={i.id} status={i.status == '未通过' ?  'error' : 'process'}
                                                                      title=
                                                                          {<div className='check_user'>
                                                                              <span>{i.check_id}</span>
                                                                              <span className='check_time'>{i.infotime}</span>
                                                                          </div>}
                                                                      description={<div><span className='check_status'>
                                                                          {i.status_name}</span>
                                                                          {i.content == '' ? '' : <span>({i.content})</span>}</div>}
                                                                />
                                                        ))
                                                    }
                                                </Steps>
                                            </div>:''
                                        }
                                    </div>
                                ))}
                            </div> :
                            <div className='detail_nodata'>
                                暂无撤销申请
                            </div>
                        }
                    </div>
                    <div className='tab_content list_tab'>
                        {data.cailiao.length > 0 ?
                            <div className='detail_item'>
                                {data.cailiao.map((item,index)=>(
                                    <div className='new_list'>
                                        <div className="new_list_title">
                                            <span>申请信息</span>
                                            {item.status_name=='审批通过' ?
                                                <span style={{color: '#1296db'}}>{item.status_name}</span>:
                                                <span style={{color: '#ff8e2b'}}>{item.status_name}</span>
                                            }
                                        </div>
                                        <div className="new_list_content">
                                            <div className="new_list_item">
                                                <p>申请人</p>
                                                <div>{item.user_id}</div>
                                            </div>
                                            <div className="new_list_item">
                                                <p>申请时间</p>
                                                <div>{item.infotime}</div>
                                            </div>
                                            <div className="new_list_item img_box">
                                                <p>证明材料</p>
                                                {
                                                    item.imgurl &&
                                                    <img onClick={() => {
                                                        const large = this.state.isLarge;
                                                        this.setState({isLarge: !large});
                                                        this.setState({imgurl: item.imgurl})
                                                    }} src={item.imgurl} alt=""/>
                                                }
                                            </div>
                                            {
                                                data.type=='特批出勤'&&
                                                <div className="new_list_item img_box">
                                                    <p>核酸检测结果</p>
                                                    {
                                                        item.tpurl &&
                                                        <img onClick={() => {
                                                            const large = this.state.isLarge;
                                                            this.setState({isLarge: !large});
                                                            this.setState({imgurl: item.tpurl})
                                                        }} src={item.tpurl} alt=""/>
                                                    }
                                                </div>
                                            }

                                            <div className="new_list_item">
                                                <p>材料描述</p>
                                                <div>{item.content}</div>
                                            </div>
                                        </div>
                                        {item.record.length>0?
                                            <div>
                                                <div className="new_list_title"><span>审核信息</span></div>
                                                <Steps className='check_step' size="small" current={5}>
                                                    {item.record == null ? '' :
                                                        item.record.map(i => (i.check_id == null ? '' :
                                                                <Step key={i.id} status={i.status == '未通过' ?  'error' : 'process'}
                                                                      title=
                                                                          {<div className='check_user'>
                                                                              <span>{i.check_id}</span>
                                                                              <span className='check_time'>{i.infotime}</span>
                                                                          </div>}
                                                                      description={<div><span className='check_status'>
                                                                          {i.status_name}</span>
                                                                          {i.content == '' ? '' : <span>({i.content})</span>}</div>}
                                                                />
                                                        ))
                                                    }
                                                </Steps>
                                            </div>:''
                                        }
                                    </div>
                                ))}
                            </div> :
                            <div className='detail_nodata'>
                                暂无材料提交信息
                            </div>
                        }
                    </div>
                </Tabs>
                </div>

                <div style={{height:'50px'}}></div>
                {this.state.isLarge ?
                    <div onClick={() => {
                        const large = this.state.isLarge;
                        this.setState({isLarge: !large})
                    }} className='img_view'>
                        <img src={this.state.imgurl}/>
                    </div> : ''
                }
            </div>
        )
    }
}

export default QingjiaDetail