import { connect } from 'react-redux'
import { getQingjia, getOverTime,deleteQingjia } from './actions'
import Setting from './main'


const mapDispatchToProps = {
  getQingjia,
  getOverTime,
    deleteQingjia
}
const mapStateToProps = (state) => {
  return {
    ...state.QingjiaList
  }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
