import React from 'react'
import PropTypes from 'prop-types'
import {
    List,
    TextareaItem,
    Button,
    InputItem,
    NavBar,
    Checkbox,
    Icon,
    WhiteSpace,
    Picker,
    Toast,
    DatePicker,
    WingBlank
} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import {createForm} from 'rc-form'
import {postRequest} from '../../../utils/utils'
import './index.css'
import moment from 'moment'
import CustomerSearch from '../../components/customer_search'
import AmapSearch from '../../components/amapSearch'

const Item = List.Item;

class JiaBanBukaEditor extends React.Component {
    static propTypes = {
        form: PropTypes.object.isRequired,
        getApper: PropTypes.func.isRequired,
        apper: PropTypes.array.isRequired,
        saveJBBuka: PropTypes.func.isRequired,
    }

    state = {
        files: null,
        start_time: '',
        end_time: '',
        apper: [],
        check_username: '',
        content: '',
        buka_id: '',
        showMap: false,
        time1:'',
        time2:'',
        type:0,//补卡类型1补开始按钮，2补结束按钮，3是两个按钮。
    };

    componentWillMount() {
        const jiabanbuka_id = localStorage.getItem('jiabanbuka_id');
        const jiaban_id = localStorage.getItem('jiaban_id');

        this.setState({buka_id: jiabanbuka_id});
        if (jiabanbuka_id !== '')//判断是编辑还是新增
        {
            this.getDetail(jiabanbuka_id);
        }
        else {
            const data = localStorage.getItem('applytime');
            const time1 = data.split('|')[0];
            const time2 = data.split('|')[1];
            this.setState({time1:time1,time2:time2});
            this.getJiaBan(jiaban_id, time1, time2)
        }
    }

    componentDidMount() {
        //this.props.getApper();
    }

    //获取修改数据
    getDetail = async (buka_id) => {
        postRequest(`/jiabanbk/edit`, `id=${buka_id}`).then((result) => {
            //console.log(result)
            if (result && result.status === 1) {
                this.setState({
                    start_time: new Date(result.data.starttime),
                    end_time: new Date(result.data.endtime),
                    content: result.data.content,
                    apper: [result.data.check_id],
                    check_username: result.data.u_fullname,
                    time1:result.data.time1,
                    time2:result.data.time2,
                    type:result.data.type||0
                });
            } else {
                Toast.info(result.msg || '获取失败', 1);
                if (result.msg === '请登陆！') {
                    localStorage.removeItem('daka_token')
                    alert('账号已在别处登录,请重新登录')
                    this.props.history.push('/')
                }
            }
        })
    }
    //获取加班数据
    getJiaBan = async (buka_id, time1, time2) => {
        //console.log(`id=${buka_id}&time1=${time1}&time2=${time2}`)
        postRequest(`/jiabanbk/look`, `id=${buka_id}&time1=${time1}&time2=${time2}`).then((result) => {
           // console.log(result);
            if (result && result.status === 1) {
                let type
                if(result.data.starttime=='')type=1
                if(result.data.endtime=='')type=2
                if(result.data.endtime==''&&result.data.starttime=='')type=3
                this.setState({
                    start_time: result.data.starttime?new Date(result.data.starttime):null,
                    end_time: result.data.endtime?new Date(result.data.endtime):null,
                    type:type
                });
            }
        })
    }

    onSubmit = () => {
        const {validateFields} = this.props.form
        const {start_time, end_time, type, content,time1,time2} = this.state
        const {files, history, saveJBBuka} = this.props
        const jiaban_id = localStorage.getItem('jiaban_id');
        validateFields((err, values) => {
            let body = `jb_id=${jiaban_id}&type=${type}`
            if (start_time) {
                body += `&starttime=${moment(start_time).format('YYYY-MM-DD HH:mm')}`
            } else {
                Toast.info('加班开始时间为必填项', 1);
                return
            }
            if (end_time) {
                body += `&endtime=${moment(end_time).format('YYYY-MM-DD HH:mm')}`
            } else {
                Toast.info('加班结束时间为必填项', 1);
                return
            }
            // if(apper && apper[0]) {
            //   body += `&check_id=${apper[0]}`
            // } else {
            //     Toast.info('审批人为必填项', 1);
            //   return
            // }
            if (content) {
                body += `&content=${content}`
            } else {
                Toast.info('加班补卡事由为必填项', 1);
                return
            }
            if (!err) {
                //Toast.loading('新建中, 请稍等....')
                if (this.state.buka_id != '') {
                    body += `&id=${this.state.buka_id}`
                }
                body += `&time1=${time1}&time2=${time2}`
                console.log(body);
                saveJBBuka(body, history)
            }
        });
    }

    render() {
        const {getFieldProps} = this.props.form;
        const {ctype, detail} = this.props;
        let apper = [];
        apper = this.props.apper.map((value, index) => {
            return {
                value: value.u_id,
                label: value.u_fullname
            }
        })

        return (
            <div className='index-content' id='wipeout_editor'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left"/>}
                    onLeftClick={() => this.props.history.goBack()}
                >加班补卡申请</NavBar>
                <List className="picker-list my_list" renderHeader=''>

                    <DatePicker
                        mode="datetime"
                        title="选择开始时间"
                        extra="选择开始时间"
                        disabled={!(this.state.type==1||this.state.type==3)}
                        value={this.state.start_time}
                        onChange={date => {
                            this.setState({start_time: date});
                        }}
                    >
                        <List.Item arrow="horizontal">开始时间<span className="xing">*</span></List.Item>
                    </DatePicker>
                    <DatePicker
                        mode="datetime"
                        title="选择结束时间"
                        extra="选择结束时间"
                        disabled={!(this.state.type==2||this.state.type==3)}
                        value={this.state.end_time}
                        onChange={date => {
                            this.setState({end_time: date});
                        }}
                    >
                        <List.Item arrow="horizontal">结束时间<span className="xing">*</span></List.Item>
                    </DatePicker>
                    {/*<CustomerSearch selectvalue={this.state.apper} initialValue={this.state.check_username} customers={apper} onOk={(v) => {*/}
                    {/*{this.setState({ apper: v })}*/}
                    {/*}}  name='审批人'/>*/}
                </List>
                <List renderHeader='' className="qj-list">
                    <TextareaItem
                        value={this.state.content}
                        onChange={value => this.setState({content: value})}
                        placeholder="请填写事由"
                        data-seed="logId"
                        rows={4}
                        ref={el => this.customFocusInst = el}
                    />
                </List>
                <WhiteSpace/>
                <WhiteSpace/>
                <WhiteSpace/>
                <WingBlank size="md">
                    <Button type='primary' onClick={() => this.onSubmit()}>提交</Button>
                </WingBlank>
                <div className='daka_rule'>
                    <span>注意事项</span>
                    <p>
                        1）主管审核通过后，需本人到门卫室协助查监控<br/>
                        2）审核通过后，会同步加班时长<br/>
                        3）时间不可选即为不可补卡，如：打过开始卡，补卡时开始时间不可选<br/>
                    </p>
                </div>
            </div>
        )
    }
}

export default createForm()(JiaBanBukaEditor)