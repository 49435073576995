import React from 'react'
import PropTypes from 'prop-types'
import {Flex, WingBlank, WhiteSpace, NavBar, Icon, Toast, ListView, List,Button} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {postRequest, getUserInfo} from '../../utils/utils'


import './index.css'

const Item = List.Item;
const Brief = Item.Brief;
const dataSource = new ListView.DataSource({  //这个dataSource有cloneWithRows方法
    rowHasChanged: (row1, row2) => row1 !== row2,
})

class OpenRecord extends React.Component {
    static propTypes = {}

    state = {
        height: document.documentElement.clientHeight,
        dataSource,
        listdata: [],
        hasdata: true,
        isLarge: false,  //是否放大
        imgurl: '' //放大图片地址
    };

    componentDidMount() {
        this.getqueka();
    }

    getqueka = async () => {
        postRequest(`/paizhao/getlist`, '').then((result) => {
            console.log(result);
            if (result.status == 1) {
                this.setState({listdata: result.data});
                this.setState({hasdata: true});
            }
            else
            {
                this.setState({hasdata: false});
            }
        })
    }

    render() {
        const {listdata, hasdata} = this.state;
        const separator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    backgroundColor: '#F5F5F9',
                    height: 10,
                }}
            />
        );
        const nodata = () => {
            return (
                <div className='nodata'>
                </div>
            );
        }
        const row = (rowData, sectionID, rowID) => {
            return (
                <div>
                    <div className='list_item' key={rowID} style={{padding: '10px'}}>
                        <div className='list_img_left'>
                            {
                                rowData.imgurl &&
                                <img onClick={() => {
                                    const large = this.state.isLarge;
                                    this.setState({isLarge: !large});
                                    this.setState({imgurl: rowData.imgurl})
                                }} src={rowData.imgurl} alt=""/>
                            }
                        </div>
                        <div className='list_left'>
                            <h3>{rowData.dakatime}</h3>
                            <p>{rowData.infotime}</p>
                        </div>
                    </div>

                </div>
            );
        };
        return (
            <div className='index-content'>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left"/>}
                    onLeftClick={() => this.props.history.goBack()}
                >开门打卡记录</NavBar>

                <ListView
                    ref={el => this.lv = el}
                    dataSource={this.state.dataSource.cloneWithRows(listdata)}
                    renderRow={row}
                    style={{
                        height: this.state.height,
                        overflow: 'auto',
                    }}
                    renderSeparator={separator}
                    renderBodyComponent={!hasdata ? nodata : null} //无数据时显示
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}
                />
                <div style={{height: '53px'}}></div>
                {this.state.isLarge ?
                    <div onClick={() => {
                        const large = this.state.isLarge;
                        this.setState({isLarge: !large})
                    }} className='img_view'>
                        <img src={this.state.imgurl}/>
                    </div> : ''
                }
            </div>
        )
    }
}

export default OpenRecord
