import React from 'react'
import PropTypes from 'prop-types'
import {
    ListView,
    WhiteSpace,
    NavBar,
    Icon,
    SearchBar,
    Card,
    Button,
    SegmentedControl,
    Checkbox,
    Tabs,
    Toast
} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import {postRequest, getUserInfo} from '../../../utils/utils'
import BottomTab from '../../components/bottomTab'
import './index.css'

import {createForm} from 'rc-form'

//图片引入

import list_ico from '../../../images/list_ico.png'

const CheckboxItem = Checkbox.CheckboxItem
const tabs2 = [
    {title: '申请列表', sub: '1'},
    {title: '审批列表', sub: '2'}
];
const dataSource = new ListView.DataSource({  //这个dataSource有cloneWithRows方法
    rowHasChanged: (row1, row2) => row1 !== row2,
})

class BukaList extends React.Component {
    static propTypes = {
        getBuka: PropTypes.func.isRequired,
        getBukaChecklist: PropTypes.func.isRequired,
        deleteBuka: PropTypes.func.isRequired
    }
    state = {
        height: document.documentElement.clientHeight-70,
        dataSource,
        hasdata: true,
        hascheckdata: true,
        tabindex: 0,  //tab选项卡索引判断是申请还是审核列表(0:申请列表；1：审核列表)
        buka_id: '',
        hasMore: true,
        page_size: 1,
        hasMore2: true,
        page_size2: 1,
        isLarge: false,  //是否放大
        imgurl: '' //放大图片地址
    }

    componentWillMount() {
        const index = this.props.location.state
        if (index == undefined) {
            this.setState({tabindex: 0})
        }
        else {
            this.setState({tabindex: 1})
        }
    }

    //完成挂载
    componentDidMount() {
        const index = this.props.location.state
        if (index == undefined) {
            this.props.getBuka()
        }
        else {
            this.props.getBukaChecklist()
        }
    }

    //删除补卡
    delete = async (bukaid) => {
        //this.setState({buka_id:bukaid});
        const {history, deleteBuka} = this.props
        let body = `id=${bukaid}`
        deleteBuka(body, history);
    }

    onEndReached1 = () => {
        const {page_size} = this.state
        const pagesize=Math.ceil(this.props.count/20) //页数
        let p=page_size;
        if (!this.state.hasMore) {
            return
        }
        else {
            if (p>=pagesize) {
                this.setState({hasMore: false})
            }
            else
            {
                this.setState({hasMore: true})
                p = page_size + 1;
                this.setState({page_size:p})
                const oldData = this.props.listdata;
                this.props.getBuka(p, oldData)
            }
        }
    }
    onEndReached2 = () => {
        const {page_size2,hasMore2} = this.state
        const pagesize=Math.ceil(this.props.count2/20) //页数
        let p=page_size2;
        if (!hasMore2) {
            return
        }
        else {
            if (p>=pagesize) {
                this.setState({hasMore2: false})
            }
            else
            {
                this.setState({hasMore2: true})
                p = page_size2 + 1;
                this.setState({page_size2:p})
                const oldData = this.props.checklistdata;
                this.props.getBukaChecklist(p, oldData)
            }
        }
    }

    render() {
        const {listdata, hasdata, hascheckdata, checklistdata} = this.props;
        const user = JSON.parse(localStorage.getItem('daka_user'))
        const uid = user.uid;
        //console.log(user);
        const separator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    backgroundColor: '#F5F5F9',
                    height: 10,
                }}
            />
        );
        const nodata = () => {
            return (
                <div className='nodata'>
                </div>
            );
        }
        const row = (rowData, sectionID, rowID) => {
            //console.log(rowData)
            const time = new Date(Number(rowData.shijian + '000'))
            const date = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()} ${time.getHours()}:${time.getMinutes()}`
            let stateSpan = ""
            // if (rowData.status === 0) {
            //     stateSpan = <span style={{fontSize: 16, color: '#FF8E2B'}}>待主管审核</span>
            // }
            if (rowData.status === 0||rowData.status === 3||rowData.status === 4) {
                stateSpan = <span style={{fontSize: 16, color: '#FF8E2B'}}>待{rowData.check_name}审核</span>
            }
            // if (rowData.status === 4) {
            //     stateSpan = <span style={{fontSize: 16, color: '#FF8E2B'}}>待门卫审核</span>
            // }
            if (rowData.status === 1) {
                stateSpan = <span style={{fontSize: 16, color: '#1296db'}}>已通过</span>
            }
            if (rowData.status === 5) {
                stateSpan = <span style={{fontSize: 16, color: '#1296db'}}>待{rowData.check_name}登记</span>
            }
            if (rowData.status === 2) {
                stateSpan = <span style={{fontSize: 16, color: '#ff4d4f'}}>未通过</span>
            }
            if (rowData.status === 6) {
                stateSpan = <span style={{fontSize: 16, color: '#1296db'}}>审批超时关闭</span>
            }
            return (
                <Card>
                    <Card.Header
                        extra={stateSpan}
                        title={`申请人: ${rowData.user_id}`}
                        style={{
                            fontSize: 15
                        }}
                    />

                    <Card.Body style={{padding: 10, paddingLeft: 15, display: 'flex', flexDirection: 'row'}}
                              >
                        <div className='list_txcontent large_width'>
                        {this.state.tabindex==0?'':
                            <div className='list_tx'>
                                {
                                    rowData.touxiang &&
                                    <img onClick={() => {
                                        const large = this.state.isLarge;
                                        this.setState({isLarge: !large});
                                        this.setState({imgurl: rowData.touxiang})
                                    }} src={rowData.touxiang} alt=""/>
                                }
                            </div>}
                        <div onClick={() => {
                            localStorage.setItem('buka_id', rowData.id)
                            this.state.tabindex == 0 ?
                                this.props.history.push({pathname: '/buka/detail'}) :
                                this.props.history.push({pathname: '/buka/check'})
                        }} style={{flex: '1'}}>
                            <div className='card-body-list qj_list_item flex_title'>
                                {this.state.tabindex==0?
                                    <img src={list_ico} />:''
                                }
                                <p className='list_time'>{rowData.shijian}</p>
                                <span className='remark'>{rowData.type}</span>
                            </div>
                            <div className='card-body-list little_text'>{`原因： ${rowData.content}`}</div>
                            {this.state.tabindex ==0&&rowData.check_name!='' ?
                            <div className='card-body-list'>{`待审核人： ${rowData.check_name}`}</div>:''
                        }

                        </div>
                        </div>
                    </Card.Body>
                    {
                        rowData.status === 0 && this.state.tabindex == 0 ?
                            <Card.Footer className='list_footer' content={<div onClick={() => {
                                localStorage.setItem('buka_id', rowData.id);
                                this.props.history.push({pathname: '/buka/editor', qingjia_id: rowData.id})
                            }}>编辑</div>}
                                         extra={<div onClick={() => {
                                             this.delete(rowData.id)
                                         }}>删除</div>}>
                            </Card.Footer> : ''
                    }
                </Card>

            );
        };

        return (
            <div id='wipeout-list' style={{backgroundColor: '#f5f5f9'}}>
                <NavBar
                    mode="dark"
                    icon={<Icon type="left"/>}
                    onLeftClick={() => this.props.history.goBack()}
                    rightContent={
                        <Link to='/buka/editor' onClick={() => {
                            localStorage.setItem('buka_id', '');
                        }} style={{color: '#fff', paddingTop: 3}}><Icon key="0" type="plus" size='xs'/></Link>
                    }
                >补卡列表</NavBar>
                <Tabs swipeable={false} tabs={tabs2}
                      initialPage={this.state.tabindex}
                      onChange={(tab, index) => {
                          if (index === 0) {
                              this.props.getBuka()
                              this.setState({tabindex: 0})
                          }
                          if (index === 1) {
                              this.props.getBukaChecklist()
                              this.setState({tabindex: 1});
                          }
                      }}
                >
                    <div>
                        <ListView
                            ref={el => this.lv = el}
                            dataSource={this.state.dataSource.cloneWithRows(listdata)}
                            renderRow={row}
                            style={{
                                height: this.state.height,
                                overflow: 'auto',
                            }}
                            initialListSize={20}
                            scrollEventThrottle={20}
                            renderSeparator={separator}
                            renderBodyComponent={!hasdata ? nodata : null} //无数据时显示
                            scrollRenderAheadDistance={500}
                            onEndReached={this.onEndReached1}
                            onEndReachedThreshold={20}
                            renderFooter={() => (
                                <p>{hasdata ? (this.state.hasMore&&listdata.length>=20 ? '加载中..., 请稍等' : '已经全部加载完毕') : ''}</p>
                            )}
                        />

                    </div>
                    <div>
                        <ListView
                            ref={el => this.lv = el}
                            dataSource={this.state.dataSource.cloneWithRows(checklistdata)}
                            renderRow={row}
                            style={{
                                height: this.state.height,
                                overflow: 'auto',
                            }}
                            renderSeparator={separator}
                            renderBodyComponent={!hascheckdata ? nodata : null} //无数据时显示
                            onEndReached={this.onEndReached2}
                            onEndReachedThreshold={20}
                            renderFooter={() => (
                                <p>{hascheckdata ? (this.state.hasMore2&&checklistdata.length>=20 ? '加载中..., 请稍等' : '已经全部加载完毕') : ''}</p>
                            )}
                        />

                    </div>
                </Tabs>
                {this.state.isLarge ?
                    <div onClick={() => {
                        const large = this.state.isLarge;
                        this.setState({isLarge: !large})
                    }} className='img_view'>
                        <img src={this.state.imgurl}/>
                    </div> : ''
                }
                <BottomTab selectedTab='buka' badge={listdata.pendnum}/>
            </div>
        )
    }
}

export default BukaList

