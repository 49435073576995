import React from 'react'
import PropTypes from 'prop-types'
import {Flex, WingBlank, Button, NavBar, List, Toast, Icon, ListView, DatePicker, Picker} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'

import Work from '../../images/work.png'
import Evection from '../../images/evection.png'
import {postRequest} from '../../utils/utils.js'
import moment from 'moment'

import './index.css'

import {nodataico} from '../../images/ico2.png'

const dataSource = new ListView.DataSource({  //这个dataSource有cloneWithRows方法
    rowHasChanged: (row1, row2) => row1 !== row2,
})
const Item = List.Item
const Brief = Item.Brief;
class ChuQingList extends React.Component {

    static propTypes = {}

    state = {
        dataSource,
        data: [],
        height: document.documentElement.clientHeight,
        hasdata:true,
        type: '',
    };

    componentDidMount() {
        const type = localStorage.getItem('type');
        const body = localStorage.getItem('body');
        this.setState({type:type});
        if(type==1){
            this.getchidao(body);
        }
        if(type==2)
            this.getzaotui(body)
        if(type==3)
            this.getChuChai(body)
        if(type==4)
            this.getWaiChu(body)
        if(type==5)
            this.getbuka(body)
        if(type==6)
            this.getqingjia(body)
        if(type==7)
            this.getjiaban(body)
    }

    //统计迟到
    getchidao = async (body) => {
        postRequest(`/zongji/chidao`, body).then((result) => {
            if(result.data!=null&&result.data.length>0){
                this.setState({data: result.data});
                this.setState({hasdata: true});
            }
            else{
                this.setState({hasdata: false});
            }
        })
    }
    //统计早退
    getzaotui = async (body) => {
        postRequest(`/zongji/zaotui`, body).then((result) => {
            console.log(result);
            if(result.data!=null&&result.data.length>0){
                this.setState({data: result.data});
                this.setState({hasdata: true});
            }
            else{
                this.setState({hasdata: false});
            }
        })
    }
    //统计出差打卡次数
    getChuChai = async (body) => {
        postRequest(`/zongji/chuchai`, body).then((result) => {
            console.log(result);
            if(result.data!=null&&result.data.length>0){
                this.setState({data: result.data});
                this.setState({hasdata: true});
            }
            else{
                this.setState({hasdata: false});
            }
        })
    }
    //统计外出打卡次数
    getWaiChu = async (body) => {
        postRequest(`/zongji/waichu`, body).then((result) => {
            if(result.data!=null&&result.data.length>0){
                this.setState({data: result.data});
                this.setState({hasdata: true});
            }
            else{
                this.setState({hasdata: false});
            }
        })
    }
    //统计补卡
    getbuka = async (body) => {
        postRequest(`/zongji/buka`, body).then((result) => {
            if(result.data!=null&&result.data.length>0){
                this.setState({data: result.data});
                this.setState({hasdata: true});
            }
            else{
                this.setState({hasdata: false});
            }
        })
    }

    //统计请假
    getqingjia = async (body) => {
        postRequest(`/zongji/qingjia`, body).then((result) => {
            if(result.data!=null&&result.data.length>0){
                this.setState({data: result.data});
                this.setState({hasdata: true});
            }
            else{
                this.setState({hasdata: false});
            }
        })
    }
    //统计加班
    getjiaban = async (body) => {
        postRequest(`/zongji/jiaban`, body).then((result) => {
            console.log(result)
            if(result.data!=null&&result.data.length>0){
                this.setState({data: result.data});
                this.setState({hasdata: true});
            }
            else{
                this.setState({hasdata: false});
            }
        })
    }


    render() {
        const {data,type} = this.state;
        const separator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    backgroundColor: '#F5F5F9',
                    height: 5,
                    borderTop: '1px solid #ECECED',
                    borderBottom: '1px solid #ECECED',
                }}
            />
        );
        const row = (rowData, sectionID, rowID) => {
            let time;
            let address;
           if(rowData!=null) {
               if(type==3)
               {
                   time = rowData.dakatime;
                   address=rowData.address;
               }
           else
               {
                   time = rowData.split('|')[0]
               }
           }
            return (
                <div>
                    <List className="my-list warp_list">
                        <Item>{time}
                            <Brief>{address}</Brief></Item>
                    </List>
                </div>
            );
        };
        const nodata = () => {
            return (
                <div className='nodata'>
                </div>
            );
        }
        return (
            <div className='index-content' id='record'>
              <NavBar
                  mode="dark"
                  icon={<Icon type="left"/>}
                  onLeftClick={() => {
                      this.props.history.goBack()
                  }}
              >统计详情</NavBar>
              <ListView
                  ref={el => this.lv = el}
                  dataSource={this.state.dataSource.cloneWithRows(data)}
                  renderRow={row}
                  renderSeparator={separator}
                  renderBodyComponent={!this.state.hasdata ? nodata : null} //无数据时显示
                  style={{
                      height: this.state.height,
                      overflow: 'auto',
                  }}
                  initialListSize={100}
              />
                {this.state.isLarge?
                    <div className='img_view'>
                      <img onClick={()=>{ const large=this.state.isLarge;this.setState({isLarge:!large})}} src={this.state.imgurl} />
                    </div>:''
                }
            </div>
        )
    }
}

export default ChuQingList
