import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import {Flex, WingBlank, WhiteSpace, NavBar, List, Icon, Toast, Button, Tabs, ImagePicker, Modal} from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'
import {Link} from 'react-router-dom'
import BottomTab from '../components/bottomTab'

import Work from '../../images/work.png'
import offwork from '../../images/offwork.png'
import Person from '../../images/person.png'
import address from '../../images/address.png'
import guize from '../../images/chuchai.png'
import loading from '../../images/loading.png'
import {postRequest, postRequest2, saveLog, backpromise, checkDaka} from '../../utils/utils.js'

import './index.css'

const Item = List.Item;
const Brief = Item.Brief;
var map, geolocation
const BMap = window.BMap;

const tabs2 = [
    {title: '上下班打卡', sub: '1'},
    {title: '市内外出', sub: '2'},
    {title: '出差打卡', sub: '3'},
    {title: '请假打卡', sub: '4'}
];
const alert = Modal.alert;
let isTime = false;//是否需要判断下班时间
class Index extends React.Component {
    static propTypes = {}

    state = {
        selectedTab: 'daka',
        clheight: '',
        data: [],
        files: [],  //市内外出打卡图片
        files2: [], //出差打卡图片
        files3: [],//上班打卡图片
        files4: [],//下班打卡图片
        files5: [],//下班打卡图片
        files6: [], //离襄打卡
        files7: [],//返襄打卡
        address: '',
        addressreal: '',
        province: '',//定位省份
        city: '',//定位城市
        qu:'',
        tabindex: 0,
        jingdu: '',
        weidu: '',
        btnstate1: true,
        btnstate2: true,
        amtime: '',//上班打卡时间
        pmtime: '',//下班打卡时间
        onworkTime: '',//上班时间
        offworkTime: '',//下班时间
        menwei: false,  //是否是门卫
        msg: '',  //打卡判定返回信息
        result: true,  //是否可以打卡
        lfresult: true, //离返襄是否可以打卡
        wcresult: false,
        ccresult: false,
        qjresult: false,
        isdaka: false, //是否询问打卡
        previewVisible: false,
        isweek: false,//是否周末
        disabledonwork: false, //上班打卡按钮是否可操作
        resultData: [], //打卡结束返回数据
        loadingTab: true,  //加载tab，切换tab时接口返回数据再加载tab里面的内容
    };

    componentDidMount() {
        //this.getServerDate();
        // const height=document.body.clientHeight;
        // this.setState({clheight:'height:'+height});
        // console.log(this.state.clheight);
        this.getToday(2, 0)
        this.isMenWei();
        var myDate = new Date();
        var hours = myDate.getHours();
        if (hours >= 14) this.setState({disabledonwork: true})
        else this.setState({disabledonwork: false})

        // const month = (myDate.getMonth() + 1)
        // if (5 < month && month < 10)  //5月到9月下班时间
        // {
        //     this.setState({offworkTime: '17:30'})
        // }
        //测试数据
        // this.setState({
        //     jingdu: '212.086156',
        //     weidu: '32.100666',
        //     addressreal: '九鼎昊天'
        // })
        // this.canDaka(2)

        // this.getGPS()
        this.getbaiduGPS()
        // this.getH5GPS()
    }

    //高德定位
    getGPS = () => {
        const {AMap,} = window
        map = new AMap.Map('mapContainer', {
            resizeEnable: true,
            zoom: 12
        });
        map.plugin('AMap.Geolocation', () => {
            geolocation = new AMap.Geolocation({
                enableHighAccuracy: true,//是否使用高精度定位，默认:true
                timeout: 10000,          //超过10秒后停止定位，默认：无穷大
                buttonOffset: new AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
                zoomToAccuracy: true,      //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                buttonPosition: 'RT'
            });
            map.addControl(geolocation);
            geolocation.getCurrentPosition();
            Toast.loading('定位中..., 请稍后', 30);
            AMap.event.addListener(geolocation, 'complete', (data) => {
                console.log(data)
                const jingdu = data.position.lng;
                const weidu = data.position.lat;
                this.setState(
                    {
                        jingdu: data.position.lng,
                        weidu: data.position.lat,
                        addressreal: data.formattedAddress,
                        province: data.addressComponent.province,
                        city: data.addressComponent.city,
                    })//获取经度
                   
                if (weidu > 32.0993720100 && weidu < 32.1028074700 && jingdu > 112.0810818672 && jingdu < 112.0884686708) {
                    this.setState({address: '襄阳九鼎昊天环保设备有限公司'})//获取定位地址
                }
                else
                    this.setState({address: data.formattedAddress || '未获取到地址'})//获取定位地址
                if (data.formattedAddress) {
                    Toast.hide()
                }
                else {
                    Toast.hide()
                    Toast.fail('未获取到定位信息，请重新定位', 6);
                }
                this.GetNOwork(); //未打卡次数
                // 获取上下班打卡时间
                if (this.state.tabindex == 0)//首次进入页面时才判断上下班是否可以打卡
                    this.canDaka(2)
            });//返回定位信息
            AMap.event.addListener(geolocation, 'error', (data) => {
                console.log(data);
                this.getbaiduGPS()
            });      //返回定位出错信息
        });
    }
    //百度定位
    getbaiduGPS=()=>{
        const that = this;
        var map = new BMap.Map("mapContainer");
        Toast.loading('定位中..., 请稍后', 30);
        const BMapGeolocation = new BMap.Geolocation();
        BMapGeolocation.getCurrentPosition((r) => {
            console.log(r)
            // alert(r.point.lng+'纬度'+r.point.lat)
            var point = new BMap.Point(r.point.lng, r.point.lat);  // 创建点坐标  
            map.centerAndZoom(point, 15); 
            var marker = new BMap.Marker(point);  // 创建标注
                map.addOverlay(marker);              // 将标注添加到地图中
                map.panTo(point);    
                const jingdu = r.point.lng;
                const weidu = r.point.lat;  
                const address=r.address.province+r.address.city+r.address.district+r.address.street+r.address.street_number
            this.setState(
                {
                    jingdu: r.point.lng,
                    weidu: r.point.lat,
                    addressreal: address,
                    province: r.address.province,
                    city:r.address.city,
                    qu:r.address.district
                })//获取经度
                if (weidu > 32.096221 && weidu < 32.108701 && jingdu > 112.07799 && jingdu < 112.119851) {
                    this.setState({address: '襄阳九鼎昊天环保设备有限公司'})//获取定位地址
                }
                else{
                     this.setState({address: address || '未获取到地址'})//获取定位地址
                }
                if (address) {
                    Toast.hide()
                }
                else {
                    Toast.hide()
                    Toast.fail('未获取到定位信息，请重新定位', 6);
                }
                this.GetNOwork(); //未打卡次数
                console.log(this.state.tabindex)
                 // 获取上下班打卡时间
                 if (this.state.tabindex == 0)//首次进入页面时才判断上下班是否可以打卡
                 this.canDaka(2)
          },function (error) {
            Toast.hide()
            Toast.fail('未获取到定位信息，请重新定位', 6);
        },{
            enableHighAccuracy: true,//是否要求高精度的地理位置信息
            timeout: 10000,//对地理位置信息的获取操作做超时限制，如果再该事件内未获取到地理位置信息，将返回错误
            maximumAge:0//设置缓存有效时间，在该时间段内，获取的地理位置信息还是设置此时间段之前的那次获得的信息，超过这段时间缓存的位置信息会被废弃
        });
    }
    //h5定位
    getH5GPS(){ 
        const _this=this
        Toast.loading('定位中..., 请稍后', 30);
        var map = new BMap.Map("mapContainer");
        if (navigator.geolocation){
            navigator.geolocation.getCurrentPosition(function(position){
                var x = position.coords.longitude;//经度
                var y = position.coords.latitude;//维度
               
                console.log(x+'维度'+y)
                var gpsPoint = new BMap.Point(x, y)
                setTimeout(function(){
                    var convertor = new BMap.Convertor()
                    var pointArr = []
                    pointArr.push(gpsPoint)
                    //转换成百度经纬度,然后在地图上标记
                    convertor.translate(pointArr, 1, 5, function(data){
                        console.log(data)
                        if(data.status === 0) {
                           const point = data.points[0]
                            console.log(point)
                            map.centerAndZoom(point, 15);
                            var marker = new BMap.Marker(point);  // 创建标注
                            map.addOverlay(marker); 
                            _this.loadfunc(data.points[0].lng,data.points[0].lat,null)
                        }
                       else{
                          Toast.hide()
                          Toast.fail('未获取到定位信息，请重新定位', 6);
                       }
                    })
                   }, 1000)
            }); 
        }
        else{
            Toast.hide()
            Toast.fail('未获取到定位信息，请重新定位', 6);
        }
    }
    //加载方法（H5专用）
    loadfunc(jingdu,weidu,address){
        this.setState(
            {
                jingdu: jingdu,
                weidu: weidu,
                addressreal: address,
            })//获取经度
            //公司打卡默认显示公司名称
            if (weidu > 32.096221 && weidu < 32.108701 && jingdu > 112.07799 && jingdu < 112.119851) {
                this.setState({address: '襄阳九鼎昊天环保设备有限公司'})//获取定位地址
            }
            this.GetNOwork(); //未打卡次数
             // 获取上下班打卡时间
             if (this.state.tabindex == 0)//首次进入页面时才判断上下班是否可以打卡
             this.canDaka(2)
    }
   
    //判断未打卡次数
    GetNOwork = () => {
        postRequest(`/login/queka_tx`, '').then((result) => {
            //console.log(result);
            if (result.status > 0) {
                Toast.info('您有' + result.status + '次未打卡，请及时补卡', 3);
            }
        })
    }
    //判断是电脑还是PC(true:电脑，false:手机)
    isPC = () => {
        var u = navigator.userAgent
        //是否为移动端
        var ismobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
        // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
        // var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        // if (isAndroid || isiOS) return false
        if (ismobile) return false
        else return true
    }
    //是否可以打卡,ele:操作元素
    canDaka = (status) => {
        const {jingdu, weidu, addressreal,province,city,qu} = this.state
        this.setState({loadingTab: true})
        if (this.isPC()) {
            this.setState({result: false, msg: '禁止用电脑打卡，请切换到手机'})
        }
        else {
            //const jingdu='122.08624';const weidu='39.10037';
            postRequest(`/login/check`, `status=${status}&jingdu=${jingdu}&weidu=${weidu}&address=${province+city+qu+addressreal || ''}`).then((result) => {
                
                if (result) {
                    if (result.suc == 1) {
                        if (status == 2) { //上下班打卡的判断
                            this.setState({
                                offworkTime: result.data.time, onworkTime: result.data.shangban,
                                isweek: result.data.week == 1 ? true : false,
                                address:result.data.address
                            });
                        }
                        else if (status == 1)//出差打卡判断离返襄和异地打卡情况
                        {
                            if (result.yidi) { //异地打卡
                                if (result.yidi.suc == 1){
                                    this.setState({result: true})
                                }
                                else {
                                    this.setState({result: false,msg:result.yidi.msg})
                                }
                            }
                            if (result.lifanxiang) {  //离返襄打卡
                                if (result.lifanxiang.suc == 1)
                                    this.setState({lfresult: true})
                                else {
                                    this.setState({lfresult: false,lfmsg:result.lifanxiang.msg})
                                }
                            }
                        }
                        else this.setState({result: true})
                    }
                    else {
                        this.setState({result: false,msg: result.msg || '不能打卡',
                            lfresult: false,lfmsg:'不能打离返襄卡'})
                    }
                }
                else {
                    this.setState({result: false,lfresult: false,
                        lfmsg: '打卡系统更新，请刷新再试',
                        msg: '打卡系统更新，请刷新再试'})
                }
                this.setState({loadingTab: false})  //判断结果出来后才可以打卡
            })
        }
    }

    //上班打卡
    onWork = (files) => {
        const daka_user = JSON.parse(localStorage.getItem('daka_user'));
        const uid=daka_user.u_id
        const {jingdu, weidu, addressreal, province, city} = this.state
        this.setState({files3: files, btnstate1: false})
        Toast.loading('打卡中..., 请稍后'
            , 60, () => {
                Toast.fail('打卡失败，稍后再试', 4);
            });
        let body = `u_id=${uid}&jingdu=${jingdu}&weidu=${weidu}&province=${province}&city=${city}&address=${addressreal}&status=2&type=1`
        if (this.state.files3[0] != 'undefined' && this.state.files3[0] != null) {
            body += `&imgurl=${this.state.files3[0].url}`
        }
        postRequest('/login/shangban', body).then((result) => {
            console.log(result)
            if (result) {
                if (result.suc === 1) {
                    Toast.hide()
                    this.setState({resultModal: true, modalMsg: result.msg, resultData: result.data})
                    // this.getToday(2, 1);
                }
                else if(result.suc === 10){
                    localStorage.removeItem('daka_token')
                    Toast.hide();
                    Toast.info('账号已在别处登录,请重新登录', 3);
                    this.props.history.push('/')
                }
                else {
                    Toast.hide()
                    Toast.fail(result.msg || '打卡失败,请重新操作', 5);
                }
            } else {
                Toast.hide();
                Toast.info('操作失败，请刷新重试', 3);
            }
        })
    }
    //下班打卡
    offWork = (files) => {
        const daka_user = JSON.parse(localStorage.getItem('daka_user'));
        const uid=daka_user.u_id
        const {jingdu, weidu, addressreal, province, city} = this.state
        this.setState({files4: files, btnstate2: false})
        Toast.loading('打卡中..., 请稍后'
            , 60, () => {
                Toast.fail('打卡失败，稍后再试', 4);
            });
        let body = `u_id=${uid}&jingdu=${jingdu}&weidu=${weidu}&province=${province}&city=${city}&address=${addressreal}&status=2&type=2`
        if (this.state.files4[0] != 'undefined' && this.state.files4[0] != null) {
            body += `&imgurl=${this.state.files4[0].url}`
        }
        postRequest('/login/xiaban', body).then((result) => {
            console.log(result)
            if (result) {
                if (result.suc === 1) {
                    Toast.hide()
                    this.setState({resultModal: true, modalMsg: result.msg, resultData: result.data})
                    // this.getToday(2, 1);
                }
                else if(result.suc === 10){
                    localStorage.removeItem('daka_token')
                    Toast.hide();
                    Toast.info('账号已在别处登录,请重新登录', 3);
                    this.props.history.push('/')
                }
                else Toast.fail(result.msg || '打卡失败,请重新操作', 5);
            } else {
                Toast.hide();
                Toast.info('操作失败，请刷新再试', 3);
            }
        })
    }
    //外出打卡
    onChange = (files, type, index, e) => {
        this.setState({
            files,
        });
        Toast.loading('打卡中..., 请稍后', 60, () => {
            Toast.fail('打卡失败，稍后再试', 4);
        });
        if (this.state.result)
            this.handleSendDaka(0, 3);
        else Toast.fail(this.state.msg, 3);
    }
    //出差打卡
    onChangeCc = (files, type, index) => {
        Toast.loading('打卡中..., 请稍后'
            , 60, () => {
                Toast.fail('打卡失败，稍后再试', 5);
            });
        this.setState({
            files2: files
        });
        if (this.state.result) {
            const {jingdu, weidu, addressreal,province,city,qu} = this.state
            let body = `is_work=${type}&jingdu=${jingdu}&weidu=${weidu}&province=${province}&city=${city}&qu=${qu}&address=${addressreal || ''}&status=1`

            let imgurl;
            if (this.state.files2[0] != 'undefined' && this.state.files2[0] != null) {
                imgurl = this.state.files2[0].url;
            }
            if (imgurl != '' && imgurl != 'undefined' && imgurl != null) {
                body += `&imgurl=${imgurl}`;
            }
            postRequest('/login/daka', body).then((result) => {
                console.log(result)
                if (result) {
                    if (result.suc === 1) {
                        Toast.hide()
                        this.setState({resultModal: true, modalMsg: result.msg, resultData: result.data})
                    }
                    else {
                        if (result.msg.indexOf('请登陆')!=-1) {
                            localStorage.removeItem('daka_token')
                            Toast.info('登录超时,请重新登录', 4);
                            this.props.history.push({pathname: '/login'})
                        }
                        else Toast.fail(result.msg || '打卡失败，请稍后再试', 4);
                    }
                } else {
                    Toast.info('/login/daka接口错误，请刷新再试', 5);
                }
            })
        }
        else Toast.fail(this.state.msg || '不满足出差打卡条件，请重试', 5);
    }
    //离襄
    onChangeleave = (files) => {
        Toast.loading('打卡中..., 请稍后'
            , 60, () => {
                Toast.fail('打卡失败，稍后再试', 4);
            });
        this.setState({
            files6: files
        });
        if (this.state.lfresult) {
            const {jingdu, weidu, addressreal, province, city,qu} = this.state
            let body = `jingdu=${jingdu}&weidu=${weidu}&province=${province}&city=${city}&qu=${qu}&address=${addressreal || ''}&status=5`

            let imgurl;
            if (this.state.files6[0] != 'undefined' && this.state.files6[0] != null) {
                imgurl = this.state.files6[0].url;
            }
            if (imgurl != '' && imgurl != 'undefined' && imgurl != null) {
                body += `&imgurl=${imgurl}`;
            }
            postRequest('/login/lixiang', body).then((result) => {
                console.log(result)
                if (result) {
                    if (result.suc === 1) {
                        Toast.hide()
                        this.setState({resultModal: true, modalMsg: result.msg, resultData: result.data})
                    }
                    else {
                        if (result.msg.indexOf('请登陆')!=-1) {
                            localStorage.removeItem('daka_token')
                            Toast.info('登录超时,请重新登录', 3);
                            this.props.history.push({pathname: '/login'})
                        }
                        else Toast.fail(result.msg || '打卡失败，请稍后再试', 4);
                    }
                } else {
                    Toast.info('/login/lixiang接口错误，请联系信息开发部', 5);
                }
            })
        }
        else Toast.fail(this.state.msg, 3);
    }
    //返襄
    onChangeback = (files) => {
        Toast.loading('打卡中..., 请稍后'
            , 60, () => {
                Toast.fail('打卡失败，稍后再试', 4);
            });
        this.setState({
            files7: files
        });
        if (this.state.lfresult) {
            const {jingdu, weidu, addressreal, province, city,qu} = this.state
            let body = `jingdu=${jingdu}&weidu=${weidu}&province=${province}&city=${city}&qu=${qu}&address=${addressreal || ''}&status=6`

            let imgurl;
            if (this.state.files7[0] != 'undefined' && this.state.files7[0] != null) {
                imgurl = this.state.files7[0].url;
            }
            if (imgurl != '' && imgurl != 'undefined' && imgurl != null) {
                body += `&imgurl=${imgurl}`;
            }
            postRequest('/login/fanxiang', body).then((result) => {
                console.log(result)
                if (result) {
                    if (result.suc === 1) {
                        Toast.hide()
                        this.setState({resultModal: true, modalMsg: result.msg, resultData: result.data})
                    }
                    else {
                        if (result.msg.indexOf('请登陆')!=-1) {
                            localStorage.removeItem('daka_token')
                            Toast.info('登录超时,请重新登录', 1);
                            this.props.history.push({pathname: '/login'})
                        }
                        else Toast.fail(result.msg || '打卡失败，请稍后再试', 4);
                    }
                } else {
                    Toast.info('/login/fanxiang接口错误，请联系信息开发部', 5);
                }
            })
        }
        else Toast.fail(this.state.msg, 3);
    }
    //请假打卡
    onChangeQj = (files, type, index) => {
        Toast.loading('打卡中..., 请稍后'
            , 60, () => {
                Toast.fail('打卡失败，稍后再试', 4);
            });
        this.setState({
            files5: files
        });
        if (this.state.result)
            this.handleSendDaka(0, 4);
        else Toast.fail(this.state.msg, 3);
    }
    //打卡
    handleSendDaka = async (type, status) => {
        const {jingdu, weidu, addressreal, province, city,qu} = this.state
        let body = `is_work=${type}&jingdu=${jingdu}&weidu=${weidu}&province=${province}&city=${city}&qu=${qu}&address=${addressreal || ''}&status=${status}`

        let imgurl;
        if (status == 3) { //外出图片
            if (this.state.files[0] != 'undefined' && this.state.files[0] != null) {
                imgurl = this.state.files[0].url;
            }
        }
        if (status == 1) {//出差图片
            if (this.state.files2[0] != 'undefined' && this.state.files2[0] != null) {
                imgurl = this.state.files2[0].url;
            }
        }
        if (status == 4) {//请假图片
            if (this.state.files5[0] != 'undefined' && this.state.files5[0] != null) {
                imgurl = this.state.files5[0].url;
            }
        }

        if (imgurl != '' && imgurl != 'undefined' && imgurl != null) {
            body += `&imgurl=${imgurl}`;
        }
        postRequest('/login/daka', body).then((result) => {
            console.log(result)
            if (result) {
                if (result.suc === 1) {
                    Toast.hide()
                    this.setState({resultModal: true, modalMsg: result.msg, resultData: result.data})
                }
                else {
                    if (result.msg.indexOf('请登陆')!=-1) {
                        localStorage.removeItem('daka_token')
                        Toast.info('登录超时,请重新登录', 4);
                        this.props.history.push('/login')
                    }
                    else Toast.fail(result.msg || '打卡失败，请稍后再试', 4);
                }
            } else {
                Toast.info('/login/daka接口错误，请联系信息开发部', 5);
            }
        })
    }
    //获取今日打卡记录(type:0:首次加载；1：打卡后加载)
    getToday = async (status, type) => {
        postRequest('/login/record', `status=${status}`).then((result) => {
            console.log(result)
            if (result && result.suc == 1) {
                const list = result.data ? result.data : [];
                if (list != null && list.length > 0) {
                    var dataAM = '', dataPM = '', amState = true, pmState = true;
                    for (var i = 0; i < list.length; i++) {
                        if (list[i].is_work == 1)//上班
                        {
                            dataAM = list[i].dakatime == '' ? '' : list[i].dakatime.split(' ')[1];
                            amState = false;
                        }
                        else {  //下班
                            dataPM = list[i].dakatime == '' ? '' : list[i].dakatime.split(' ')[1];
                            pmState = false;
                        }
                    }
                    this.setState({
                        btnstate1: amState,
                        amtime: dataAM,
                        btnstate2: pmState,
                        pmtime: dataPM
                    });
                    // if(type==1) Toast.hide();
                }
                if (type == 1) {
                    Toast.hide();
                    Toast.success('打卡成功', 1);
                }
            }
        })
    }
    //判断是否是门卫
    isMenWei = async () => {
        postRequest(`/login/menwei`, '').then((result) => {
            //console.log(result);
            if (result.status == 1)
                this.setState({menwei: true});
            else
                this.setState({menwei: false});
        })
    }
    //打卡成功后确认
    handleConfirm = () => {
        const body = JSON.stringify(this.state.resultData)
        postRequest2(`/login/check_log`, {data: body}).then((result) => {
            if (result) {
                if (result.suc == 1) {
                    this.props.history.push({pathname: '/record'})
                    this.setState({resultModal: false})
                }
            }
            else Toast.fail('接口错误，请联系信息开发部', 3)
        })
    }
    

    render() {
        const {data, files, files2, files3, files4, files5, files6, files7, modalMsg, jingdu, weidu, addressreal} = this.state
        const daka_user = JSON.parse(localStorage.getItem('daka_user'));

        const t_show = daka_user.t_show;  //是否属于倒班员工（倒班员工不限制打卡）

        return (
            <div className='index-content'>

                <div className="contentBox">
                    {/*头部*/}
                    <List className="my-list">
                        <Item className="top_list" align="top" thumb={
                            daka_user.u_avatar == null ? Person : `http://jd.stycie.com/Upload/${daka_user.u_avatar}`}
                              multipleLine>
                            <div>
                                {daka_user.u_fullname}
                                <div onClick={() => {
                                    this.props.history.push({pathname: '/tempDaka'})
                                }} style={{float: 'right', color: 'red'}}>（临时打卡）
                                </div>
                                <Brief>部门：{daka_user.depart}</Brief>
                            </div>
                            <a className="left_bnt" onClick={() => {
                                this.props.history.push({pathname: '/record'})
                            }}>打卡记录</a>
                        </Item>
                    </List>
                    {/*头部*/}
                    <div className='address_content'>
                        <div id="mapContainer"></div>
                    </div>
                    <List className="my-list address">
                        <Item>
                            <div className='site_title'>
                                <img src={address}/>
                                <span>我的位置</span>
                                <span onClick={this.getbaiduGPS} style={{color: '#3182D2'}}>（重新定位）</span>
                            </div>
                            <Brief>{this.state.address}</Brief>
                        </Item>
                    </List>
                    <WhiteSpace/>
                    <Tabs swipeable={false} style={{height: 'auto'}} className='mytab' tabs={tabs2}
                          onTabClick={(tab, index) => {
                              this.setState({tabindex: index})
                              if (index == 0) this.canDaka(2)
                              if (index == 1) this.canDaka(3)
                              if (index == 2) this.canDaka(1)
                              if (index == 3) this.canDaka(4)
                          }}
                          initialPage={0}
                    >
                        <div>
                            {this.state.loadingTab ?
                                <div className='loadingimg'>
                                    <img src={loading}/>
                                    <p>加载中.....</p>
                                </div> :
                                <div>
                                    <div style={{display: 'none', width: '10px'}} id='onwork'>
                                        <ImagePicker
                                            files={files3}
                                            length="1"
                                            onChange={this.onWork.bind(this)}
                                            capture='camera'
                                            onImageClick={(index, fs) => {
                                                console.log(index, fs)
                                            }}
                                            selectable={files3.length < 1}
                                            multiple={false}
                                            style={{display: 'none'}}
                                            disableDelete/>
                                    </div>
                                    <List className='daka_item'>
                                        <Item
                                            multipleLine
                                            thumb={Work}
                                            extra={
                                                this.state.btnstate1 ?
                                                    <Button
                                                        disabled={this.state.menwei ? false : this.state.disabledonwork}  //超过下午2点，不能再打上班卡
                                                        onClick={() => {
                                                            if (this.state.result) {
                                                                document.getElementById('onwork').getElementsByTagName('input')[0].click()
                                                            }
                                                            else {
                                                                Toast.info(this.state.msg, 2)
                                                            }
                                                        }}
                                                        type="primary" inline size="small">未打卡</Button> :
                                                    <div>打卡时间：{this.state.amtime}</div>
                                            }
                                        >
                                            上班打卡
                                            {this.state.menwei ? '' :
                                                <Brief>{this.state.onworkTime}</Brief>
                                            }

                                        </Item>
                                        <Item
                                            multipleLine
                                            thumb={offwork}
                                            extra={
                                                this.state.btnstate2 ?
                                                    <Button
                                                        onClick={() => {
                                                            if (this.state.result) {
                                                                // if (!this.state.menwei) {
                                                                //     if (this.state.isweek||t_show==1||daka_user.u_depart==142) {  //周末打卡不限制，倒班用户不限制,市场部不限制
                                                                //         document.getElementById('offwork').getElementsByTagName('input')[0].click()
                                                                //     }
                                                                //     else {
                                                                //         var myDate = new Date();
                                                                //         var hours = myDate.getHours();
                                                                //         var min = myDate.getMinutes();
                                                                //         var hh = this.state.offworkTime == '' ? '' : this.state.offworkTime.split(':')[0]
                                                                //         var mm = this.state.offworkTime == '' ? '' : this.state.offworkTime.split(':')[1]
                                                                //         if ((hours < hh || (hours == hh && min < mm))) {
                                                                //             Toast.info('还未到下班时间，不能打卡！', 2)
                                                                //         }
                                                                //         else {
                                                                //             document.getElementById('offwork').getElementsByTagName('input')[0].click()
                                                                //         }
                                                                //     }
                                                                // }
                                                                // else
                                                                document.getElementById('offwork').getElementsByTagName('input')[0].click()
                                                            }
                                                            else {
                                                                Toast.info(this.state.msg, 2)
                                                            }
                                                        }
                                                        }
                                                        type="primary" inline size="small">未打卡</Button> :
                                                    <div>打卡时间：{this.state.pmtime}</div>
                                            }
                                        >
                                            下班打卡
                                            {this.state.menwei ? '' :
                                                <Brief>{this.state.offworkTime}</Brief>
                                            }
                                        </Item>
                                    </List>
                                    <div style={{display: 'none', width: '10px', float: 'right'}} id='offwork'>
                                        <ImagePicker
                                            ref='offwork'
                                            files={files4}
                                            length="1"
                                            onChange={this.offWork.bind(this)}
                                            capture='camera'
                                            onImageClick={(index, fs) => console.log(index, fs)}
                                            selectable={files4.length < 1}
                                            multiple={false}
                                            style={{display: 'none'}}
                                            disableDelete
                                        />
                                    </div>
                                    <div style={{height: '53px'}}></div>
                                </div>
                            }
                        </div>
                        <div>
                            {this.state.loadingTab ?
                                <div className='loadingimg'>
                                    <img src={loading}/>
                                    <p>加载中.....</p>
                                </div> :
                                <div>
                                    <div className="daka_bottom" id='waichu'>
                                        {
                                            files && files.length > 0 || <span className='title' onClick={() => {
                                                if (this.state.result) {
                                                    document.getElementById('waichu').getElementsByTagName('input')[0].click()
                                                }
                                                else Toast.info(this.state.msg, 2)

                                            }}>市内外出</span>
                                        }
                                        <ImagePicker className='homeimg'
                                                     files={files}
                                                     length="1"
                                                     onChange={
                                                         (files, type, index, e) => {
                                                             this.onChange(files, type, index)
                                                         }
                                                     }
                                                     capture='camera'
                                                     onAddImageClick={(e) => {
                                                         if (!this.state.result) {
                                                             e.preventDefault()
                                                             Toast.info(this.state.msg, 2)
                                                         }
                                                     }}
                                                     onImageClick={(index, fs) => console.log(index, fs)}
                                                     selectable={files.length < 1}
                                                     multiple={false}
                                                     style={{textAlign: 'center'}}
                                                     disableDelete
                                        />
                                        <WhiteSpace/>
                                    </div>
                                    <div className='daka_rule'>
                                        <span>市内外出打卡规则</span>
                                        <p>
                                            1）不从公司出发直接外出的，上午9:00前需要在办事地点打卡一次。<br/>
                                            2）下午16:00后办完事可不返回公司，需在当天外出申请上办理“申请不回公司“，审核通过后需在办事地点打卡一次。<br/>
                                            3) 上午外出晚于9:00后打卡的计为迟到；下午外出早于16:00前打卡的计为早退。<br/>
                                            4) 晚于9:00后申请，并在16:00前回公司的，需在办事地点打卡一次。外出未有打卡记录的计为旷工。<br/>
                                        </p>
                                    </div>
                                    <div style={{height: '53px'}}></div>
                                </div>
                            }
                        </div>
                        <div>
                            {this.state.loadingTab ?
                                <div className='loadingimg'>
                                    <img src={loading}/>
                                    <p>加载中.....</p>
                                </div> :
                                <div>
                                    <div className='more_daka_box'>
                                        <div className="item">
                                            <div className='daka_quyu_x' id='leave'>
                                                {
                                                    files6 && files6.length > 0 ||
                                                    <span className='title title1' onClick={() => {
                                                        if (this.state.lfresult) {
                                                            document.getElementById('leave').getElementsByTagName('input')[0].click()
                                                        }
                                                        else Toast.info(this.state.lfmsg, 4)
                                                    }}>离襄</span>
                                                }
                                                <ImagePicker ref='lixiangImg' className='homeimg icon1'
                                                             files={files6}
                                                             length="1"
                                                             onChange={
                                                                 (files) => {
                                                                     this.onChangeleave(files)
                                                                 }
                                                             }
                                                             capture='camera'
                                                             onImageClick={(index, fs) => console.log(index, fs)}
                                                             selectable={files6.length < 1}
                                                             multiple={false}
                                                             style={{textAlign: 'center'}}
                                                             disableDelete
                                                />
                                            </div>
                                            <WhiteSpace/>
                                        </div>
                                        <div className="item">
                                            <div className='daka_quyu_center' id='chucai'>
                                                {
                                                    files2 && files2.length > 0 ||
                                                    <span className='title' onClick={() => {
                                                        if (this.state.result) {
                                                            document.getElementById('chucai').getElementsByTagName('input')[0].click()
                                                        }
                                                        else Toast.info(this.state.msg, 4)
                                                    }}>异地打卡</span>
                                                }
                                                <ImagePicker className='homeimg' ref={this.chucaiImg}
                                                             files={files2}
                                                             length="1"
                                                             onChange={
                                                                 (files, type, index) => {
                                                                     this.onChangeCc(files, type, index)
                                                                 }
                                                             }
                                                             capture='camera'
                                                             onAddImageClick={(e) => {
                                                                 console.log('bbb')
                                                                 // checkDaka(1, jingdu, weidu, addressreal).then(result => {
                                                                 //     if (result) {
                                                                 //         if (result.suc == 1) {
                                                                 //             this.setState({result: true})
                                                                 //         }
                                                                 //         else {
                                                                 //             Toast.info('条件不符合，阻止调用照相机', 5)
                                                                 //             this.setState({result: false})
                                                                 //             e.preventDefault()
                                                                 //             Toast.info(result.msg, 5)
                                                                 //         }
                                                                 //     }
                                                                 //     else Toast.info('login/check接口错误，请联系信息开发部', 5)
                                                                 // })
                                                                 // if (!this.state.result) {
                                                                 //     e.preventDefault()
                                                                 //     Toast.info(this.state.msg, 2)
                                                                 // }
                                                             }}
                                                             onImageClick={(index, fs) => console.log(index, fs)}
                                                             selectable={files2.length < 1}
                                                             multiple={false}
                                                             style={{textAlign: 'center'}}
                                                             disableDelete
                                                />
                                            </div>
                                            <WhiteSpace/>
                                        </div>
                                        <div className="item">
                                            <div className='daka_quyu_x' id='goback'>
                                                {
                                                    files7 && files7.length > 0 ||
                                                    <span className='title title2' onClick={() => {
                                                        if (this.state.lfresult) {
                                                            document.getElementById('goback').getElementsByTagName('input')[0].click()
                                                        }
                                                        else Toast.info(this.state.lfmsg, 4)
                                                    }}>返襄</span>
                                                }
                                                <ImagePicker className='homeimg icon2'
                                                             files={files7}
                                                             length="1"
                                                             onChange={
                                                                 (files) => {
                                                                     this.onChangeback(files)
                                                                 }
                                                             }
                                                             capture='camera'
                                                             onAddImageClick={(e) => {
                                                                 // checkDaka(6, jingdu, weidu, addressreal).then(result => {
                                                                 //     if (result.suc == 1) {
                                                                 //         this.setState({result: true})
                                                                 //     }
                                                                 //     else {
                                                                 //         this.setState({result: false})
                                                                 //         e.preventDefault()
                                                                 //         Toast.info(result.msg, 2)
                                                                 //     }
                                                                 // })
                                                                 // if (!this.state.result) {
                                                                 //     e.preventDefault()
                                                                 //     Toast.info(this.state.msg, 2)
                                                                 // }
                                                             }}
                                                             onImageClick={(index, fs) => console.log(index, fs)}
                                                             selectable={files7.length < 1}
                                                             multiple={false}
                                                             style={{textAlign: 'center'}}
                                                             disableDelete
                                                />
                                            </div>
                                            <WhiteSpace/>
                                        </div>
                                    </div>
                                    <div className='daka_rule'>
                                        <span>出差打卡规则补充</span>
                                        <p>
                                            新疆、甘肃的打卡时间后移两个小时，上午：7：00-11:00；下午：19:00-23:00<br/>
                                        </p>
                                    </div>
                                    <div className='daka_rule_img' onClick={() => {
                                        this.setState({previewVisible: true})
                                    }}>
                                        <img src={guize}/>
                                    </div>
                                    {/*<div className='daka_rule'>*/}
                                    {/*<span>出差打卡规则</span>*/}
                                    {/*<p>*/}
                                    {/*1）出差出发日和返程日打卡时除规定的打卡外需增加打卡次数，用于拍摄车票、高速路票等证明来回行程的票据<br/>*/}
                                    {/*2）出差打卡时间段：<br/>*/}
                                    {/*<li>上午5:00-9：00；</li>*/}
                                    {/*<li>下午17：00-21:00；</li>*/}
                                    {/*<li>备注：两个时段各打卡一次，每次奖励5元。当天奖励10元封顶</li>*/}
                                    {/*3）当天未在规定时段内打卡的，少一次扣10元并且当天无奖励。当天规定时间打卡均未完成的按公司最低出差补助标准报销并负奖励20元。*/}
                                    {/*连续2天未打卡的，该2天不享受任何出差补助<br/>*/}
                                    {/*4）出差返程当天必须在出发地或途中打卡一次，回到襄阳后第一时间再打卡一次。返程未按规定打卡的按系统上最后一次打卡日作为返程日，出差补助只享受到系统最后一次打卡日<br/>*/}
                                    {/*5）出差不得无故错打漏打，该次出差漏打未有特殊原因的最多不能漏打超过6次（一个时间段为1次）。超过6次者将给予负奖励及处理，同时公司通报批评。对确有特殊原因未能打卡的，可返程后回公司申请线下申诉给予补卡<br/>*/}
                                    {/*</p>*/}
                                    {/*</div>*/}
                                    <div style={{height: '53px'}}></div>
                                </div>}
                        </div>
                        <div>
                            {this.state.loadingTab ?
                                <div className='loadingimg'>
                                    <img src={loading}/>
                                    <p>加载中.....</p>
                                </div> :
                                <div>
                                    <div className="daka_bottom" id='qingjia'>
                                        {
                                            files5 && files5.length > 0 || <span className='title' onClick={() => {
                                                if (this.state.result) {
                                                    document.getElementById('qingjia').getElementsByTagName('input')[0].click()
                                                }
                                                else Toast.info(this.state.msg, 2)
                                            }}>请假打卡</span>
                                        }
                                        <ImagePicker className='homeimg'
                                                     files={files5}
                                                     length="1"
                                                     onChange={
                                                         (files, type, index) => {
                                                             this.onChangeQj(files, type, index)
                                                         }
                                                     }
                                                     capture='camera'
                                                     onAddImageClick={(e) => {
                                                         if (!this.state.result) {
                                                             e.preventDefault()
                                                             Toast.info(this.state.msg, 2)
                                                         }
                                                     }}
                                                     onImageClick={(index, fs) => console.log(index, fs)}
                                                     selectable={files5.length < 1}
                                                     multiple={false}
                                                     style={{textAlign: 'center'}}
                                                     disableDelete
                                        />
                                        <WhiteSpace/>
                                    </div>
                                    <div className='daka_rule'>
                                        <span>请假打卡规则</span>
                                        <p>
                                            1）非请假全天的：离开公司时打卡 一次，如回公司的，要在回公司第一时间打卡；如不回则不打卡。未到公司直接请假的，在到公司的第一时间打卡<br/>
                                            2）全天请假的不用在请假打卡处打卡<br/>
                                            3）请假未及时打卡者，造成请假时长延长，由个人承担<br/>
                                        </p>
                                    </div>
                                    <div style={{height: '53px'}}></div>
                                </div>}
                        </div>
                        }

                    </Tabs>
                    <BottomTab selectedTab='daka'/>
                </div>
                <Modal className='img_view' maskClosable={true} visible={this.state.previewVisible} closable={true}
                       onClose={() => {
                           this.setState({previewVisible: false})
                       }}>
                    <img alt="example" style={{width: '100%'}} src={guize}/>
                </Modal>
                <Modal
                    visible={this.state.resultModal}
                    transparent
                    maskClosable={false}
                    title="打卡提示"
                    footer={[{
                        text: '确定', onPress: () => {
                            this.handleConfirm();
                        }
                    }]}
                    wrapProps={{onTouchStart: this.onWrapTouchStart}}>
                    <div style={{height: 50}}>
                        {modalMsg}
                    </div>
                </Modal>
            </div>
        )
    }
}

export default Index
